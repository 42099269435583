import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AssetContext } from '../../contexts/asset/AssetContext';

import Text from '../Generic/Text';
import firstUp from '../../controllers/firstUp';

const ActivityList = ({ list }) => {
	const { bullet } = useContext(AssetContext);

	return (
		<ul className='activity-list'>
			{list.map((text, index) => {
				if (typeof text === 'object') {
					return (
						<Fragment key={'activityLi_' + index}>
							<Text
								li
								className='fs-xs'
								text={firstUp(text.header)}
								icon={bullet}
							/>
							<ul className={`${index !== list.length - 1 && 'underline'}`}>
								{text.list.map((entry, index) => (
									<Text
										key={'ulKey_' + index}
										li
										className={`fs-xxs`}
										text={firstUp(entry)}
									/>
								))}
							</ul>
						</Fragment>
					);
				} else {
					return (
						<Text
							key={'activityLi_' + index}
							li
							className='fs-xs underline'
							text={firstUp(text)}
							icon={bullet}
						/>
					);
				}
			})}
		</ul>
	);
};

ActivityList.propTypes = {
	list: PropTypes.array.isRequired,
};

export default ActivityList;
