import { SET_LOCATION, SET_PATH, SET_SIDE } from './types';

const locReducer = (state, action) => {
	switch (action.type) {
		case SET_SIDE:
			return {
				...state,
				...action.payload,
			};
		case SET_PATH:
			return {
				...state,
				path: action.payload,
			};
		case SET_LOCATION:
			return {
				...state,
				location: action.payload,
			};
		default:
			return state;
	}
};

export default locReducer;
