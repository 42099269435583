import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';
import UserPhoto from '../Generic/UserPhoto';
import Text from '../Generic/Text';
import Detail from '../Generic/Detail';
import firstUp from '../../controllers/firstUp';
import Button from '../Generic/Button';

const UserProfileInfo = ({
	user: {
		photo,
		rank,
		born,
		age,
		from,
		residence,
		timezone,
		gender,
		fullName,
		role,
		createdAt,
	},
}) => {
	const {
		lang: {
			TX00704: mentorSinceLang,
			TX00718: discipleSinceLang,
			TX00705: bornLang,
			TX00249: fromLang,
			TX00706: ageLang,
			TX00243: genderLang,
			TX00247: residenceLang,
			TX00248: timezoneLang,
			TX00336: messageLang,
		},
	} = useContext(LangContext);

	const messagesLang = {
		content: 'messages',
	};

	const since = {
		mentor: mentorSinceLang.content,
		disciple: discipleSinceLang.content,
	};

	const info = [
		{ label: bornLang.content, data: born },
		{ label: fromLang.content, data: from },
		{ label: ageLang.content, data: age },
		{ label: residenceLang.content, data: residence },
		{ label: genderLang.content, data: gender },
		{ label: timezoneLang.content, data: timezone },
	];

	return (
		<main className='user-profile-info'>
			<div className='user-profile-photo'>
				<UserPhoto photo={photo} type='l' />
				<Text>{firstUp(rank)}</Text>
			</div>
			<div className='user-profile-details'>
				<div className='user-profile-title'>
					<h5 className='fs-l'>{fullName}</h5>
					<Text className='clr-light-grey'>
						{since[role].concat(` ${createdAt}`)}
					</Text>
					<Button
						to={`/${messagesLang.content}`}
						primary
						text={firstUp(messageLang.content)}
					/>
				</div>
				{info.map((entry, index) => (
					<Detail
						key={'keyUserProfileInfo_' + index}
						column
						label={firstUp(entry.label)}
					>
						{firstUp(entry.data)}
					</Detail>
				))}
			</div>
		</main>
	);
};

UserProfileInfo.propTypes = {
	user: PropTypes.object.isRequired,
};

export default UserProfileInfo;
