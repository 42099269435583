import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Text from './Text';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';

const EditToggler = ({ className, onSubmit, onCancel, state }) => {
	const { editIcon, checkMarkIcon, closeRed } = useContext(AssetContext);
	const {
		lang: {
			TX00250: { content: editLang },
			TX00251: { content: cancelLang },
			TX00252: { content: saveLang },
		},
	} = useContext(LangContext);

	const { edit, setEdit } = state;

	return (
		<>
			{!edit ? (
				<Text
					className={`edit-toggler fs-xs clr-dark-grey ${className}`}
					icon={editIcon}
					onClick={() => setEdit(true)}
				>
					{editLang}
				</Text>
			) : (
				<>
					<Text
						className={`edit-toggler fs-xs clr-dark-grey ${className}`}
						icon={checkMarkIcon}
						onClick={onSubmit}
					>
						{saveLang}
					</Text>
					<Text
						className={`edit-toggler-close fs-xs clr-dark-grey ${className}`}
						icon={closeRed}
						onClick={() => (onCancel(), setEdit(false))}
					>
						{cancelLang}
					</Text>
				</>
			)}
		</>
	);
};

EditToggler.propTypes = {
	className: PropTypes.string,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	state: PropTypes.shape({
		edit: PropTypes.bool.isRequired,
		setEdit: PropTypes.func.isRequired,
	}).isRequired,
};

EditToggler.defaultProps = {
	className: '',
};

export default EditToggler;
