import React from 'react';
import PropTypes from 'prop-types';

const CardsContainer = ({ className, orientation, children }) => {
  return (
    <section className={`cards-container ${orientation} ${className}`}>
      {children}
    </section>
  );
};

CardsContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  orientation: PropTypes.oneOf(['portrait', 'landscape']),
};

CardsContainer.defaultProps = {
  className: '',
};

export default CardsContainer;
