import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';
import Pill from '../Generic/Pill';
import { AssetContext } from '../../contexts/asset/AssetContext';

const UserProfilePills = ({ role }) => {
  const {
    lang: {
      TX00018: discipleLang,
      TX00394: disciplesLang,
      TX00499: materialsLang,
      TX00666: materialLang,
      TX00707: dayLang,
      TX00493: daysLang,
      TX00700: hourLang,
      TX00267: hoursLang,
      TX00509: interestLang,
      TX00682: interestsLang,
      TX00077: certificationLang,
      TX00029: certificationsLang,
    },
  } = useContext(LangContext);

  const { agendaIcon, timeIcon, discipleIcon, stamp } =
    useContext(AssetContext);

  const pill_3 = {
    mentor: {
      single: discipleLang.content,
      plural: disciplesLang.content,
    },
    disciple: {
      single: interestLang.content,
      plural: interestsLang.content,
    },
    icon: discipleIcon,
  };

  const pill_4 = {
    mentor: {
      single: materialLang.content,
      plural: materialsLang.content,
    },
    disciple: {
      single: certificationLang.content,
      plural: certificationsLang.content,
    },
    icon: stamp,
  };

  const pills = [
    {
      number: 31,
      single: dayLang.content,
      plural: daysLang.content,
      icon: agendaIcon,
    },
    {
      number: 123,
      single: hourLang.content,
      plural: hoursLang.content,
      icon: timeIcon,
    },
    {
      number: 2,
      ...pill_3[role],
      icon: pill_3.icon,
    },
    {
      number: 1,
      ...pill_4[role],
      icon: pill_4.icon,
    },
  ];

  return (
    <footer className='user-profile-pills'>
      {pills.map((pill, index) => (
        <Pill
          key={'keyUserProfilePills_' + index}
          large
          content={{
            icon: pill.icon,
            text: pill.number > 1 ? pill.plural : pill.single,
            number: pill.number,
          }}
        />
      ))}
    </footer>
  );
};

UserProfilePills.propTypes = {
  role: PropTypes.string.isRequired,
};

export default UserProfilePills;
