import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LocContext } from '../../contexts/location/LocContext';

const To = ({ className, children, to, onClick }) => {
  const { updateLocation } = useContext(LocContext);

  return (
    <Link
      to={to}
      className={className}
      onClick={
        onClick
          ? (e) => (updateLocation(to), onClick(e))
          : () => updateLocation(to)
      }>
      {children}
    </Link>
  );
};

To.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string,
  ]).isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

To.defaultProps = {
  className: '',
  onClick: undefined,
};

export default To;
