import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import firstUp from '../../controllers/firstUp';

const CategoriesCard = ({ content }) => {
  const { title, text, img } = content;

  return (
    <Link to={`/category/${title}`}>
      <div className='categories-card'>
        <h4 className='fs-l clr-white'>{title}</h4>
        <div>
          <span />
          <span />
          <span />
          <span />
          <img src={img} alt={title} />
        </div>
        <p className='fs-xs clr-white'>{firstUp(text)}</p>
      </div>
    </Link>
  );
};

CategoriesCard.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CategoriesCard;
