import React from 'react';
import { Switch, Route } from 'react-router';

// PAGES
import Category from '../pages/Category/Category';
import Role from '../pages/Role/Role';
import Activity from '../pages/Activity/Activity';
import Explore from '../pages/Explore/Explore';
import Account from '../pages/Account/Account';
import Wallet from '../pages/Wallet/Wallet';

// MODALS
import UserProfile from '../modules/UserProfile/UserProfile';
import Join from '../modules/Join/Join';

const JoinRoutes = () => (
	<Switch>
		<Route path={`/`} component={Join} />
	</Switch>
);

const CategoryRoutes = () => (
	<Switch>
		<Route exact path={`/category/:category`} component={Category} />
	</Switch>
);

const RoleRoutes = () => (
	<Switch>
		<Route exact path={`/role/:role`} component={Role} />
	</Switch>
);

const ActivityRoutes = () => (
	<Switch>
		<Route exact path={`/activity/:activity`} component={Activity} />
	</Switch>
);

const ExploreRoutes = () => (
	<Switch>
		<Route path='/' component={Explore} />
	</Switch>
);

const AccountRoutes = () => (
	<Switch>
		<Route exact path='/account/wallet/:walletPage?' component={Wallet} />
		<Route path='/' component={Account} />
	</Switch>
);

const UserProfileRoutes = () => (
	<Switch>
		<Route path='/user_profile/:id/:nav?' component={UserProfile} />
	</Switch>
);

export {
	CategoryRoutes,
	RoleRoutes,
	ActivityRoutes,
	ExploreRoutes,
	AccountRoutes,
	UserProfileRoutes,
	JoinRoutes,
};
