import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { LangContext } from '../../contexts/language/LangContext';
import firstUp from '../../controllers/firstUp';
import Text from './Text';
import { Link } from 'react-router-dom';

const PresentationCard = ({ content }) => {
  const { title, icon, text1, text2 } = content;

  const {
    lang: {
      TX00049: more,
      TX00005: sessionLang,
      TX00006: classLang,
      TX00007: webinarLang,
      TX00008: liveLang,
    },
  } = useContext(LangContext);

  const className =
    title === sessionLang.content
      ? 'session-card'
      : title === classLang.content
      ? 'class-card'
      : title === webinarLang.content
      ? 'webinar-card'
      : title === liveLang.content
      ? 'live-card'
      : '';

  return (
    <Link to={`/activity/${title}`} className='presentation-card'>
      <div className={`presentation-card-title ${className}`}>
        <h4 className='title clr-white'>{firstUp(title)}</h4>
        <img src={icon} alt={title + 'icon'} />
      </div>
      <div className='presentation-card-body'>
        <p className='presentation-card-body-text fs-xs'>{firstUp(text1)}</p>
        <p className='presentation-card-body-text2 fs-xs clr-light-grey'>
          {firstUp(text2)}
        </p>
        <Text className='fs-xs'>{more.content}</Text>
      </div>
    </Link>
  );
};

PresentationCard.propTypes = {
  content: PropTypes.object.isRequired,
};

export default PresentationCard;
