import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import authReducer from './authReducer';
import { LOGIN_SUCCES, LOGOUT, EDIT_USER } from './types';
import { useHistory } from 'react-router';
import { LangContext } from '../language/LangContext';

export const AuthContext = createContext();

const AuthState = ({ children }) => {
	const initialState = {
		user: {
			photo: '',
			rating: 4.5,
			fullName: 'John Doe',
			firstName: 'John',
			lastName: 'Doe',
			birthday: '25 oct. 1994',
			email: 'johndoe@gmail.com',
			language: localStorage.lang ?? 'english',
			creSession: 10,
			discipline: 'Arabic',
			level: 5,
			rank: 'gold',
			disciples: 12,
			role: 'mentor',
			experience: 5,
			certified: true,
			avFor: 1,
			teachingLang: ['english', 'arabic'],
			hours: 72,
			sessionH: 32,
			description:
				'This is some dummy text to fill out the field. This is some dummy text to fill out the field.',
			video: 'sadklihfsdnaiofsd',
			mentor: [{ identifier: 'TX00005', uid: '1234' }],
			born: 'Oct 25, 1994',
			age: '25',
			gender: 'Male',
			from: 'United States of America',
			residence: 'Romania, Cluj-Napoca',
			timezone: 'UTC +3',
			createdAt: '15 June 2021',
			// disciple: [{ identifier: 'TX00005', uid: '1234' }],
		},
		wallet: { cre: 50 },
		isLogged: true,
	};

	const {
		lang: { TX00231: accountLang },
	} = useContext(LangContext);

	const history = useHistory();

	const [state, dispatch] = useReducer(authReducer, initialState);

	const { user } = state;

	// FUNCTIONS
	const login = (payload = user) => (
		dispatch({ type: LOGIN_SUCCES, payload }),
		history.push(`/${accountLang.content}`)
	);

	const logout = () => dispatch({ type: LOGOUT });

	const editUser = (payload) => dispatch({ type: EDIT_USER, payload });

	return (
		<AuthContext.Provider value={{ ...state, dispatch, login, logout, editUser }}>
			{children}
		</AuthContext.Provider>
	);
};

AuthState.propTypes = {
	children: PropTypes.element.isRequired,
};

export default AuthState;
