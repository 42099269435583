import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const PenLine = ({ white, right, className }) => {
  const lineRef = useRef(null);

  useEffect(() => {
    white && lineRef.current.classList.add('penline-white');
    right && lineRef.current.classList.add('penline-right');
    // eslint-disable-next-line
  }, []);

  return (
    <svg
      id='svg-penline'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 535.33 8.62'>
      <g id='penline' data-name='penline' ref={lineRef}>
        <path d='M484.39,5.86c-10,.53-18.76.62-29.05,1.11-16.81.29-31.92.91-48.86,1.16-49.11.33-98.49.86-147.61.12-1.56,0-3.91-.37-4.69.21-2.6-.25-6.64-.38-9.64-.33-39.61-.21-74.52.12-112.82-.58C111.39,7.34,89.24,7.14,69.83,7a18.36,18.36,0,0,0-6-.33c-2.09-.29-5.34.17-7.17,0-1-.66-2.86.08-4.43,0-.78.08-1.17-.58-1.82-.08-1.7-.29-4.69,0-6.65-.08-.39.41-1.56,0-2.47,0-1,.74-2.48-.37-3.78.29,0,.08,0,.24.39.28a7.73,7.73,0,0,1-3.13.21c0-.12,0-.25-.39-.29-2,.87-2.47-.49-4.69-.33-.26.58-2.73,0-2.47.62-1.44.58-3.39-.29-5.6,0-.66,0-1.05-.41-1.57-.12a6.17,6.17,0,0,1-3.38-.29c-1.18.7-3.13-.25-4.7.2.14-.49-2-.7-1.56-1.19-1.82-.5-2.73.58-4.69.2A21.92,21.92,0,0,1,.39,4.87L1,4.66c-.39-.12-1-.2-1-.37H1c1-.25-.78-.37-.65-.62,1.69-.12.65-.45.91-.86l2.22-.13c.26-.16-.39-.24-.66-.37l.27-.12c0-.29,1.56-.7,2-.66l.65.16c1.18.09.91-.37,1.83-.49-.65-1,3.77.08,4-1,2.87-.54,3.65.62,6,.41-.13-.12.26-.33-.39-.41,1.95-.66,3.91.49,6,0C22.15,1,25.14.46,25,1c2.09.12,4.3.36,6.26.28,1.95.66,4.43-.49,6.25.37,2.22-.2,3.39.46,5.34.33,3.65.42,8.47,0,12.25.58,6.9,0,14.07.5,21.24.58C83,3.38,88.85,3.92,96,4c8.47.46,16.54.66,24.75,1,3.78.21,7.69,0,11.6.5,7.16-.09,11.85.49,18.11.49,7.68,0,15.5.41,22.54.37,19.67.54,36.74.21,56.15.29,49,.83,93.8.58,141.09.62l.66-.21c7.16,0,15.24,0,21.49-.08,13.55-.08,28-.66,41.95-1,12.51-.41,24.89-.82,37.79-1.19,7,.08,14.59-.54,21-.62C502.5,3.67,511,4,520,3.51c5.34.16,11.34-.83,15.37.08L532.21,4C515.14,4.62,500.68,5.53,484.39,5.86Z' />
      </g>
    </svg>
  );
};

PenLine.propTypes = {
  white: PropTypes.bool,
  right: PropTypes.bool,
  className: PropTypes.string,
};

PenLine.defaultProps = {
  white: false,
  right: false,
  className: '',
};

export default PenLine;
