import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LocContext } from '../../contexts/location/LocContext';
import Text from '../Generic/Text';
import To from './To';
// import { AssetContext } from '../../contexts/asset/AssetContext';
import ArrowIcon from '../Assets/ArrowIcon';
import firstUp from '../../controllers/firstUp';
import { useLocation } from 'react-router-dom';

const SidebarLink = ({ link: { text, icon, submenu }, index }) => {
	// const { arrowIcon } = useContext(AssetContext);
	const { pathname } = useLocation();
	const pathSplit = pathname.split('/');
	const path = '/' + pathSplit[1] + '/' + pathSplit[2];

	const { sideBar_account, sideBar_profile, location } = useContext(LocContext);

	const sideBarSelected = {
		account: sideBar_account,
		profile: sideBar_profile,
	};

	return (
		<span
			className={`fs-xs sidebar-link ${
				index === sideBarSelected[location] && 'selected'
			} ${submenu ? 'hasSubmenu' : ''}`}
		>
			<To to={`/${location}/${text}`}>
				<Text li icon={icon}>
					{firstUp(text)}
				</Text>
			</To>
			{submenu && (
				<div className='sidebar-submenu'>
					{submenu.map((link, index) => (
						<To to={`${path}/${link}`} key={'submenuKey_' + index} className='fs-xs'>
							{firstUp(link)}
							<ArrowIcon />
						</To>
					))}
				</div>
			)}
		</span>
	);
};

SidebarLink.propTypes = {
	link: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string.isRequired,
		submenu: PropTypes.arrayOf(PropTypes.string.isRequired),
	}).isRequired,
	index: PropTypes.number.isRequired,
};

export default SidebarLink;
