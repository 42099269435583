import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';
import firstUp from '../../controllers/firstUp';

const ShowcaseTexts = ({ content, white, className, small }) => {
  const { header, subheader, paragraph } = content;

  return (
    <div className={`showcase-texts ${className}`}>
      <h1 className={`${white && 'clr-white'} ${small && 'fs-xl'}`}>
        {header}
      </h1>
      <Text
        className={`${white && 'clr-white'} ${small ? 'fs-s' : 'fs-m'}`}
        text={firstUp(subheader)}
      />
      {paragraph && (
        <Text
          className={`${white && 'clr-white'} ${small ? 'fs-xxs' : 'fs-xs'}`}
          text={firstUp(paragraph)}
        />
      )}
    </div>
  );
};

ShowcaseTexts.propTypes = {
  content: PropTypes.object.isRequired,
  white: PropTypes.bool,
  className: PropTypes.string,
  small: PropTypes.bool,
};

ShowcaseTexts.defaultProps = {
  white: false,
  className: '',
  small: false,
};

export default ShowcaseTexts;
