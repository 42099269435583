import React, { useContext } from 'react';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { FilterContext } from '../../contexts/filter/FilterContext';

const SelectorListGrid = () => {
  const { listIcon, gridIcon_org } = useContext(AssetContext);

  const { setOrientation, orientation } = useContext(FilterContext);

  const handleClick = (orientation) => {
    localStorage.setItem('card_viewMode', orientation);
    setOrientation(orientation);
  };

  return (
    <div className='list-grid'>
      {orientation === 'portrait' ? (
        <img
          id='portrait'
          src={listIcon}
          alt='portrait'
          onClick={() => handleClick('landscape')}
        />
      ) : (
        <img
          id='landscape'
          src={gridIcon_org}
          alt='landscape'
          onClick={() => handleClick('portrait')}
        />
      )}
    </div>
  );
};

export default SelectorListGrid;
