import React, { createContext, useContext, useReducer } from 'react';
import locReducer from './locReducer';
import { SET_LOCATION, SET_SIDE, LOCATIONS, SET_PATH } from './types';
import PropTypes from 'prop-types';
import getIndexOf from '../../controllers/getIndexOf';
import { LangContext } from '../language/LangContext';

export const LocContext = createContext();

const LocState = ({ children }) => {
	const {
		lang: {
			TX00234: { content: supportLang },
			TX00236: { content: infoLang },
			TX00237: { content: walletLang },
			TX00238: { content: shareLang },
			TX00231: { content: accountLang },
			// TX00232: { content: profileLang },
		},
	} = useContext(LangContext);

	const { HOME, ACTIVITY, CATEGORY, ROLE, EXPLORE, ACCOUNT, JOIN, PROFILE } =
		LOCATIONS;

	const initialState = {
		location: null,
		path: null,
		sideBar_account: 0,
		sideBar_profile: 0,
		possibleRoutes: {
			[accountLang]: [infoLang, walletLang, shareLang, supportLang, undefined],
		},
	};

	const [state, dispatch] = useReducer(locReducer, initialState);

	const { location, possibleRoutes } = state;

	const setLocation = (payload) =>
		location !== payload && dispatch({ type: SET_LOCATION, payload });

	const setSideBar = (slug, possibleRoutes) => {
		console.log('Slug is: ', slug);
		console.log('Routes are: ', possibleRoutes);
		if (possibleRoutes) {
			const index = getIndexOf(slug, possibleRoutes);
			if (index !== -1) {
				switch (location) {
					case LOCATIONS.ACCOUNT:
						dispatch({
							type: SET_SIDE,
							payload: { sideBar_account: index === 4 ? 0 : index },
						});
						break;
					case LOCATIONS.PROFILE:
						dispatch({ type: SET_SIDE, payload: { sideBar_profile: index } });
						break;
					default:
						break;
				}
			}
		}
	};

	const updateLocation = (location = window.location.pathname) => {
		const slug = location.split('/')[2];
		location = location.split('/')[1];
		const path = slug ? `${location}/${slug}` : `${location}`;

		const locations = [
			HOME,
			ACTIVITY,
			CATEGORY,
			ROLE,
			EXPLORE,
			ACCOUNT,
			JOIN,
			PROFILE,
		];
		const index = getIndexOf(location, locations);

		dispatch({ type: SET_PATH, payload: path });
		slug && setSideBar(slug, possibleRoutes[location]);
		setLocation(index !== -1 ? locations[index] : locations[0]);
		return index !== -1 ? locations[index] : locations[0];
	};

	const isMainBlock = () => {
		const mainBlock = [HOME, ACTIVITY, CATEGORY, ROLE];
		const isMainBlock = getIndexOf(location, mainBlock);

		return isMainBlock !== -1 ? true : false;
	};

	const navigate = (to) => {
		updateLocation(to);
		history.push(`${to}`);
	};

	return (
		<LocContext.Provider
			value={{
				...state,
				LOCATIONS,
				dispatch,
				navigate,
				setLocation,
				setSideBar,
				isMainBlock,
				updateLocation,
			}}
		>
			{children}
		</LocContext.Provider>
	);
};

LocState.propTypes = {
	children: PropTypes.element.isRequired,
};

export default LocState;
