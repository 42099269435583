import React, { useContext, useState } from 'react';
// import PropTypes from 'prop-types'
import Modal from '../../components/Modal/Modal';
import ContinueWithBtns from '../../components/ContinueWithBtns/ContinueWithBtns';
import Separator from '../../components/Generic/Separator';
import { LangContext } from '../../contexts/language/LangContext';
import { Link, useParams } from 'react-router-dom';
import Input from '../../components/Generic/Input';
import Button from '../../components/Generic/Button';
import Text from '../../components/Generic/Text';
import firstUp from '../../controllers/firstUp';
import { AuthContext } from '../../contexts/auth/AuthContext';

const Join = () => {
	const [fields, setFields] = useState({
		email: '',
		pass: '',
	});

	const { slugJoin } = useParams();

	const {
		lang: {
			TX00041: orLang,
			TX00244: emailLang,
			TX00040: passwordLang,
			TX00042: alreadyHaveAccountLang,
			TX00043: registerLang,
			TX00044: signInLang,
			TX00045: havingTroubleLang,
			TX00048: joinLang,
		},
	} = useContext(LangContext);

	const { login } = useContext(AuthContext);

	const joinType =
		slugJoin === joinLang.content ? registerLang.content : signInLang.content;

	const handleChange = (e) =>
		setFields({ ...fields, [e.target.name]: e.target.value });

	const handleSubmit = (e) => {
		e.preventDefault();
		const { email, pass } = fields;

		// check fields in-depth (regEx or something)
		if (email && pass) {
			// do login
			console.log(email, pass);
			login();
		}
	};

	return (
		<Modal location='back' className='join'>
			<ContinueWithBtns full google facebook apple />
			<Separator vertical text={orLang.content} />
			<form onSubmit={(e) => handleSubmit(e)}>
				<Input
					column
					id='email'
					type='email'
					name='email'
					content={{ label: emailLang.content }}
					value={fields.email}
					onChange={handleChange}
				/>
				<Input
					column
					id='password'
					type='password'
					name='pass'
					content={{ label: passwordLang.content }}
					value={fields.pass}
					onChange={handleChange}
				/>
				<Button
					text={joinType}
					submit
					primary
					full
					onClick={(e) => handleSubmit(e)}
				/>
			</form>
			<footer>
				<Text text={firstUp(alreadyHaveAccountLang.content)}>
					<Link to={`/${signInLang.content}`}>{firstUp(signInLang.content)}</Link>
				</Text>
				<Link to='/'>{firstUp(havingTroubleLang.content)}</Link>
			</footer>
		</Modal>
	);
};

// Join.propTypes = {};

export default Join;
