import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LangContext } from '../../contexts/language/LangContext';
import firstUp from '../../controllers/firstUp';

const FeatureCard = ({ content, id }) => {
  const {
    lang: { TX00049: more, TX00028: library, TX00029: cert, TX00030: academy },
  } = useContext(LangContext);

  const { title, text, icon } = content;

  const iconBackground =
    title === library.content
      ? 'library-card'
      : title === cert.content
      ? 'cert-card'
      : title === academy.content
      ? 'academy-card'
      : '';

  const color =
    title === library.content
      ? 'clr-library'
      : title === cert.content
      ? 'clr-cert'
      : title === academy.content
      ? 'clr-academy'
      : '';

  return (
    <div className={`feature-card feature-card-${id}`}>
      <div className='feature-card-title'>
        <h4 className={`fs-m ${color}`}>{title}</h4>
      </div>
      <div className='feature-card-body'>
        <p className='fs-xs'>{firstUp(text)}</p>
        <Link to='#' className='fs-xs'>
          {more.content}
        </Link>
      </div>
      <div className={`${iconBackground} feature-card-icon`}>
        <img src={icon} alt={`${title} icon`} />
      </div>
    </div>
  );
};

FeatureCard.propTypes = {
  content: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default FeatureCard;
