import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '../Generic/Box';
import { useHistory, Link } from 'react-router-dom';
import { AssetContext } from '../../contexts/asset/AssetContext';

const Modal = ({ className, location, children, onClose }) => {
	const history = useHistory();

	const { closeRed } = useContext(AssetContext);

	const handleModalClose = (e) =>
		location
			? e.target.className.includes('modal') && location === 'back'
				? history.goBack()
				: history.push(`/${location}`)
			: onClose(e);

	return (
		<section
			className={`modal ${className}`}
			onClick={(e) => handleModalClose(e)}
		>
			<Box className={`${className}-content`} onClick={(e) => e.stopPropagation()}>
				{children}
				{location ? (
					<Link to={`/${location}`}>
						<img src={closeRed} alt='close-modal' />
					</Link>
				) : (
					<img src={closeRed} alt='close-modal' onClick={(e) => onClose(e)} />
				)}
			</Box>
		</section>
	);
};

Modal.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]).isRequired,
	location: PropTypes.string,
	onClose: PropTypes.func,
};

Modal.defaultProps = {
	className: '',
};

export default Modal;
