import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Generic/Box';
import ArrowIcon from '../Assets/ArrowIcon';
import Text from '../Generic/Text';
import firstUp from '../../controllers/firstUp';

const FilterInterval = ({ header }) => {
  return (
    <Box className='filter-item filter-item-interval fs-xs'>
      <div
        className='filter-title'
        onClick={(e) => e.target.classList.toggle('filter-close')}>
        <Text>{firstUp(header)}</Text>
        <ArrowIcon />
      </div>
      <div className='filter-body'>To be developed...</div>
    </Box>
  );
};

FilterInterval.propTypes = {
  header: PropTypes.string.isRequired,
};

export default FilterInterval;
