import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Text from './Text';
import firstUp from '../../controllers/firstUp';
import { LangContext } from '../../contexts/language/LangContext';

const DisplayBox = ({ className, content, additional, noFill }) => {
  const dbRef = useRef(null);

  const handleHoverOpen = () => {
    dbRef.current && dbRef.current.classList.add('display-box-hover-open');
  };

  const handleHoverClose = () => {
    dbRef.current && dbRef.current.classList.remove('display-box-hover-open');
  };

  return (
    <div
      className={`display-box ${className} ${noFill && 'no-fill'}`}
      onMouseEnter={() => handleHoverOpen()}
      onMouseLeave={() => handleHoverClose()}>
      {content.ss ? (
        <Text text={content.number} ss={content.ss} />
      ) : (
        <h5 className='fs-l'>{content.number}</h5>
      )}
      <Text text={content.info} className='clr-grey fs-xs' />
      {additional && <HoverInfo content={additional} hoverRef={dbRef} />}
    </div>
  );
};

const HoverInfo = ({ content, hoverRef }) => {
  const {
    lang: {
      TX00054: mentorsLang,
      TX00055: creLang,
      TX00056: avLang,
      TX00005: sessionLang,
    },
  } = useContext(LangContext);

  return (
    <div ref={hoverRef} className='display-box-hover'>
      {content.map((entry, index) => (
        <Text
          key={'hover_' + index}
          className='fs-xs'
          text={`${firstUp(entry.discipline)}, ${entry.noMentors} ${
            mentorsLang.content
          }, ${avLang.content} ${entry.av} ${creLang.content} / ${
            sessionLang.content
          }`}
          icon={entry.icon}
        />
      ))}
    </div>
  );
};

DisplayBox.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string,
  additional: PropTypes.array,
  noFill: PropTypes.bool,
};

DisplayBox.defaultProps = {
  className: '',
  noFill: false,
};

HoverInfo.propTypes = {
  content: PropTypes.array.isRequired,
  hoverRef: PropTypes.object,
};

export default DisplayBox;
