import React, { createContext } from 'react';
import PropTypes from 'prop-types';

// Graphics
import walletGraph from '../../assets/img/wallet_graph.png';

import logo from '../../assets/img/GR00001-logo.svg';
import stamp from '../../assets/img/GR00004-stamp.svg';
import penLine from '../../assets/img/GR00005-pen_line.svg';
import owl from '../../assets/img/GR00006-owl.svg';
import owl_origami from '../../assets/img/GR00007-origami_owl.svg';
import owl_faraToca from '../../assets/img/GR00062-owl_fara_toca.svg';
import owl_suparat from '../../assets/img/GR00063-owl_suparat.svg';
import owl_0 from '../../assets/img/GR00052-owl_0.svg';
import owl_25 from '../../assets/img/GR00053-owl_25.svg';
import owl_50disciple from '../../assets/img/GR00054-owl_50_disciple.svg';
import owl_50mentor from '../../assets/img/GR00054-owl_50_mentor.svg';
import owl_75 from '../../assets/img/GR00055-owl_75.svg';
import bullet from '../../assets/img/GR00031-bullet.svg';

import userFemale from '../../assets/img/GR00009-user_female.svg';
import userMale from '../../assets/img/GR00009-user_male.svg';

import languagesCard_1 from '../../assets/img/GR00011-languages_card_1.svg';
import languagesCard_2 from '../../assets/img/GR00012-languages_card_2.svg';
import languagesCard_3 from '../../assets/img/GR00013-languages_card_3.svg';
import sciencesCard_1 from '../../assets/img/GR00014-sciences_card_1.svg';
import sciencesCard_2 from '../../assets/img/GR00015-sciences_card_2.svg';
import sciencesCard_3 from '../../assets/img/GR00016-sciences_card_3.svg';
import talentsCard_1 from '../../assets/img/GR00017-talents_card_1.svg';
import talentsCard_2 from '../../assets/img/GR00018-talents_card_2.svg';
import talentsCard_3 from '../../assets/img/GR00019-talents_card_3.svg';

import inkDrip_simple from '../../assets/img/GR00010-ink_drip_2.svg';
import inkDrip from '../../assets/img/GR00010-ink_drip.svg';
import penTip from '../../assets/img/GR00020-pen_tip.svg';
import star from '../../assets/img/GR00021-star.svg';
import diamondMentor from '../../assets/img/GR00023-diamond_mentor.svg';
import diamondDisciple from '../../assets/img/GR00023-diamond_disciple.svg';
import cup from '../../assets/img/GR00024-cup.svg';
import cupMentor from '../../assets/img/GR00024_1-cup_mentor.svg';
import cupDisciple from '../../assets/img/GR00024_2-cup_disciple.svg';
import morning from '../../assets/img/GR00056-morning.svg';
import lunch from '../../assets/img/GR00057-lunch.svg';
import evening from '../../assets/img/GR00058-evening.svg';
import night from '../../assets/img/GR00059-night.svg';
import planBox from '../../assets/img/GR00060-plan_box.svg';
import planArrow from '../../assets/img/GR00061-plan_arrow.svg';

import discipleRank_1 from '../../assets/img/GR00025-disciple_medal_1.svg';
import discipleRank_2 from '../../assets/img/GR00026-disciple_medal_2.svg';
import discipleRank_3 from '../../assets/img/GR00027-disciple_medal_3.svg';
import discipleRank_4 from '../../assets/img/GR00028-disciple_medal_4.svg';
import discipleRank_5 from '../../assets/img/GR00029-disciple_medal_5.svg';
import discipleRank_6 from '../../assets/img/GR00030-disciple_medal_6.svg';
import mentorRank_1 from '../../assets/img/GR00032-mentor_amulet_1.svg';
import mentorRank_2 from '../../assets/img/GR00033-mentor_amulet_2.svg';
import mentorRank_3 from '../../assets/img/GR00034-mentor_amulet_3.svg';
import mentorRank_4 from '../../assets/img/GR00035-mentor_amulet_4.svg';
import mentorRank_5 from '../../assets/img/GR00036-mentor_amulet_5.svg';
import mentorRank_6 from '../../assets/img/GR00037-mentor_amulet_6.svg';

import yogaPicture from '../../assets/img/GR00008-yoga_picture.svg';
import yogaPictureSimple from '../../assets/img/GR00008-yoga_picture_simple.svg';
import backdrop_end from '../../assets/img/backdrop_end.svg';
import onlineEducationLaptop from '../../assets/img/GR00038-online_education.svg';
import sessionIllustration from '../../assets/img/GR00040-session_illustration.svg';
import classIllustration from '../../assets/img/GR00041-class_illustration.svg';
import webinarIllustration from '../../assets/img/GR00042-webinar_illustration.svg';
import liveIllustration from '../../assets/img/GR00043-live_illustration.svg';
import supportIllustration from '../../assets/img/GR00045-support_illustration.svg';
import backdropActivity from '../../assets/img/GR00050-backdrop_activity.svg';
import wSplash from '../../assets/img/GR00064-w_splash.svg';
import mentoringBackdrop from '../../assets/img/backdrop_mentoring.png';
import educationBackdrop from '../../assets/img/backdrop_education.png';
import topMentors from '../../assets/img/top_mentors.svg';
import topDisciples from '../../assets/img/top_disciples.svg';
import milestonesMentor from '../../assets/img/milestones_mentor.svg';
import milestonesDisciple from '../../assets/img/milestones_disciple.svg';
import sessionAsDisciple from '../../assets/img/session_as_disciple.svg';
import sessionAsMentor from '../../assets/img/session_as_mentor.svg';
import classAsDisciple from '../../assets/img/class_as_disciple.svg';
import classAsMentor from '../../assets/img/class_as_mentor.svg';
import webinarAsDisciple from '../../assets/img/webinar_as_disciple.svg';
import webinarAsMentor from '../../assets/img/webinar_as_mentor.svg';
import liveAsDisciple from '../../assets/img/live_as_disciple.svg';
import liveAsMentor from '../../assets/img/live_as_mentor.svg';

import creBill from '../../assets/img/GR00044-cre_bill.svg';
import creditCard from '../../assets/img/GR00046-creditcard.svg';
import colorSpot_1 from '../../assets/img/GR00051-color_spot_1.svg';
import colorSpot_2 from '../../assets/img/GR00051-color_spot_2.svg';
import colorSpot_gradient_1 from '../../assets/img/GR00051-color_spot_gradient_1.svg';
import colorSpot_gradient_2 from '../../assets/img/GR00051-color_spot_gradient_2.svg';

// Flags
import french from '../../assets/img/icons/french.png';
import arabic from '../../assets/img/icons/arabic.svg';
import italian from '../../assets/img/icons/italian.svg';
import romanian from '../../assets/img/icons/romanian.svg';
import spanish from '../../assets/img/icons/spanish.svg';
import english from '../../assets/img/icons/english.svg';
import german from '../../assets/img/icons/german.svg';

// Icons
import plusIcon from '../../assets/img/icons/plus.svg';
import arrowIcon from '../../assets/img/icons/arrow.svg';
import checkMarkIcon from '../../assets/img/icons/check_mark.svg';
import favoritesIcon_noFill from '../../assets/img/icons/favorites_icon_no_fill.svg';
import favoritesIcon_fill from '../../assets/img/icons/favorites_icon.svg';
import mentorIcon from '../../assets/img/icons/IC00001-mentor_icon.svg';
import discipleIcon from '../../assets/img/icons/IC00002-disciple_icon.svg';
import discipleIcon_noFill from '../../assets/img/icons/IC00002_1-disciple_icon_no_fill.svg';
import sessionIcon from '../../assets/img/icons/IC00005-session_card.svg';
import classIcon from '../../assets/img/icons/IC00006-class_card.svg';
import webinarIcon from '../../assets/img/icons/IC00007-webinar_card.svg';
import liveIcon from '../../assets/img/icons/IC00008-live_mentoring_card.svg';
import walletIcon from '../../assets/img/icons/IC00023-wallet.svg';
import walletIcon_org from '../../assets/img/icons/IC00023-wallet_org.svg';
import eventsIcon from '../../assets/img/icons/IC00025-events.svg';
import eventsIcon_org from '../../assets/img/icons/IC00025-events_org.svg';
import eventsMenu from '../../assets/img/icons/IC00025-events_icon.svg';
import agendaIcon from '../../assets/img/icons/IC00026-agenda.svg';
import agendaIcon_org from '../../assets/img/icons/IC00026-agenda_org.svg';
import infoIcon from '../../assets/img/icons/IC00027-info.svg';
import infoIcon_org from '../../assets/img/icons/IC00027-info_org.svg';
import listIcon from '../../assets/img/icons/list.svg';
import gridIcon from '../../assets/img/icons/IC00029-grid.svg';
import gridIcon_org from '../../assets/img/icons/IC00029-grid_org.svg';
import attachmentIcon from '../../assets/img/icons/IC00030-attachment.svg';
import attachmentIcon_org from '../../assets/img/icons/IC00030-attachment_org.svg';
import arrowUpIcon from '../../assets/img/icons/IC00031-arrow_up.svg';
import arrowUpIcon_org from '../../assets/img/icons/IC00031-arrow_up_org.svg';
import languagesIcon from '../../assets/img/icons/IC00033-languages.svg';
import sciencesIcon from '../../assets/img/icons/IC00034-sciences.svg';
import talentsIcon from '../../assets/img/icons/IC00035-talents.svg';
import favoriteIcon from '../../assets/img/icons/IC00037-favorite.svg';
import favoriteIcon_org from '../../assets/img/icons/IC00037-favorite_org.svg';
import searchIcon from '../../assets/img/icons/IC00038-search.svg';
import searchIcon_org from '../../assets/img/icons/IC00038-search_org.svg';
import exploreIcon from '../../assets/img/icons/IC00039-explore.svg';
import exploreIcon_org from '../../assets/img/icons/IC00039-explore_org.svg';
import editIcon from '../../assets/img/icons/IC00040-edit.svg';
import editIcon_org from '../../assets/img/icons/IC00040-edit_org.svg';
import pictureEditIcon from '../../assets/img/icons/IC00041-picture_edit.svg';
import pictureEditIcon_noFill from '../../assets/img/icons/IC00041-picture_edit_nofill.svg';
import owlEye_open from '../../assets/img/icons/IC00042-owl_eye_open.svg';
import owlEye_close from '../../assets/img/icons/IC00043-owl_eye_close.svg';
import accountIcon from '../../assets/img/icons/IC00044-account.svg';
import profileIcon from '../../assets/img/icons/IC00045-profile.svg';
import communityIcon from '../../assets/img/icons/IC00046-community.svg';
import supportIcon from '../../assets/img/icons/IC00049-support.svg';
import shareIcon from '../../assets/img/icons/IC00051-share.svg';
import chatIcon from '../../assets/img/icons/IC00052-chat.svg';
import contactIcon from '../../assets/img/icons/IC00054-contact.svg';
import overviewIcon from '../../assets/img/icons/IC00058-overview.svg';
import timeIcon from '../../assets/img/icons/IC00062-time.svg';
import commonIcon from '../../assets/img/icons/IC00065-common.svg';
import continuousIcon from '../../assets/img/icons/IC00066-continuous.svg';
import persistantIcon from '../../assets/img/icons/IC00067-persistant.svg';
import messagesIcon from '../../assets/img/icons/messages.svg';
import messagesIcon_org from '../../assets/img/icons/messages_org.svg';
import medalIcon_org from '../../assets/img/icons/medal_org.svg';
import arrowFill from '../../assets/img/icons/arrowFill.svg';
import filterIcon from '../../assets/img/icons/filter.svg';
import filterIcon_org from '../../assets/img/icons/filter_org.svg';
import closeRed from '../../assets/img/icons/close_red.svg';
import activityIcon from '../../assets/img/icons/IC00060-activity.svg';
import forumIcon from '../../assets/img/icons/forum.svg';
import academyIcon from '../../assets/img/icons/academy.svg';
import dismissIcon from '../../assets/img/icons/dismiss.svg';

export const AssetContext = createContext();

const AssetState = ({ children }) => {
	const state = {
		logo,
		stamp,
		penLine,
		owl,
		owl_origami,
		owl_faraToca,
		owl_suparat,
		owl_0,
		owl_25,
		owl_50disciple,
		owl_50mentor,
		owl_75,
		bullet,
		userFemale,
		userMale,
		languagesCard_1,
		languagesCard_2,
		languagesCard_3,
		sciencesCard_1,
		sciencesCard_2,
		sciencesCard_3,
		talentsCard_1,
		talentsCard_2,
		talentsCard_3,
		inkDrip_simple,
		inkDrip,
		penTip,
		star,
		diamondMentor,
		diamondDisciple,
		cup,
		cupMentor,
		cupDisciple,
		morning,
		lunch,
		evening,
		night,
		planBox,
		planArrow,
		discipleRank_1,
		discipleRank_2,
		discipleRank_3,
		discipleRank_4,
		discipleRank_5,
		discipleRank_6,
		mentorRank_1,
		mentorRank_2,
		mentorRank_3,
		mentorRank_4,
		mentorRank_5,
		mentorRank_6,
		yogaPicture,
		yogaPictureSimple,
		onlineEducationLaptop,
		sessionIcon,
		classIcon,
		webinarIcon,
		liveIcon,
		sessionIllustration,
		classIllustration,
		webinarIllustration,
		liveIllustration,
		supportIllustration,
		backdropActivity,
		wSplash,
		creBill,
		creditCard,
		colorSpot_1,
		colorSpot_2,
		colorSpot_gradient_1,
		colorSpot_gradient_2,
		french,
		arabic,
		arrowIcon,
		checkMarkIcon,
		english,
		german,
		favoritesIcon_noFill,
		mentorIcon,
		discipleIcon,
		discipleIcon_noFill,
		italian,
		romanian,
		spanish,
		backdrop_end,
		mentoringBackdrop,
		educationBackdrop,
		topMentors,
		topDisciples,
		milestonesMentor,
		milestonesDisciple,
		sessionAsDisciple,
		sessionAsMentor,
		classAsDisciple,
		classAsMentor,
		webinarAsDisciple,
		webinarAsMentor,
		liveAsDisciple,
		liveAsMentor,
		walletIcon,
		messagesIcon,
		eventsIcon,
		agendaIcon,
		arrowFill,
		filterIcon,
		agendaIcon_org,
		infoIcon,
		infoIcon_org,
		gridIcon,
		gridIcon_org,
		listIcon,
		attachmentIcon,
		attachmentIcon_org,
		arrowUpIcon,
		arrowUpIcon_org,
		languagesIcon,
		sciencesIcon,
		talentsIcon,
		favoriteIcon,
		favoriteIcon_org,
		searchIcon,
		searchIcon_org,
		exploreIcon,
		exploreIcon_org,
		editIcon,
		editIcon_org,
		pictureEditIcon,
		pictureEditIcon_noFill,
		owlEye_open,
		owlEye_close,
		accountIcon,
		profileIcon,
		communityIcon,
		supportIcon,
		chatIcon,
		contactIcon,
		overviewIcon,
		timeIcon,
		commonIcon,
		continuousIcon,
		persistantIcon,
		messagesIcon_org,
		medalIcon_org,
		filterIcon_org,
		walletIcon_org,
		eventsIcon_org,
		eventsMenu,
		closeRed,
		activityIcon,
		favoritesIcon_fill,
		forumIcon,
		academyIcon,
		dismissIcon,
		shareIcon,
		walletGraph,
		plusIcon,
	};

	return (
		<AssetContext.Provider value={{ ...state }}>{children}</AssetContext.Provider>
	);
};

AssetState.propTypes = {
	children: PropTypes.element.isRequired,
};

export default AssetState;
