// category "ids" in the code: session - 0, class - 1, webinar - 2, live - 3

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';
import getIndexOf from '../../controllers/getIndexOf';
import CategoryInfo from '../../modules/Category/CategoryInfo';
import InkBackdrop from '../../components/Generic/InkBackdrop';

const Category = ({ className, categoryProp, white }) => {
  const [indexOfCategory, setIndexOfCategory] = useState(null);
  let { category } = useParams();
  category = categoryProp ?? category;

  const {
    lang: {
      TX00022: languagesCat,
      TX00052: text1Languages,
      TX00025: text2Languages,

      TX00023: sciencesCat,
      TX00057: text1Sciences,
      TX00026: text2Sciences,

      TX00024: talentsCat,
      TX00059: text1Talents,
      TX00027: text2Talents,

      TX00054: mentorsLang,
      TX00055: creLang,
      TX00056: avLang,
      TX00005: sessionLang,
    },
  } = useContext(LangContext);

  const { languagesCard_1, sciencesCard_1, talentsCard_1 } =
    useContext(AssetContext);

  const categoryArray = [
    languagesCat.content,
    sciencesCat.content,
    talentsCat.content,
  ];

  const languagesPageContent = {
    header: languagesCat.content,
    subheader: text1Languages.content,
    paragraph: text2Languages.content,
  };

  const sciencesPageContent = {
    header: sciencesCat.content,
    subheader: text1Sciences.content,
    paragraph: text2Sciences.content,
  };

  const talentsPageContent = {
    header: talentsCat.content,
    subheader: text1Talents.content,
    paragraph: text2Talents.content,
  };

  const languagesInfo = {
    category: {
      info: languagesCat.content,
      number: 10,
    },
    mentors: {
      info: mentorsLang.content,
      number: 40,
    },
    price: {
      info: `${avLang.content} / ${sessionLang.content}`,
      number: 7,
      ss: creLang.content,
    },
  };
  const sciencesInfo = {
    category: {
      info: sciencesCat.content,
      number: 10,
    },
    mentors: {
      info: mentorsLang.content,
      number: 40,
    },
    price: {
      info: `${avLang.content} / ${sessionLang.content}`,
      number: 7,
      ss: creLang.content,
    },
  };
  const talentsInfo = {
    category: {
      info: talentsCat.content,
      number: 10,
    },
    mentors: {
      info: mentorsLang.content,
      number: 40,
    },
    price: {
      info: `${avLang.content} / ${sessionLang.content}`,
      number: 7,
      ss: creLang.content,
    },
  };

  const availableContent = [
    languagesPageContent,
    sciencesPageContent,
    talentsPageContent,
  ];

  const availableInfo = [languagesInfo, sciencesInfo, talentsInfo];

  const availableImgs = [languagesCard_1, sciencesCard_1, talentsCard_1];

  useEffect(() => {
    const indexOfCategory = getIndexOf(category, categoryArray);
    setIndexOfCategory(indexOfCategory);

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`category-page-container ${
        categoryProp && 'category-component'
      }`}>
      <InkBackdrop className='category-page-container-backdrop' />
      <section className='container'>
        {indexOfCategory !== null && (
          <div className={`category-page-content ${className}`}>
            <CategoryInfo
              content={{ availableContent, availableInfo, indexOfCategory }}
              white={white}
            />
            <img
              src={availableImgs[indexOfCategory]}
              alt={`activity-background-${indexOfCategory}`}
            />
          </div>
        )}
      </section>
    </div>
  );
};

Category.propTypes = {
  className: PropTypes.string,
  categoryProp: PropTypes.string,
  white: PropTypes.bool,
};

Category.defaultProps = {
  className: '',
  white: true,
};

export default Category;
