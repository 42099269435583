import React, { useState } from 'react';

// import PropTypes from 'prop-types'

const SearchBar = () => {
  const [text, setText] = useState('');

  return (
    <div className='search-bar'>
      <input
        type='text'
        name='search'
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder={'Search'}
      />
    </div>
  );
};

// SearchBar.propTypes = {

// };

export default SearchBar;
