import React from 'react';
import PropTypes from 'prop-types';
import DisplayBox from '../../components/Generic/DisplayBox';
import ShowcaseTexts from '../../components/Generic/ShowcaseTexts';

const CategoryInfo = ({ content, white }) => {
  const { availableContent, availableInfo, indexOfCategory } = content;

  const categoryInfo = [
    { discipline: 'English', noMentors: 10, av: 5 },
    { discipline: 'English', noMentors: 10, av: 5 },
    { discipline: 'English', noMentors: 10, av: 5 },
    { discipline: 'English', noMentors: 10, av: 5 },
  ];

  return (
    <div className='category-page-info'>
      <ShowcaseTexts
        white={white}
        content={availableContent[indexOfCategory]}
      />
      <div className='boxes'>
        <DisplayBox
          content={availableInfo[indexOfCategory].category}
          additional={categoryInfo}
        />
        <DisplayBox content={availableInfo[indexOfCategory].mentors} />
        <DisplayBox content={availableInfo[indexOfCategory].price} />
      </div>
    </div>
  );
};

CategoryInfo.propTypes = {
  className: PropTypes.string,
  white: PropTypes.bool,
  content: PropTypes.shape({
    availableContent: PropTypes.array.isRequired,
    availableInfo: PropTypes.array.isRequired,
    indexOfCategory: PropTypes.number.isRequired,
  }).isRequired,
};

CategoryInfo.defaultProps = {
  className: '',
};

export default CategoryInfo;
