import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AssetContext } from '../asset/AssetContext';
import langReducer from './langReducer';
import { CHANGE_LANG, SET_AV_LANGS } from './types';
import getFirstTwo from '../../controllers/getFirstTwo';
import languages from '../../assets/languages/languages';

export const LangContext = createContext();

const LangState = ({ children }) => {
  const initialState = {
    languageSelected: '',
    lang: localStorage.lang
      ? languages[`${localStorage.lang}`]
      : languages.english,
    availableLangs: [],
  };

  const [state, dispatch] = useReducer(langReducer, initialState);

  const assets = useContext(AssetContext);

  const getAvLangs = () => {
    const payload = Object.keys(languages).map((lang) => ({
      text: lang,
      icon: assets[`${lang}`],
    }));

    dispatch({ type: SET_AV_LANGS, payload });
  };

  const getAvLangsList = () => {
    const list = [];
    const { availableLangs } = state;

    // eslint-disable-next-line
    availableLangs.map((lang) => list.push(lang.text));
    return list;
  };

  const changeLang = (lang = 'english') => {
    localStorage.setItem('lang', lang);
    const payload = {
      lang: languages[`${lang}`],
      short: getFirstTwo(lang),
    };
    dispatch({ type: CHANGE_LANG, payload });
  };

  const loadLang = () =>
    localStorage.lang ? changeLang(localStorage.lang) : changeLang();

  return (
    <LangContext.Provider
      value={{
        ...state,
        dispatch,
        changeLang,
        getAvLangs,
        getAvLangsList,
        loadLang,
      }}>
      {children}
    </LangContext.Provider>
  );
};

LangState.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LangState;
