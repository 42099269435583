import React, { useContext } from 'react';
import { LangContext } from '../../contexts/language/LangContext';
import firstUp from '../../controllers/firstUp';
import Selector from '../Generic/Selector';
import Logo from '../Assets/Logo';
import PenLine from '../Assets/PenLine';
import PropTypes from 'prop-types';
import To from './To';

const NavigationBarMain = ({ white }) => {
  const {
    lang: { TX00046: explore, TX00028: library, TX00048: join },
    languageSelected,
    availableLangs,
  } = useContext(LangContext);

  const selectorContent = {
    text: languageSelected,
    list: availableLangs,
  };

  return (
    <nav className={`nav-main ${white && 'clr-white'}`}>
      <div className='container'>
        <PenLine white={white} right className='penline' />
        <Logo white={white} stroke />
        <To to={`/${explore.content}`}>{firstUp(explore.content)}</To>
        <To to={`/${library.content}`}>{firstUp(library.content)}</To>
        <Selector white={white} icon content={selectorContent} />
        <To to={`/${join.content}`}>{firstUp(join.content)}</To>
      </div>
    </nav>
  );
};

NavigationBarMain.propTypes = {
  white: PropTypes.bool,
};

NavigationBarMain.defaultProps = {
  white: false,
};

export default NavigationBarMain;
