import React from 'react';
import PropTypes from 'prop-types';
import UserProfileInfo from '../../components/UserProfile/UserProfileInfo';
import UserProfilePills from '../../components/UserProfile/UserProfilePills';

const UserProfileGeneral = ({ user }) => {
  return (
    <>
      <UserProfileInfo user={user} />
      <UserProfilePills role={user.role} />
    </>
  );
};

UserProfileGeneral.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserProfileGeneral;
