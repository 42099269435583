import React from 'react';
import PropTypes from 'prop-types';
import firstUp from '../../controllers/firstUp';
import PenLine from '../Assets/PenLine';
import Text from './Text';

const Separator = ({
  text,
  white,
  side,
  right,
  icon,
  className,
  children,
  vertical,
}) => {
  if (side)
    return (
      <div
        className={`separator-side ${
          right && 'separator-side-right'
        } ${className}`}>
        <h3 className={`fs-l fw-b ${white && 'clr-white'}`}>{firstUp(text)}</h3>
        {icon && <img src={icon} alt={`webinclass-${text}-icon`} />}
        {children && children}
      </div>
    );

  if (vertical)
    return (
      <div className='separator-vertical'>
        <Text className={`${white && 'clr-white'} ${className}`}>{text}</Text>
      </div>
    );

  return (
    <div className={`separator ${className}`}>
      <PenLine right className='separator-line' white={white} />
      <h3 className={`fs-xxxl fw-b ${white && 'clr-white'}`}>
        {firstUp(text)}
      </h3>
      <PenLine className='separator-line' white={white} />
    </div>
  );
};

Separator.propTypes = {
  text: PropTypes.string.isRequired,
  white: PropTypes.bool,
  side: PropTypes.bool,
  right: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  vertical: PropTypes.bool,
};

Separator.defaultProps = {
  white: false,
  side: false,
  right: false,
  vertical: false,
  className: '',
};

export default Separator;
