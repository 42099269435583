// activity "ids" in the code: session - 0, class - 1, webinar - 2, live - 3

import React, { useContext, useEffect, useState } from 'react';
import ActivityHero from '../../components/Activity/ActivityHero';
import Separator from '../../components/Generic/Separator';
import { LangContext } from '../../contexts/language/LangContext';
import { useParams } from 'react-router';
import ActivityIcon from '../../components/Assets/ActivityIcon';
import ActivityCards from '../../modules/Activity/ActivityCards';
import ActivityInfo from '../../modules/Activity/ActivityInfo';
import ActivitySteps from '../../modules/Activity/ActivitySteps';

const Activity = () => {
  const [activitySelected, setActivitySelected] = useState('');

  const {
    lang: {
      TX00005: sessionLang,
      TX00006: classLang,
      TX00007: webinarLang,
      TX00008: liveLang,

      TX00062: HowStartLang,

      TX00073: WhatIsSessionLang,
      TX00083: SessionAsDiscipleLang,
      TX00097: SessionAsMentorLang,

      TX00110: WhatIsClassLang,
      TX00114: ClassAsDiscipleLang,
      TX00124: ClassAsMentorLang,

      TX00134: WhatIsWebinarLang,
      TX00136: WebinarAsDiscipleLang,
      TX00142: WebinarAsMentorLang,

      TX00149: WhatIsLiveLang,
      TX00151: LiveAsDiscipleLang,
      TX00156: LiveAsMentorLang,
    },
  } = useContext(LangContext);

  const { activity } = useParams();

  const separators = {
    session: [
      WhatIsSessionLang.content,
      SessionAsDiscipleLang.content,
      SessionAsMentorLang.content,
    ],
    class: [
      WhatIsClassLang.content,
      ClassAsDiscipleLang.content,
      ClassAsMentorLang.content,
    ],
    webinar: [
      WhatIsWebinarLang.content,
      WebinarAsDiscipleLang.content,
      WebinarAsMentorLang.content,
    ],
    live: [
      WhatIsLiveLang.content,
      LiveAsDiscipleLang.content,
      LiveAsMentorLang.content,
    ],
  };

  useEffect(() => {
    window.scroll({ top: 0 });

    activity === sessionLang.content
      ? setActivitySelected('session')
      : activity === classLang.content
      ? setActivitySelected('class')
      : activity === webinarLang.content
      ? setActivitySelected('webinar')
      : activity === liveLang.content
      ? setActivitySelected('live')
      : setActivitySelected(null);

    // eslint-disable-next-line
  }, []);

  return (
    <main className='activity-page'>
      {activitySelected && (
        <>
          <ActivityHero activity={activitySelected} />
          <Separator
            side
            text={HowStartLang.content}
            className={`separator-side-${activitySelected}`}
          />
          <ActivitySteps />
          <Separator
            side
            right
            text={separators[`${activitySelected}`][0]}
            className={`separator-side-${activitySelected}`}
          />
          <ActivityCards activity={activity} />
          <Separator
            side
            text={separators[`${activitySelected}`][1]}
            className={`separator-side-${activitySelected}`}>
            <ActivityIcon disciple activity={activity} />
          </Separator>
          <ActivityInfo activity={activity} type='disciple' />
          <Separator
            side
            right
            text={separators[`${activitySelected}`][2]}
            className={`separator-side-${activitySelected}`}>
            <ActivityIcon mentor activity={activity} />
          </Separator>
          <ActivityInfo activity={activity} type='mentor' />
        </>
      )}
    </main>
  );
};

export default Activity;
