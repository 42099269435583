import React, { useContext } from 'react';
import Box from '../../../components/Generic/Box';
import OwlTeacher from '../../../components/Generic/OwlTeacher';
import Text from '../../../components/Generic/Text';
// import PropTypes from 'prop-types'
import { AuthContext } from '../../../contexts/auth/AuthContext';
import { LangContext } from '../../../contexts/language/LangContext';
import firstUp from '../../../controllers/firstUp';
import { AssetContext } from '../../../contexts/asset/AssetContext';

const WalletOverview = () => {
	const {
		lang: {
			TX00260: { content: weCareLang },
			TX00261: { content: enjoyMentoringLang },
			TX00263: { content: owlTeacherTitleLang },
			TX00264: { content: owlTeacherTextLang },
			TX00378: { content: investInYourselfLang },
		},
	} = useContext(LangContext);

	const {
		user: { role },
	} = useContext(AuthContext);

	const { milestonesMentor, milestonesDisciple } = useContext(AssetContext);

	const owlContent = { title: owlTeacherTitleLang, list: [owlTeacherTextLang] };

	return (
		<section className='wallet-overview mw-70'>
			<Box>
				<header>
					<h5>{firstUp(weCareLang)}</h5>
					<OwlTeacher className={role} content={owlContent} />
				</header>
				<div className='wallet-overview-body'>
					<div className='left'>
						<Text>{firstUp(enjoyMentoringLang)}</Text>
						<Text>{firstUp(investInYourselfLang)}</Text>
					</div>
					<div className='right'>
						<img
							src={role === 'mentor' ? milestonesMentor : milestonesDisciple}
							alt='milestones'
						/>
					</div>
				</div>
			</Box>
		</section>
	);
};

// WalletOverview.propTypes = {};

export default WalletOverview;
