const german = {
  TX00001: {
    content: 'lernen. Erkunden. Verwandeln.',
    tooltip: '',
  },
  TX00002: {
    content: 'die umfassendste Online-Bildungsplattform.',
    tooltip: '',
  },
  TX00003: {
    content: 'Der Lernprozess ist eine unendliche Reise.',
    tooltip: '',
  },
  TX00004: {
    content: 'lerne uns besser kennen',
    tooltip: '',
  },
  TX00005: {
    content: 'Session',
    tooltip: '',
  },
  TX00006: {
    content: 'Klasse',
    tooltip: '',
  },
  TX00007: {
    content: 'Webinar',
    tooltip: '',
  },
  TX00008: {
    content: 'wohnen',
    tooltip: '',
  },
  TX00009: {
    content:
      'den passenden Punkt Ihres Ziels, nach dem Sie sich sehnen, und den Wunsch des Montors zu unterrichten.',
    tooltip: '',
  },
  TX00010: {
    content:
      'Eins-zu-eins-Online-Sitzungen sind am effektivsten, wenn Sie versuchen zu lernen.',
    tooltip: '',
  },
  TX00011: {
    content:
      'Treffen Sie sich mit Kollegen, die das gleiche Interesse haben wie Sie, und entdecken Sie die nächste Lernstufe.',
    tooltip: '',
  },
  TX00012: {
    content:
      'Definieren Sie Ihren Lernstil, indem Sie verschiedene andere Stile analysieren.',
    tooltip: '',
  },
  TX00013: {
    content:
      'Vielzahl von Themen, Ergebnis der Erfahrung und Überprüfung der Informationen von einem Mentor an Sie alle.',
    tooltip: '',
  },
  TX00014: {
    content: 'Aktuelle, heiße und breite Themen, die Ihre Erfahrung beleuchten',
    tooltip: '',
  },
  TX00015: {
    content:
      'ein Mentor. Eine Stunde. Mehr Fragen. Finden Sie Ihre Antworten und klären Sie Szenarien.',
    tooltip: '',
  },
  TX00016: {
    content:
      'Nehmen Sie von überall aus online an einem Echtzeitgespräch teil.',
    tooltip: '',
  },
  TX00017: {
    content: 'Mentor',
    tooltip: '',
  },
  TX00018: {
    content: 'Schüler',
    tooltip: '',
  },
  TX00019: {
    content:
      'Teilen Sie Ihr Wissen weltweit. Helfen Sie anderen, ihre Ziele zu erreichen. Sie fragen sich vielleicht, wie das geht? Treten Sie unserer Community als Mentor bei und geben Sie anderen Zugriff auf Ihr Informationsimperium. Seien Sie der pädagogische Führer, den Sie als Anfänger immer brauchten.',
    tooltip: '',
  },
  TX00020: {
    content:
      'Haben Sie jemals ein Missverhältnis zwischen einem Fach, für das Sie eine Leidenschaft hatten, und demjenigen, das Ihnen dieses Fach beibringt, erlebt? Jetzt können Sie den am besten geeigneten Mentor für sich selbst auswählen. Wählen Sie Ihren Mentor aus unserer vielfältigen Community.',
    tooltip: '',
  },
  TX00021: {
    content: 'Beginne deine Reise',
    tooltip: '',
  },
  TX00022: {
    content: 'Sprachen',
    tooltip: '',
  },
  TX00023: {
    content: 'Wissenschaften',
    tooltip: '',
  },
  TX00024: {
    content: 'Talente',
    tooltip: '',
  },
  TX00025: {
    content:
      'Machen Sie Ihre Welt zu einem besseren Ort und geben Sie Ihnen das beste Geschenk: andere in ihrer eigenen Sprache zu verstehen.',
    tooltip: '',
  },
  TX00026: {
    content:
      'Geh zum Mond und zurück! Erfinde und erfinde dich durch Wissenschaft neu und verbessere die Welt, in der du lebst.',
    tooltip: '',
  },
  TX00027: {
    content:
      'Der beste Weg, um Ihre Gefühle, Ihre Leidenschaft und Ihre Persönlichkeit auszudrücken, sind Ihre Talente.',
    tooltip: '',
  },
  TX00028: {
    content: 'Bibliothek',
    tooltip: 'Check our selection of documents',
  },
  TX00029: {
    content: 'Zertifizierungen',
    tooltip: '',
  },
  TX00030: {
    content: 'Akademie',
    tooltip: '',
  },
  TX00031: {
    content:
      'Bibliotheken sind wichtige Eckpfeiler einer gesunden Gemeinschaft. Bibliotheken bieten Menschen die Möglichkeit, Arbeit zu finden, Forschung zu erforschen, neue Ideen zu erfahren und sich in wundervollen Geschichten zu verlieren.',
    tooltip: '',
  },
  TX00032: {
    content:
      'Eine Zertifizierung ist eine Berechtigung, die Sie erwerben, um nachzuweisen, dass Sie über bestimmte Fähigkeiten oder Kenntnisse verfügen. Sie sind normalerweise an einen Beruf, eine Technologie oder eine Branche gebunden.',
    tooltip: '',
  },
  TX00033: {
    content:
      'Akademien sind unabhängige, staatlich finanzierte Schulen, die ihre Finanzierung direkt von der Zentralregierung und nicht von einer lokalen Behörde erhalten. Sie kontrollieren ihren eigenen Zulassungsprozess.',
    tooltip: '',
  },
  TX00034: {
    content: 'Nimm unser Wort nicht dafür',
    tooltip: '',
  },
  TX00035: {
    content: 'weitermachen mit',
    tooltip: '',
  },
  TX00036: {
    content: 'Facebook',
    tooltip: '',
  },
  TX00037: {
    content: 'Google',
    tooltip: '',
  },
  TX00038: {
    content: 'Apfel',
    tooltip: '',
  },
  TX00039: {
    content: 'Email',
    tooltip: '',
  },
  TX00040: {
    content: 'Passwort',
    tooltip: '',
  },
  TX00041: {
    content: 'oder',
    tooltip: '',
  },
  TX00042: {
    content: 'Sie haben bereits ein Konto?',
    tooltip: '',
  },
  TX00043: {
    content: 'registrieren',
    tooltip: '',
  },
  TX00044: {
    content: 'Anmelden',
    tooltip: '',
  },
  TX00045: {
    content: 'Probleme haben?',
    tooltip: '',
  },
  TX00046: {
    content: 'erkunden',
    tooltip: 'find a mentor',
  },
  TX00047: {
    content: 'Mappe',
    tooltip: '',
  },
  TX00048: {
    content: 'beitreten',
    tooltip: 'Enroll in our community',
  },
  TX00049: {
    content: 'Mehr…',
    tooltip: 'More information',
  },
  TX00050: {
    content: 'Bildung',
    tooltip: '',
  },
  TX00051: {
    content: 'Mentoring',
    tooltip: '',
  },
  TX00052: {
    content: 'Kommunikation ist eine sehr wichtige Fähigkeit.',
    tooltip: '',
  },
  TX00053: {
    content: 'Sprache',
    tooltip: '',
  },
  TX00054: {
    content: 'Mentoren',
    tooltip: '',
  },
  TX00055: {
    content: 'CRE',
    tooltip: '',
  },
  TX00056: {
    content: 'ein V.',
    tooltip: '',
  },
  TX00057: {
    content: 'Wissenschaft ist der Keim des Fortschritts.',
    tooltip: '',
  },
  TX00058: {
    content: 'Wissenschaft',
    tooltip: '',
  },
  TX00059: {
    content: 'der Ausdruck Ihrer Gefühle.',
    tooltip: '',
  },
  TX00060: {
    content: 'Talent',
    tooltip: '',
  },
  TX00061: {
    content: 'Sitzungen',
    tooltip: '',
  },
  TX00062: {
    content: 'wie fängst du an',
    tooltip: '',
  },
  TX00063: {
    content: 'ein Konto erstellen',
    tooltip: '',
  },
  TX00064: {
    content:
      'Vergessen Sie nach dem Erstellen eines Kontos nicht, alle Ihre Informationen im Abschnitt "Kontoeinstellungen" hinzuzufügen.',
    tooltip: '',
  },
  TX00065: {
    content: 'Dies wird anderen helfen, Ihnen leichter zu vertrauen.',
    tooltip: '',
  },
  TX00066: {
    content: 'Verbinden Sie Ihre Kreditkarte',
    tooltip: '',
  },
  TX00067: {
    content: 'Dies wird zum Kauf oder Abheben von CRE verwendet.',
    tooltip: '',
  },
  TX00068: {
    content: '1 CRE = 1 EUR',
    tooltip: '',
  },
  TX00069: {
    content: 'werde Teil der Gemeinschaft',
    tooltip: '',
  },
  TX00070: {
    content: 'ein Interesse als Schüler hinzufügen.',
    tooltip: '',
  },
  TX00071: {
    content: 'Fügen Sie eine Disziplin als Mentor hinzu.',
    tooltip: '',
  },
  TX00072: {
    content:
      'Schauen Sie sich unsere große Auswahl an Mentoren an. Wir sind sicher, dass Sie eine perfekte Übereinstimmung finden werden.',
    tooltip: '',
  },
  TX00073: {
    content: 'Was ist eine Sitzung?',
    tooltip: '',
  },
  TX00074: {
    content: 'Pilot',
    tooltip: '',
  },
  TX00075: {
    content: 'eins zu eins',
    tooltip: '',
  },
  TX00076: {
    content: 'wiederkehrend',
    tooltip: '',
  },
  TX00077: {
    content: 'Zertifizierung',
    tooltip: '',
  },
  TX00078: {
    content: 'Die erste Sitzung mit Ihrem Mentor ist eine Pilotsitzung.',
    tooltip: '',
  },
  TX00079: {
    content:
      'Hinweis: Wenn Sie Ihre Pilotsitzung zufällig nicht genießen, können Sie eine Rückerstattung beantragen.',
    tooltip: '',
  },
  TX00080: {
    content: 'Ad-hoc-Sitzung mit Ihrem Mentor ohne Häufigkeit.',
    tooltip: '',
  },
  TX00081: {
    content:
      'Eine Sitzung, die auf der Grundlage einer wöchentlichen Wiederholung am selben Tag und zur selben Zeit stattfindet.',
    tooltip: '',
  },
  TX00082: {
    content: 'ein Sitzungsteil eines Zertifizierungsprogramms.',
    tooltip: '',
  },
  TX00083: {
    content: 'Sitzung als Schüler',
    tooltip: '',
  },
  TX00084: {
    content:
      'kann nur von einem Schüler geplant werden und die CRE wird gehalten, bis die Sitzung abgeschlossen ist',
    tooltip: '',
  },
  TX00085: {
    content:
      'Bei wiederkehrenden Sitzungen können Sie die verfügbaren Pakete Ihres Mentors überprüfen, um Rabatte zu erhalten',
    tooltip: '',
  },
  TX00086: {
    content:
      'Das Festlegen des Zeitplans erfolgt anhand Ihrer eigenen Agenda oder anhand des Verfügbarkeitszeitraums, der im Profil eines Mentors angezeigt wird',
    tooltip: '',
  },
  TX00087: {
    content:
      'Wenn Sie Hausaufgaben oder Material erhalten, können Sie es in der Bibliothek finden',
    tooltip: '',
  },
  TX00088: {
    content: 'mit mindestens 4 Stunden vor dem Start:',
    tooltip: '',
  },
  TX00091: {
    content:
      'Wenn eine Sitzung gestartet wurde und der Besprechungsraum auf der Plattform eingerichtet ist, können Sie über Ihre Sitzungskarte in der Tagesordnung auf den Raum zugreifen',
    tooltip: '',
  },
  TX00092: {
    content:
      'Sobald die Sitzung beendet ist, können Sie sie als abgeschlossen markieren (nach 72 Stunden wird sie automatisch als abgeschlossen für Sie markiert).',
    tooltip: '',
  },
  TX00093: {
    content: 'Bewertungen werden immer helfen:',
    tooltip: '',
  },
  TX00097: {
    content: 'Sitzung als Mentor',
    tooltip: '',
  },
  TX00098: {
    content:
      'Ein Mentor kann den Verfügbarkeitszeitrahmen für eine bestimmte Disziplin festlegen, und ein Schüler kann eine Sitzung nur während dieses festgelegten Zeitraums planen',
    tooltip: '',
  },
  TX00099: {
    content:
      'Nachdem eine Sitzung geplant ist, können Sie Folgendes hinzufügen:',
    tooltip: '',
  },
  TX00103: {
    content:
      'Sie können die Sitzung jederzeit vor dem Start aktualisieren oder abbrechen. Stellen Sie jedoch sicher, dass Sie Ihrem Schüler eine Nachricht darüber senden',
    tooltip: '',
  },
  TX00104: {
    content:
      'Wenn eine Sitzung gestartet wurde und der Besprechungsraum auf der Plattform eingerichtet ist, können Sie über Ihre Sitzungskarte in der Tagesordnung auf den Raum zugreifen',
    tooltip: '',
  },
  TX00105: {
    content: 'Sobald die Sitzung als co markiert ist',
    tooltip: '',
  },
  TX00106: {
    content: 'review and feedback will always help:',
    tooltip: '',
  },
  TX00107: {
    content: '— you to commit to your disciples even more',
    tooltip: '',
  },
  TX00108: {
    content: '— your disciples to keep on track with their goals',
    tooltip: '',
  },
  TX00109: {
    content: 'classes',
    tooltip: '',
  },
  TX00110: {
    content: 'what is a class?',
    tooltip: '',
  },
  TX00111: {
    content: 'one to Many',
    tooltip: '',
  },
  TX00112: {
    content: 'ad hoc class with your mentor and peers without any frequency.',
    tooltip: '',
  },
  TX00113: {
    content:
      'a class that will take place based on a weekly reccurency, on the same day and at the same time.',
    tooltip: '',
  },
  TX00114: {
    content: 'class as a disciple',
    tooltip: '',
  },
  TX00115: {
    content: 'can be scheduled only by a mentor',
    tooltip: '',
  },
  TX00116: {
    content:
      'for recurrent classes feel free to check your mentor’s available packages in order to get discounts',
    tooltip: '',
  },
  TX00117: {
    content:
      'you can attend a class by accepting an invitation from your mentor or by joining through your agenda',
    tooltip: '',
  },
  TX00118: {
    content:
      'when enrolling to a class your CRE will be held until it is completed',
    tooltip: '',
  },
  TX00119: {
    content:
      'if you receive any homework or material you will be able to find it in the library',
    tooltip: '',
  },
  TX00120: {
    content: '— you can cancel the class',
    tooltip: '',
  },
  TX00121: {
    content: '— you can confirm or cancel the class if any changes occurred',
    tooltip: '',
  },
  TX00122: {
    content:
      'when a class has started and the meeting room is set up on the platform you can access the room from your class card in the agenda',
    tooltip: '',
  },
  TX00123: {
    content:
      'once the class is finished you can mark it as complete (after 72 hours it will automatically be marked as complete for you)  ',
    tooltip: '',
  },
  TX00124: {
    content: 'class as a mentor',
    tooltip: '',
  },
  TX00125: {
    content:
      'a mentor can set up classes and then invite or leave the seats empty for disciples to attend',
    tooltip: '',
  },
  TX00126: {
    content: 'when a class is scheduled you can add to it:',
    tooltip: '',
  },
  TX00127: {
    content:
      '— a title and topic for your disciples to understand what the class will be about',
    tooltip: '',
  },
  TX00128: {
    content: '— a file from the library',
    tooltip: '',
  },
  TX00129: {
    content: '— homework',
    tooltip: '',
  },
  TX00130: {
    content:
      'you can update or cancel the class any time before it starts, but make sure to message your disciples about it',
    tooltip: '',
  },
  TX00131: {
    content:
      'when a class started and the meeting room is set up on the platform you can access the room from your class card in the agenda',
    tooltip: '',
  },
  TX00132: {
    content:
      'once the class is marked as completed (done by disciples or automatically after 72 hours) CRE will be allocated to your wallet ',
    tooltip: '',
  },
  TX00133: {
    content: 'webinars',
    tooltip: '',
  },
  TX00134: {
    content: 'what is a webinar?',
    tooltip: '',
  },
  TX00135: {
    content:
      'a webinar allows a speaker from the hosting organization/company to share PowerPoint presentations, videos, web pages or other multimedia content with audiences that can be located anywhere. Webinars typically have audio and visual components.',
    tooltip: '',
  },
  TX00136: {
    content: 'webinar as a disciple',
    tooltip: '',
  },
  TX00137: {
    content:
      'you can attend a webinar by joining through your agenda and your CRE will be held until it is completed',
    tooltip: '',
  },
  TX00138: {
    content: '— you can cancel the webinar',
    tooltip: '',
  },
  TX00139: {
    content: '— you can confirm or cancel the webinar if any changes occurred',
    tooltip: '',
  },
  TX00140: {
    content:
      'when a webinar has started you can access the room from your webinar card in the agenda',
    tooltip: '',
  },
  TX00141: {
    content:
      'once the webinar is finished you can mark it as complete (after 72 hours it will automatically be marked as complete for you)  ',
    tooltip: '',
  },
  TX00142: {
    content: 'webinar as a mentor',
    tooltip: '',
  },
  TX00143: {
    content:
      'a mentor can set up webinars with a maximum number disciple attendance',
    tooltip: '',
  },
  TX00144: {
    content: 'when a webinar is scheduled you can add to it:',
    tooltip: '',
  },
  TX00145: {
    content:
      '— a title and topic for your disciples to understand what the webinar will be about',
    tooltip: '',
  },
  TX00146: {
    content: 'you can update or cancel the webinar any time',
    tooltip: '',
  },
  TX00147: {
    content:
      'when a webinar has started and the meeting room is set up on the platform you can access the room from your webinar card in the agenda',
    tooltip: '',
  },
  TX00148: {
    content:
      'once the webinar is marked as completed (done by disciples or automatically after 72 hours) CRE will be allocated to your wallet ',
    tooltip: '',
  },
  TX00149: {
    content: 'what is live mentoring?',
    tooltip: '',
  },
  TX00150: {
    content:
      'a live allows a speaker from the hosting organization/company to share PowerPoint presentations, videos, web pages or other multimedia content with audiences that can be located anywhere. Webinars typically have audio and visual components.',
    tooltip: '',
  },
  TX00151: {
    content: 'live as a disciple',
    tooltip: '',
  },
  TX00152: {
    content:
      'you can attend a live by joining through your agenda and your CRE will be held until it is completed',
    tooltip: '',
  },
  TX00153: {
    content: 'before it starts:',
    tooltip: '',
  },
  TX00154: {
    content: '— you can cancel the attendancy',
    tooltip: '',
  },
  TX00155: {
    content:
      'when a live has started you can access the room from your live card in the agenda',
    tooltip: '',
  },
  TX00156: {
    content: 'live as a mentor',
    tooltip: '',
  },
  TX00157: {
    content:
      'a mentor can set up a live mentoring activity with an unlimited number of disciples',
    tooltip: '',
  },
  TX00158: {
    content: 'you can cancel the live at any time before it starts',
    tooltip: '',
  },
  TX00159: {
    content:
      'when a live has started you can access the room from your live card in the agenda',
    tooltip: '',
  },
  TX00160: {
    content:
      'once the live is finished CRE will be allocated to your wallet  based on the attendancy list',
    tooltip: '',
  },
  TX00161: {
    content: 'WebinClass',
    tooltip: '',
  },
  TX00162: {
    content: 'the most complete online platform for education.',
    tooltip: '',
  },
  TX00163: {
    content:
      'easy and friendly designed, to cover all types of online mentoring and become a leader in this industry.',
    tooltip: '',
  },
  TX00164: {
    content: 'how it works',
    tooltip: '',
  },
  TX00165: {
    content: 'join our platform! Create an account',
    tooltip: '',
  },
  TX00166: {
    content: 'accept legal requirements',
    tooltip: '',
  },
  TX00167: {
    content: 'become part of the community',
    tooltip: '',
  },
  TX00168: {
    content: 'make the most of your knowledge',
    tooltip: '',
  },
  TX00169: {
    content:
      'once you create an account don’t forget to add all your info in the account settings section.',
    tooltip: '',
  },
  TX00170: {
    content: 'this will help others trust you more easily.',
    tooltip: '',
  },
  TX00171: {
    content:
      '— connect your credit card. This will be used for withdrawing CRE.',
    tooltip: '',
  },
  TX00172: {
    content: '— read and agree to the terms and conditions.',
    tooltip: '',
  },
  TX00173: {
    content: '— read and agree to the GDPR.',
    tooltip: '',
  },
  TX00174: {
    content: '— sign the contract.',
    tooltip: '',
  },
  TX00175: {
    content:
      '— connect your credit card. This will be used for purchasing CRE.',
    tooltip: '',
  },
  TX00176: {
    content:
      'add a discipline that defines you as a mentor, challenge yourself to become one of the best!',
    tooltip: '',
  },
  TX00177: {
    content: '— describe yourself as a mentor. Add all details and a video.',
    tooltip: '',
  },
  TX00178: {
    content: '— attach your degrees and show others how well prepared you are.',
    tooltip: '',
  },
  TX00179: {
    content: '— determine charges. Define the value of your work.',
    tooltip: '',
  },
  TX00180: {
    content: '— set up your availability.',
    tooltip: '',
  },
  TX00181: {
    content:
      'add an interest that defines you as a disciple, challenge yourself to become one of the best!',
    tooltip: '',
  },
  TX00182: {
    content: '— evaluate yourself and identify your initial level.',
    tooltip: '',
  },
  TX00183: {
    content: '— define your goal and be the driver of your journey.',
    tooltip: '',
  },
  TX00184: {
    content: '— set up a plan! Consistency and persistency lead to success. ',
    tooltip: '',
  },
  TX00185: {
    content: '— uncover your motivation! Be focused and engaged in your plan.',
    tooltip: '',
  },
  TX00186: {
    content: '— based on your activity CRE wil be allocated to your wallet.',
    tooltip: '',
  },
  TX00187: {
    content:
      '— every month, on the 1st, we deliver pay checks based on your previous month’s activity.',
    tooltip: '',
  },
  TX00188: {
    content: '— more than 1000 CRE can be withdrawn at any time.',
    tooltip: '',
  },
  TX00189: {
    content: '— invest in yourself by purchasing CRE inside your wallet.',
    tooltip: '',
  },
  TX00190: {
    content: '— decide when and how you want to use your CRE.',
    tooltip: '',
  },
  TX00191: {
    content:
      '— go the extra mile! Invest into library materials, tests or certifications.',
    tooltip: '',
  },
  TX00192: {
    content: 'available categories',
    tooltip: '',
  },
  TX00193: {
    content: 'activities',
    tooltip: '',
  },
  TX00194: {
    content: 'our values',
    tooltip: '',
  },
  TX00195: {
    content: 'learning is a never-ending process!',
    tooltip: '',
  },
  TX00196: {
    content:
      'we reward your mentoring practice; fees will be decreasing for those who are the most active! ',
    tooltip: '',
  },
  TX00197: {
    content: 'the Pilot Session is chargeable by the platform.',
    tooltip: '',
  },
  TX00198: {
    content:
      'packages are available for you to activate in order for disciples to get discounts.',
    tooltip: '',
  },
  TX00199: {
    content:
      'in order to compensate both quality and quantity in the same manner, the first threshold reached: credits earned, or activity hours completed on the platform will release the fee level you deserve. Great job!',
    tooltip: '',
  },
  TX00200: {
    content: 'competition is one of the primary motivation!',
    tooltip: '',
  },
  TX00201: {
    content:
      'we are competitive even from before we are born! This is one of the 3 primary needs of every human being.',
    tooltip: '',
  },
  TX00202: {
    content:
      'the more challenges we have, the better we become in the activity we do and it keeps us more passionate about it.',
    tooltip: '',
  },
  TX00203: {
    content: 'let’s enjoy competition: with yourself and with the others. ',
    tooltip: '',
  },
  TX00204: {
    content: 'collect diamonds by teaching and get amazing benefits.',
    tooltip: '',
  },
  TX00205: {
    content: 'high prestige is the result of commitment!',
    tooltip: '',
  },
  TX00206: {
    content:
      'we know you commit to become the best mentor. For you to be selected the one, its important you are visible from our community of mentors based on filters, but more important recommended by reviews and activity.',
    tooltip: '',
  },
  TX00207: {
    content:
      'mentors which have the highest rates, the most activities and best results of their work, will be the ones more visible in the list, and you will be recognised by the level of experience.',
    tooltip: '',
  },
  TX00208: {
    content:
      'nevertheless, the new mentors will also be visible, anyone can have their own chance.',
    tooltip: '',
  },
  TX00209: {
    content: 'become a mentor!',
    tooltip: '',
  },
  TX00210: {
    content: 'become a disciple!',
    tooltip: '',
  },
  TX00211: {
    content:
      'didn’t we all had at least one experience with a teacher that didn’t match our expectations and we literally lost our interest on that specific area?',
    tooltip: '',
  },
  TX00212: {
    content:
      'now its your time! If you find a good opportunity to teach others, to use your knowledge and experience to pass it on to other people.',
    tooltip: '',
  },
  TX00213: {
    content:
      'new or old generation, become a mentor and challenge yourself to be the best version of what you have expected from your teachers. ',
    tooltip: '',
  },
  TX00214: {
    content:
      'transforming skills into wisdom using the right methodology and sending the good energy, this can define an ideal mentoring process.',
    tooltip: '',
  },
  TX00215: {
    content:
      'we reward your investment; discounts will be available for those who are the most active!',
    tooltip: '',
  },
  TX00216: {
    content:
      'choose the mentor you think you vibe with the most. The Pilot Session is fully refundable in case you decide to try another mentor.',
    tooltip: '',
  },
  TX00217: {
    content:
      'packages with discounts may be available for you to purchase if your mentors have activated them.',
    tooltip: '',
  },
  TX00218: {
    content:
      'in order to compensate both quality and quantity in the same manner, the first threshold reached: credits earned, or activity hours completed on the platform will release the discount level you deserve. Great job!',
    tooltip: '',
  },
  TX00219: {
    content:
      'we are competitive even from before we are born! This is one of the 3 primary needs of every human being.',
    tooltip: '',
  },
  TX00220: {
    content:
      'the more challenges we have, the better we become in the learning process and it keeps us more passionate about it.',
    tooltip: '',
  },
  TX00221: {
    content: 'let’s enjoy competition: with yourself and with the others. ',
    tooltip: '',
  },
  TX00222: {
    content: 'collect diamonds by learning and get amazing benefits.',
    tooltip: '',
  },
  TX00223: {
    content:
      'we know you expect to find the best mentors. For you to select the one from our community of mentors, based on your filters, is more important to be recommended by reviews and activity.',
    tooltip: '',
  },
  TX00224: {
    content:
      'mentors which have the highest rates, the most activities and best results of their work, will be the ones more visible in the list, and you will recognise them by the level of experience.',
    tooltip: '',
  },
  TX00225: {
    content:
      'nevertheless, the new mentors will also be visible, anyone can have their own chance.',
    tooltip: '',
  },
  TX00226: {
    content: 'the more you learn, learning becomes fast and easy.',
    tooltip: '',
  },
  TX00227: {
    content:
      'we all are disciples, learning everyday new things, having different interests and desire to explore each of them.',
    tooltip: '',
  },
  TX00228: {
    content:
      'new or old generation, become a disciple and challenge yourself to be the best version of what you envisioned of yourself. ',
    tooltip: '',
  },
  TX00229: {
    content: 'enjoy learning and be confident. The rest is in our hands! ',
    tooltip: '',
  },
  TX00230: {
    content: 'menu',
    tooltip: '',
  },
  TX00231: {
    content: 'account',
    tooltip: '',
  },
  TX00232: {
    content: 'profile',
    tooltip: '',
  },
  TX00233: {
    content: 'forum',
    tooltip: '',
  },
  TX00234: {
    content: 'support',
    tooltip: '',
  },
  TX00235: {
    content: 'dismiss',
    tooltip: '',
  },
  TX00236: {
    content: 'info',
    tooltip: '',
  },
  TX00237: {
    content: 'wallet',
    tooltip: '',
  },
  TX00238: {
    content: 'share',
    tooltip: '',
  },
  TX00239: {
    content: 'news',
    tooltip: 'be up to date',
  },
  TX00240: {
    content: 'first Name',
    tooltip: '',
  },
  TX00241: {
    content: 'last Name',
    tooltip: '',
  },
  TX00242: {
    content: 'birthday',
    tooltip: '',
  },
  TX00243: {
    content: 'gender',
    tooltip: '',
  },
  TX00244: {
    content: 'email',
    tooltip: '',
  },
  TX00245: {
    content: 'password',
    tooltip: '',
  },
  TX00246: {
    content: 'language (platform)',
    tooltip: '',
  },
  TX00247: {
    content: 'residence',
    tooltip: '',
  },
  TX00248: {
    content: 'timezone',
    tooltip: '',
  },
  TX00249: {
    content: 'from',
    tooltip: '',
  },
  TX00250: {
    content: 'edit',
    tooltip: '',
  },
  TX00251: {
    content: 'cancel',
    tooltip: '',
  },
  TX00252: {
    content: 'save',
    tooltip: '',
  },
  TX00253: {
    content: 'information marked as ',
    tooltip: '',
  },
  TX00254: {
    content: 'will be public on the platform!',
    tooltip: '',
  },
  TX00255: {
    content: 'get free CRE!',
    tooltip: '',
  },
  TX00256: {
    content: 'have you shared with your friends about Webinclass?',
    tooltip: '',
  },
  TX00257: {
    content: 'grow together by online learning.',
    tooltip: '',
  },
  TX00258: {
    content: 'cards',
    tooltip: '',
  },
  TX00259: {
    content: 'history',
    tooltip: '',
  },
  TX00260: {
    content: 'we care about our most dedicated members',
    tooltip: '',
  },
  TX00723: {
    content: 'englisch',
    tooltip: '',
  },
  TX00729: {
    content: 'rumänisch',
    tooltip: '',
  },
  TX00725: {
    content: 'deutsche',
    tooltip: '',
  },
  TX00900: {
    content: 'category',
    tooltip: '',
  },
  TX00451: {
    content: 'recurrent',
    tooltip: '',
  },
};

export default german;
