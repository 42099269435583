import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

const Detail = ({ label, column, children, className }) => {
  return (
    <label
      htmlFor={label}
      className={`detail fs-xs clr-grey ${column && 'column'} ${className}`}>
      {label}:
      {typeof children === 'string' ? (
        <Text id={label} text={children} className='clr-black fs-xs' />
      ) : (
        children
      )}
    </label>
  );
};

Detail.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  column: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

Detail.defaultProps = {
  className: '',
  column: false,
};

export default Detail;
