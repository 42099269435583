import React, { useContext } from 'react';
import { useParams } from 'react-router';
import Dashboard from '../../components/Dashboard/Dashboard';
import { LangContext } from '../../contexts/language/LangContext';
import AccountInfo from '../../modules/Account/AccountInfo';

const Account = () => {
	const {
		lang: {
			// TX00234: { content: supportLang },
			TX00236: { content: infoLang },
			// TX00238: { content: shareLang },
		},
	} = useContext(LangContext);

	const { slug } = useParams();

	return (
		<Dashboard className='account-page'>
			{!slug && <AccountInfo />}
			{slug === infoLang && <AccountInfo />}
		</Dashboard>
	);
};

export default Account;
