import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import firstUp from '../../controllers/firstUp';

const Label = ({ label, id }) => (
  <label className='fs-xs clr-dark-grey' htmlFor={id}>
    {firstUp(label)}
  </label>
);

const Input = ({
  value,
  name,
  content,
  id,
  type,
  className,
  onChange,
  checked,
  onClick,
  column,
}) => {
  switch (type) {
    // BUTTON
    case 'button':
      return (
        <input
          id={id}
          name={name}
          type='button'
          className={`fs-xs input-button ${className}`}
          value={value}
          onClick={(e) => onClick(e)}
        />
      );
    // TEXT INPUT
    case 'text':
    case 'email':
    case 'password':
    case 'number':
      return (
        <span className={`input-container ${column && 'column'}`}>
          {content.label && <Label id={id} label={content.label} />}
          <span className='input'>
            <input
              id={id}
              name={name}
              type={type}
              className={`fs-xs ${className}`}
              value={value}
              onChange={(e) => onChange(e)}
            />
          </span>
        </span>
      );

    // SELECT
    case 'select':
      return (
        <span>
          {content.label && <Label id={id} label={content.label} />}
          <span className='input input-container'>
            <select
              name={name}
              id={id}
              className={`fs-xs ${className}`}
              onChange={(e) => onChange(e)}
              value={value}>
              <option
                key={'key_' + name + Math.floor(Math.random(100))}
                value={0}
                disabled>
                -
              </option>
              {id === 'rating'
                ? content.list.map((item, index) => (
                    <option key={'key_' + name + index} value={item}>
                      {item}
                    </option>
                  ))
                : content.list.map((item, index) => (
                    <option key={'key_' + name + index} value={item}>
                      {item}
                    </option>
                  ))}
            </select>
          </span>
        </span>
      );

    // CHECKBOX
    case 'checkbox':
    case 'radio':
      return (
        <Fragment>
          <input
            type={type}
            id={id}
            className={className}
            name={name}
            value={value}
            checked={checked}
            onChange={(e) => onChange(e)}
            onClick={(e) => onClick(e)}
          />
          <label htmlFor={id}>{value}</label>
        </Fragment>
      );
    default:
      return <p>No type passed into input...</p>;
  }
};

Input.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string.isRequired,
    list: PropTypes.array,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  column: PropTypes.bool,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

Input.defaultProps = {
  column: false,
  className: '',
  onChange: () => {},
  onClick: () => {},
};

Label.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Input;
