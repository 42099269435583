// CL100002

import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';
import firstUp from '../../controllers/firstUp';
import Text from './Text';
import ArrowIcon from '../Assets/ArrowIcon';

const Selector = ({ content, icon, white }) => {
  const [show, setShow] = useState(false);
  const selectorRef = useRef(null);

  const { changeLang } = useContext(LangContext);

  const handleClick = () => {
    selectorRef.current.classList.toggle('open-selector');
    setShow(!show);
  };

  let k = 0;

  return (
    <div ref={selectorRef} className='selector ' onClick={() => handleClick()}>
      {icon && <ArrowIcon white={white} key={k++} />}
      {content.text && <p>{content.text}</p>}
      {content.list.length > 0 && (
        <ul className='selector-content'>
          {content.list.map((item) => (
            <Text
              key={item.text}
              className='fs-xs'
              li
              icon={item.icon}
              text={firstUp(item.text)}
              onClick={() => changeLang(item.text)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

Selector.propTypes = {
  content: PropTypes.object.isRequired,
  icon: PropTypes.bool,
  white: PropTypes.bool,
};

Selector.defaultProps = {
  icon: false,
  white: false,
};

export default Selector;
