import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ShowcaseTexts from '../Generic/ShowcaseTexts';
// import { LangContext } from '../../contexts/language/LangContext';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';
import InkBackdrop from '../Generic/InkBackdrop';

const ActivityHero = ({ activity }) => {
  const {
    lang: {
      TX00005: sessionsLang,
      TX00009: text1sessions,
      TX00010: text2sessions,

      TX00006: classesLang,
      TX00011: text1classes,
      TX00012: text2classes,

      TX00007: webinarsLang,
      TX00013: text1webinars,
      TX00014: text2webinars,

      TX00008: liveLang,
      TX00015: text1live,
      TX00016: text2live,
    },
  } = useContext(LangContext);

  const {
    sessionIllustration,
    classIllustration,
    webinarIllustration,
    liveIllustration,
  } = useContext(AssetContext);

  const backdrops = {
    session: sessionIllustration,
    class: classIllustration,
    webinar: webinarIllustration,
    live: liveIllustration,
  };

  const content = {
    session: {
      header: sessionsLang.content,
      subheader: text1sessions.content,
      paragraph: text2sessions.content,
    },
    class: {
      header: classesLang.content,
      subheader: text1classes.content,
      paragraph: text2classes.content,
    },
    webinar: {
      header: webinarsLang.content,
      subheader: text1webinars.content,
      paragraph: text2webinars.content,
    },
    live: {
      header: liveLang.content,
      subheader: text1live.content,
      paragraph: text2live.content,
    },
  };

  return (
    <section className={`activity-hero activity-hero-${activity}`}>
      <InkBackdrop className={`backdrop-${activity}`} />
      <div className='activity-hero-content container'>
        <ShowcaseTexts white content={content[`${activity}`]} />
        <img
          src={backdrops[`${activity}`]}
          alt={`webinclass-${activity}-backdrop`}
        />
      </div>
    </section>
  );
};

ActivityHero.propTypes = {
  activity: PropTypes.string.isRequired,
};

export default ActivityHero;
