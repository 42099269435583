import React from 'react';
import PropTypes from 'prop-types';

const Text = ({
	li,
	icon,
	text,
	ss,
	className,
	id,
	onClick,
	children,
	textRef,
}) => {
	// LIST ITEM
	if (li) {
		return (
			<li
				id={id}
				ref={textRef}
				className={`text ${className}`}
				onClick={(e) => onClick(e)}
			>
				{icon && <img src={icon} alt={text} />}
				{text && text}
				{children && children}
			</li>
		);
	}

	// SUPERSCRIPT
	if (ss) {
		return (
			<h5
				id={id}
				ref={textRef}
				className={`text-ss fs-l ${className}`}
				onClick={(e) => onClick(e)}
			>
				<sup className='fs-xs clr-light-grey'>{ss}</sup>
				{text && text}
				{children && children}
			</h5>
		);
	}

	// PARAGRAPH
	return (
		<p
			id={id}
			ref={textRef}
			className={`text ${className}`}
			onClick={(e) => onClick(e)}
		>
			{icon && <img src={icon} alt={text} />}
			{text && text}
			{children && children}
		</p>
	);
};

Text.propTypes = {
	li: PropTypes.bool,
	superscript: PropTypes.bool,

	ss: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	icon: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.element,
		PropTypes.array,
	]),
	className: PropTypes.string,
	id: PropTypes.string,
	onClick: PropTypes.func,
	textRef: PropTypes.object,
};

Text.defaultProps = {
	className: '',
	id: '',
	onClick: () => {},
	li: false,
	ss: undefined,
};

export default Text;
