import React from 'react';
import PropTypes from 'prop-types';

const TitleBand = ({ className, text, mentor, disciple }) => {
  return (
    <div className={`title-band ${className}`}>
      <h4
        className={`clr-white fs-m ${mentor && 'title-band-mentor'} ${
          disciple && 'title-band-disciple'
        }`}>
        {text}
      </h4>
    </div>
  );
};

TitleBand.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  mentor: PropTypes.bool,
  disciple: PropTypes.bool,
};

TitleBand.defaultProps = {
  className: '',
  mentor: false,
  disciple: false,
};

export default TitleBand;
