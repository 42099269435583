import React from 'react';
import PropTypes from 'prop-types';

const ArrowIcon = ({ white, className, fill }) => {
  if (fill)
    return (
      <svg
        id='svg-arrow-icon-fill'
        className={`${className} ${white && 'arrow-white'}`}
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 494.148 494.148'
        xmlSpace='preserve'>
        <path
          d='M405.284,201.188L130.804,13.28C118.128,4.596,105.356,0,94.74,0C74.216,0,61.52,16.472,61.52,44.044v406.124
          c0,27.54,12.68,43.98,33.156,43.98c10.632,0,23.2-4.6,35.904-13.308l274.608-187.904c17.66-12.104,27.44-28.392,27.44-45.884
          C432.632,229.572,422.964,213.288,405.284,201.188z'
        />
      </svg>
    );

  return (
    <svg
      id='svg-arrow-icon'
      className={`${className} ${white && 'arrow-white'}`}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 12.7 7.2'
      xmlSpace='preserve'>
      <path
        d='M6.3,5.1L1.5,0.3C1.2-0.1,0.7-0.1,0.3,0.2c0,0,0,0,0,0l0,0c-0.1,0-0.1,0.1-0.1,0.2C-0.1,0.8,0,1.3,0.3,1.6L5.7,7
	c0.3,0.3,0.9,0.3,1.2,0H7l5.4-5.4c0.3-0.3,0.4-0.9,0.1-1.2c-0.3-0.4-0.8-0.5-1.2-0.2c0,0,0,0,0,0l0,0l-0.2,0.1L6.3,5.1z'
      />
    </svg>
  );
};

ArrowIcon.propTypes = {
  className: PropTypes.string,
  white: PropTypes.bool,
  fill: PropTypes.bool,
};

ArrowIcon.defaultProps = {
  className: '',
  white: false,
  fill: false,
};

export default ArrowIcon;
