import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';
import Text from '../Generic/Text';
import Logo from '../Assets/Logo';
import NavActiveLink from './NavActiveLink';
import { AssetContext } from '../../contexts/asset/AssetContext';
import firstUp from '../../controllers/firstUp';
import Hamburger from './Hamburger';
import PenLine from '../Assets/PenLine';
import { LocContext } from '../../contexts/location/LocContext';
import To from './To';

const NavigationBarSecondary = ({ className, white }) => {
	const {
		lang: {
			TX00046: exploreLang,
			TX00028: libraryLang,
			TX00239: newsLang,
			TX00237: walletLang,
			TX00231: accountLang,
		},
	} = useContext(LangContext);

	const { walletIcon, messagesIcon, eventsIcon, agendaIcon } =
		useContext(AssetContext);

	const { setLocation } = useContext(LocContext);

	const navLinks = [exploreLang.content, libraryLang.content, newsLang.content];

	const navActiveLinks = [
		{
			icon: walletIcon,
			tooltip: 'wallet',
			link: `${accountLang.content}/${walletLang.content}`,
		},
		{ icon: messagesIcon, tooltip: 'messages', link: 'messages' },
		{ icon: eventsIcon, tooltip: 'events', link: 'events' },
		{ icon: agendaIcon, tooltip: 'agenda', link: 'agenda' },
	];

	// const handleMenu = (e) => {};

	return (
		<nav className={`nav-secondary ${className} ${white && 'clr-white'}`}>
			<PenLine white={white} />
			<div className='nav-secondary-content container'>
				<ul className='nav-list'>
					{navLinks.map((link, index) => (
						<Text
							li
							key={'link_' + index}
							className='nav-list-item'
							onClick={() => setLocation(link)}
						>
							<To to={`/${link}`}>{firstUp(link)}</To>
						</Text>
					))}
				</ul>
				<Logo noType />
				<ul className='nav-list'>
					{navActiveLinks.map((link, index) => (
						<NavActiveLink
							key={'navLink_' + index}
							content={link}
							className='nav-list-item'
						/>
					))}
					<Hamburger white={white} />
				</ul>
			</div>
			<PenLine white={white} right />
		</nav>
	);
};

NavigationBarSecondary.propTypes = {
	className: PropTypes.string,
	white: PropTypes.bool,
};

NavigationBarSecondary.defaultProps = {
	className: '',
	white: false,
};

export default NavigationBarSecondary;
