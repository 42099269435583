import React, { useContext } from 'react';
import Dashboard from '../../components/Dashboard/Dashboard';
import { useParams } from 'react-router-dom';
import WalletOverview from '../../modules/Account/Wallet/WalletOverview';
import WalletCre from '../../modules/Account/Wallet/WalletCre';
import WalletCreditcards from '../../modules/Account/Wallet/WalletCreditcards';
import { LangContext } from '../../contexts/language/LangContext';

const Wallet = () => {
	const { walletPage } = useParams();

	const {
		lang: {
			TX00055: { content: creLang },
			TX00258: { content: cardsLang },
		},
	} = useContext(LangContext);

	return (
		<Dashboard className='wallet-page'>
			{!walletPage && <WalletOverview />}
			{walletPage === creLang && <WalletCre />}
			{walletPage === cardsLang && <WalletCreditcards />}
		</Dashboard>
	);
};

export default Wallet;
