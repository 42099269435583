import React from 'react';
import PropTypes from 'prop-types';
import UserPhoto from './UserPhoto';
// import { LangContext } from '../../contexts/language/LangContext';
import RatingIndicator from '../Indicators/RatingIndicator';

const ReviewCard = ({ content }) => {
	const {
		user: { name, role, created_at, photo },
		review: { text, rating },
	} = content;

	// const {} = useContext(LangContext);

	return (
		<div className='review-card'>
			<div className='user-info'>
				<UserPhoto photo={photo} />
				<h5 className='clr-white fs-s'>{name}</h5>
				<p className='clr-white fs-xs'>{role},</p>
				<p className='clr-white fs-xs'>since {created_at}</p>
			</div>
			<div className='review-card-body'>
				<h3 className='fs-m'>{text}</h3>
				<RatingIndicator rating={rating} />
			</div>
		</div>
	);
};

ReviewCard.propTypes = {
	content: PropTypes.object.isRequired,
};

export default ReviewCard;
