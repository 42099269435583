import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';

const AvIndicator = ({ content }) => {
	const {
		lang: {
			TX00277: { content: percentageOfLastLang },
		},
	} = useContext(LangContext);

	const { month, av, total, percentage, from } = content;

	return (
		<div className='av-indicator'>
			<div>
				<h5>{from}</h5>
				<p className='fs-xs clr-light-grey'>{month}</p>
				<p className='fs-xxxxl fw-m'>{av}</p>
				<p className='fs-xs clr-light-grey'>{total}</p>
			</div>
			<div className='percentage-bar'>
				<span
					className='percentage-bar-percentage fs-xs'
					style={{ height: `${percentage < 23 ? '20' : percentage}%` }}
				>
					<p className='clr-white'>{percentage}%</p>
				</span>

				<p className='percentage-bar-text fs-xxs clr-light-grey'>
					{percentageOfLastLang}
				</p>
			</div>
		</div>
	);
};

AvIndicator.propTypes = {
	content: PropTypes.shape({
		month: PropTypes.string.isRequired,
		av: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired,
		percentage: PropTypes.number.isRequired,
		from: PropTypes.string.isRequired,
	}).isRequired,
};

export default AvIndicator;
