import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../Generic/Button';
import { LangContext } from '../../contexts/language/LangContext';
import Text from '../Generic/Text';
import firstUp from '../../controllers/firstUp';

const ContinueWithBtns = ({ google, facebook, apple, full }) => {
  const {
    lang: {
      TX00035: continueWithLang,
      TX00036: facebookLang,
      TX00037: googleLang,
      TX00038: appleLang,
    },
  } = useContext(LangContext);

  return (
    <div className='continue-with-btns'>
      <h5 className='fs-s'>{firstUp(continueWithLang.content)}</h5>
      <ul>
        {google && (
          <Text li>
            <Button full={full} secondary text={googleLang.content} />
          </Text>
        )}
        {facebook && (
          <Text li>
            <Button full={full} secondary text={facebookLang.content} />
          </Text>
        )}
        {apple && (
          <Text li>
            <Button full={full} secondary text={appleLang.content} />
          </Text>
        )}
      </ul>
    </div>
  );
};

ContinueWithBtns.propTypes = {
  google: PropTypes.bool,
  facebook: PropTypes.bool,
  apple: PropTypes.bool,
  full: PropTypes.bool,
};

ContinueWithBtns.defaultProps = {
  google: false,
  facebook: false,
  apple: false,
  full: false,
};

export default ContinueWithBtns;
