import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Text from '../../components/Generic/Text';
import { AssetContext } from '../../contexts/asset/AssetContext';
import TitleBand from '../../components/Generic/TitleBand';
import firstUp from '../../controllers/firstUp';

const ValuesSection = ({
  className = '',
  content,
  right,
  mentor,
  disciple,
}) => {
  const { title, list, img } = content;

  const { bullet } = useContext(AssetContext);

  return (
    <>
      <TitleBand text={title} mentor={mentor} disciple={disciple} />
      <div
        className={`values-section ${className} ${
          right ? 'values-section-right' : ''
        }`}>
        <ul>
          {list.map((entry, index) => (
            <Text
              key={'valuesKey_' + index}
              li
              text={firstUp(entry)}
              icon={bullet}
              className='fs-xs'
            />
          ))}
        </ul>
        <img src={img} alt='values' />
      </div>
    </>
  );
};

ValuesSection.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    img: PropTypes.string.isRequired,
  }).isRequired,
  right: PropTypes.bool,
  mentor: PropTypes.bool,
  disciple: PropTypes.bool,
};

ValuesSection.defaultProps = {
  className: '',
  right: false,
  mentor: false,
  disciple: false,
};

export default ValuesSection;
