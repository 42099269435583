import firstUp from '../firstUp';

const handleCheckbox = (
  e,
  list,
  allLang,
  checkedCheckbox,
  setCheckedCheckbox
) => {
  const filter = e.target.className;
  const filterField = e.target.name;
  const value = e.target.value;
  const filterFieldValue = checkedCheckbox[filter][filterField];
  const isSelected = filterFieldValue === value;

  const setAll = (from) => {
    let all = {};
    const filterField = checkedCheckbox[`${from}`][`${from}All`];

    // eslint-disable-next-line
    filterField.localeCompare(allLang)
      ? list.map((value) => {
          const filterField = `${from}${firstUp(value)}`;

          all = {
            ...all,
            [filterField]: value,
          };
        })
      : // eslint-disable-next-line
        list.map((value) => {
          const filterField = `${from}${firstUp(value)}`;

          all = {
            ...all,
            [filterField]: '',
          };
        });

    setCheckedCheckbox({ ...checkedCheckbox, rank: all });
  };

  filterField.includes('All')
    ? setAll(filter)
    : filter === 'rank'
    ? setCheckedCheckbox({
        ...checkedCheckbox,
        rank: {
          ...checkedCheckbox[filter],
          rankAll: '',
          [filterField]: isSelected ? '' : value,
        },
      })
    : setCheckedCheckbox({
        ...checkedCheckbox,
        [filter]: {
          ...checkedCheckbox[filter],
          [filterField]: isSelected ? '' : value,
        },
      });
};

export default handleCheckbox;
