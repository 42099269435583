import React from 'react';
import PropTypes from 'prop-types';

import UserPhoto from '../../components/Generic/UserPhoto';
import Owl from '../../components/Assets/Owl';
import ShowcaseTexts from '../../components/Generic/ShowcaseTexts';

const ActivitySteps = ({ className }) => {
  return (
    <section className={`activity-steps ${className}`}>
      <Owl className='owl-left' />
      <div className='step-1'>
        <div className='step-1-content'>
          <UserPhoto />
          <ShowcaseTexts
            small
            white
            content={{
              header: 'test',
              subheader: 'test text test text test text test text',
              paragraph:
                'test text test text test text test text test text test text test text test text',
            }}
          />
        </div>
      </div>
      <div className='step-2'>
        <div className='step-2-content'>
          <UserPhoto />
          <ShowcaseTexts
            small
            white
            content={{
              header: 'test',
              subheader: 'test text test text test text test text',
              paragraph:
                'test text test text test text test text test text test text test text test text',
            }}
          />
        </div>
      </div>
      <div className='step-3'>
        <div className='step-3-content'>
          <UserPhoto />
          <ShowcaseTexts
            small
            white
            content={{
              header: 'test',
              subheader: 'test text test text test text test text',
              paragraph:
                'test text test text test text test text test text test text test text test text',
            }}
          />
        </div>
      </div>
      <div className='step-4'>
        <div className='step-4-content'>
          <UserPhoto />
          <ShowcaseTexts
            small
            white
            content={{
              header: 'test',
              subheader: 'test text test text test text test text',
              paragraph:
                'test text test text test text test text test text test text test text test text',
            }}
          />
        </div>
      </div>
      <Owl className='owl-right' />
    </section>
  );
};

ActivitySteps.propTypes = {
  className: PropTypes.string,
};

ActivitySteps.defaultProps = {
  className: '',
};

export default ActivitySteps;
