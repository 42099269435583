import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Star = ({ half, empty, className }) => {
  const starRef = useRef(null);

  useEffect(() => {
    half && starRef.current.classList.add('half-star');
    empty && starRef.current.classList.add('empty-star');

    // eslint-disable-next-line
  }, []);

  return (
    <svg
      id='svg-star'
      className={className}
      ref={starRef}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 84.21 80.1'>
      <defs>
        <linearGradient
          id='linear-gradient-star'
          x1='13.77'
          y1='92.33'
          x2='56.16'
          y2='12.91'
          gradientTransform='matrix(1, 0, 0, -1, 0, 79.89)'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0.32' stopColor='#e5a31e' />
          <stop offset='0.37' stopColor='#e8ac1b' />
          <stop offset='0.46' stopColor='#eec413' />
          <stop offset='0.56' stopColor='#f9eb06' />
          <stop offset='0.61' stopColor='#ff0' />
          <stop offset='0.98' stopColor='#fb923b' />
        </linearGradient>
        <linearGradient
          id='linear-gradient-star-2'
          x1='0'
          y1='39.84'
          x2='84.21'
          y2='39.84'
          xlinkHref='#linear-gradient-star'
        />
      </defs>

      <g id='star'>
        <polygon
          id='star-fill'
          points='42.11 4.3 53.87 28.11 80.14 31.92 61.13 50.46 65.61 76.62 42.11 64.27 18.62 76.62 23.1 50.46 4.09 31.92 30.36 28.11 42.11 4.3'
          fill='url(#linear-gradient-star)'
        />
        <path
          id='star-border'
          d='M68.15,80.1l-26-13.68-26,13.68,5-29L0,30.59l29.1-4.22L42.11,0l13,26.37,29.1,4.22-21,20.53Zm-60-46.85,17,16.54-4,23.35,21-11,21,11-4-23.35L76,33.25l-23.4-3.4L42.11,8.6,31.63,29.85Z'
          fill='url(#linear-gradient-star-2)'
        />
        <path
          id='star-half'
          d='M73.91,33.23c.41,0,1.35.18,1.76.18l-22.59-3.3L42.08,9,31.8,30.11,8,33.48,23.09,47.84C34.36,39,52.93,33.23,73.91,33.23Z'
          fill='#fff'
          opacity='0.5'
          style={{ isolation: 'isolate' }}
        />
      </g>
    </svg>
  );
};

Star.propTypes = {
  half: PropTypes.bool,
  empty: PropTypes.bool,
  className: PropTypes.string,
};

Star.defaultProps = {
  half: false,
  empty: false,
  className: '',
};

export default Star;
