import React, { useContext } from 'react';
import { AssetContext } from '../../contexts/asset/AssetContext';
import ReviewCard from '../../components/Generic/ReviewCard';

const Reviews = () => {
  const { userFemale } = useContext(AssetContext);

  const content = {
    user: {
      name: 'Mary Smith',
      role: 'mentor',
      created_at: 'June 2021',
      photo: userFemale,
    },
    review: {
      text: 'I enjoy mentoring on this platform because it’s nice. It has a great design, and an amazing user experience. Great job Webinclass!',
      rating: 3.2,
    },
  };
  return (
    <section className='reviews'>
      <ReviewCard content={content} />
    </section>
  );
};

export default Reviews;
