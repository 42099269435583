import React, { useContext, useEffect } from 'react';
import Separator from '../../components/Generic/Separator';
import Activities from '../../modules/Activity/Activities';
import Categories from '../../modules/Category/Categories';
import Features from '../../modules/Features/Features';
import Hero from '../../modules/Landing/Hero';
import Reviews from '../../modules/Landing/Reviews';
import RolesPresentation from '../../modules/Landing/RolesPresentation';
import { LangContext } from '../../contexts/language/LangContext';

const Landing = () => {
  const {
    lang: {
      TX00004: separator_one,
      TX00021: separator_two,
      TX00034: separator_three,
    },
  } = useContext(LangContext);

  useEffect(() => {
    window.scroll({ top: 0 });
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      <Hero />
      <Separator text={separator_one.content} />
      <Activities />
      <RolesPresentation />
      <Separator text={separator_two.content} />
      <Categories />
      <Features />
      <Separator text={separator_three.content} />
      <Reviews />
    </main>
  );
};

export default Landing;
