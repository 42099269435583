import React, { useRef } from 'react';
import Box from '../../../components/Generic/Box';
// import Text from '../../../components/Generic/Text';
// import PropTypes from 'prop-types'
// import { LangContext } from '../../../contexts/language/LangContext';
// import firstUp from '../../../controllers/firstUp';
// import { AssetContext } from '../../../contexts/asset/AssetContext';
import Creditcards from '../../../components/Wallet/Creditcards';
import LegalLinks from '../../../components/Wallet/LegalLinks';
import BankAccountInfo from '../../../components/Wallet/BankAccountInfo';
import DigitalSignature from '../../../components/Wallet/DigitalSignature';
import AddCreditcard from '../../../components/Wallet/AddCreditcard';

const WalletCreditcards = () => {
	const addCardRef = useRef(null);

	// const {
	// 	lang: {
	// 		TX00272: { content: sharingLang },
	// 		TX00273: { content: bufferLang },
	// 		TX00274: { content: withdrawLang },
	// 	},
	// } = useContext(LangContext);

	const creditcards = [
		{
			bank: 'ING CENTRAL',
			number: 1234567891234567,
			expMonth: 12,
			expYear: 24,
			fullName: 'John Doe',
			address: 'aleea Baita 6',
			accountNo: '123124325432234',
			country: 'romania',
		},
		{
			bank: 'BRD',
			number: 1234567891234567,
			expMonth: 12,
			expYear: 24,
			fullName: 'John Doe',
		},
	];

	const setShowModal = (bool) =>
		bool === false
			? addCardRef.current.classList.add('hide')
			: addCardRef.current.classList.remove('hide');

	return (
		<section className='wallet-cards mw-70'>
			<Box className=''>
				<Creditcards setShowModal={setShowModal} />
			</Box>
			<Box>
				<BankAccountInfo creditcard={creditcards[0]} />
			</Box>
			<Box>
				<LegalLinks />
			</Box>
			<Box>
				<DigitalSignature />
			</Box>
			<Box refProp={addCardRef} className='hide'>
				<AddCreditcard setShowModal={setShowModal} />
			</Box>
		</section>
	);
};

// Wallet.propTypes = {};

export default WalletCreditcards;
