import React from 'react';
import PropTypes from 'prop-types';
// import { LangContext } from '../../contexts/language/LangContext';

const UserProfileActivity = () => {
  // const {
  // lang,
  // lang: {
  //   TX00491: startedOnLang,
  //   TX00510: initialLevelLang,
  //   TX00577: currentLevelLang,
  //   TX00417: levelLang,
  //   TX00719: aimingForLang,
  //   TX00077: certificationLang,
  //   TX00720: untilLang,
  //   TX00497: certifiedLang,
  //   TX00676: testLang,
  //   TX00507: planLang,
  //   TX00493: daysLang,
  //   TX00308: periodLang,
  //   TX00721: plannedHoursLang,
  //   TX00527: onlineLearningLang,
  //   TX00528: selfStudyingLang,
  //   TX00583: greatJobSoFarLang,
  //   TX00516: desiredLevelLang,
  //   TX00783: timeAllocatedLang,
  //   TX00535: weeksLang,
  //   TX00061: sessionsLang,
  //   TX00722: valuesLang,
  //   TX00552: idealMentorLang,
  // },
  // } = useContext(LangContext);

  return (
    <>
      <div className='user-profile-activity-info'></div>
    </>
  );
};

UserProfileActivity.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserProfileActivity;
