import React, { useContext, useState, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types'
import Box from '../../components/Generic/Box';
import UserPhoto from '../../components/Generic/UserPhoto';
import { AuthContext } from '../../contexts/auth/AuthContext';
import { LangContext } from '../../contexts/language/LangContext';
import Form from '../../components/Form/Form';
import firstUp from '../../controllers/firstUp';
import EditToggler from '../../components/Generic/EditToggler';

const AccountInfo = () => {
	const {
		user,
		user: {
			firstName,
			lastName,
			birthday,
			gender,
			email,
			language,
			residence,
			timezone,
			from,
		},
	} = useContext(AuthContext);

	const { editUser } = useContext(AuthContext);

	const {
		getAvLangsList,
		lang: {
			TX00240: { content: firstNameLang },
			TX00241: { content: lastNameLang },
			TX00242: { content: birthdayLang },
			TX00243: { content: genderLang },
			TX00244: { content: emailLang },
			TX00245: { content: passwordLang },
			TX00246: { content: languageLang },
			TX00247: { content: residenceLang },
			TX00248: { content: timezoneLang },
			TX00249: { content: fromLang },
		},
	} = useContext(LangContext);

	const fields = [
		{
			label: firstUp(firstNameLang),
			text: firstName,
			type: 'text',
			name: 'firstName',
		},
		{
			label: firstUp(lastNameLang),
			text: lastName,
			type: 'text',
			name: 'lastName',
		},
		{
			label: firstUp(birthdayLang),
			text: birthday,
			type: 'text',
			name: 'birthday',
		},
		{
			label: firstUp(genderLang),
			text: gender,
			type: 'select',
			list: ['male', 'female', 'other'],
			name: 'gender',
		},
		{ label: firstUp(emailLang), text: email, type: 'email', name: 'email' },
		{
			label: firstUp(passwordLang),
			text: 'password',
			type: 'password',
			name: 'password',
		},
		{
			label: firstUp(languageLang),
			text: language,
			type: 'select',
			list: getAvLangsList(),
			name: 'language',
		},
		{
			label: firstUp(residenceLang),
			text: residence,
			type: 'text',
			name: 'residence',
		},
		{
			label: firstUp(timezoneLang),
			text: timezone,
			type: 'select',
			list: ['UTC +0', 'UTC +1', 'UTC +2', 'UTC +3'],
			name: 'timezone',
		},
		{ label: firstUp(fromLang), text: from, type: 'text', name: 'from' },
	];

	const [edit, setEdit] = useState(false);

	const [initialUser, setInitialUser] = useState(useRef(null));

	const handleSubmit = (e) => {
		e.preventDefault();
		// send updates to DB
		setInitialUser({ current: user });
		setEdit(false);
	};

	const handleChange = (e) => editUser({ [e.target.name]: e.target.value });

	useEffect(() => {
		setInitialUser({ current: user });
		// eslint-disable-next-line
	}, []);

	return (
		<section className='account-info grid-center mw-70'>
			<Box>
				<div className='top'>
					<UserPhoto border edit />
					<EditToggler
						className='top-button'
						state={{ edit, setEdit }}
						onSubmit={handleSubmit}
						onCancel={() => editUser(initialUser.current)}
					/>
				</div>
				<Form
					withSubmit
					fields={fields}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					edit={edit}
				/>
			</Box>
		</section>
	);
};

// AccountInfo.propTypes = {};

export default AccountInfo;
