import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const DiscipleIcon = ({ white, noFill, className }) => {
  const discipleRef = useRef(null);

  useEffect(() => {
    white && discipleRef.current.classList.add('disciple-icon-white');
    noFill && discipleRef.current.classList.add('disciple-icon-no-fill');

    // eslint-disable-next-line
  }, []);

  return (
    <svg
      id='svg-disciple'
      className={className}
      ref={discipleRef}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 36.06 41.81'>
      <g id='disciple-icon'>
        <path d='M25.33,10.1a6.86,6.86,0,0,0-.68-1.45c-4.15.21-4.76,1.85-6.55,1.85H18c-1.64,0-2.35-1.63-6.45-1.86a7.35,7.35,0,0,0-1.09,4.77,1.75,1.75,0,0,0,1.37,1.74c.82.24,1.63.56,2.46.77a18.05,18.05,0,0,0,4.39.52,5.35,5.35,0,0,0,1.06-.07,11.15,11.15,0,0,0,1.89-.4c.59-.18,1.2-.34,1.81-.54l1.08-.34c1-.32,1.14-1.53,1.12-2.44v-.29A11.55,11.55,0,0,0,25.33,10.1Z' />
        <path
          id='disciple-book-left'
          d='M17.23,41.78a30.2,30.2,0,0,0-14.8-3.91c-.87,0-1.13-.44-1.12-1.25,0-1.92.05-3.85,0-5.77,0-.85.34-.9,1-1,2.35-.35,3.35-1.68,3.34-4.35s-1-3.94-3.37-4.27c-.76-.1-1-.27-1-1,.22-4.73-.78-4.18,4.09-4a42.71,42.71,0,0,1,10.28,1.8c1.09.32,1.64.75,1.62,2.08C17.19,27.28,17.23,34.42,17.23,41.78Z'
        />
        <path
          id='disciple-book-right'
          d='M18.68,41.81V19.68a1.23,1.23,0,0,1,1-1.39,45.55,45.55,0,0,1,13.66-2.14c1,0,1.31.39,1.26,1.29a11.69,11.69,0,0,0,0,1.45c0,1.29.24,2.39-1.78,2.58s-2.69,2-2.56,4.72c.1,2,1.19,3.46,3.08,3.61,1.19.1,1.32.54,1.28,1.52-.07,1.75,0,3.49,0,5.24,0,.9-.3,1.3-1.25,1.32A29.7,29.7,0,0,0,18.68,41.81Z'
        />
        <path
          id='disciple-hand-right'
          d='M36.05,25.55c0,1.36.3,2.93-1.89,2.91-1.75,0-2.47-.87-2.46-3s.74-2.81,2.44-2.82S36.05,23.11,36.05,25.55Z'
        />
        <path
          id='disciple-hand-left'
          d='M0,25.55c0,1.36-.3,2.95,1.88,2.95,1.76,0,2.47-.87,2.46-3s-.73-2.81-2.43-2.82S0,23.11,0,25.55Z'
        />
        <path
          id='disciple-toca'
          d='M30.06,4.66C29.65,4.5,18.41.08,17.37,0,16.37.15,6.43,4,6.09,4.14s-.77.38-.77.7.5.68.79.8c.13.05,1.06.28,2.06.51-.08,1.36-.06,2.5-.05,3.87,0,.72.16,1.45-.65,2a1,1,0,0,0-.15,1.31,7.25,7.25,0,0,0,1.39,2.1A10.63,10.63,0,0,0,10,13.33c.2-.49.24-1.06-.16-1.32a2,2,0,0,1-1-1.89c0-1.21-.09-2.6,0-3.81l2.31.54-.48,1.79c5,0,5.66,1.89,7.42,1.89h.06c1.91,0,2.45-1.89,7.42-1.89l-.52-2c1.62-.14,4.81-.43,5-.52s.79-.47.79-.8S30.41,4.84,30.06,4.66Z'
        />
      </g>
    </svg>
  );
};

DiscipleIcon.propTypes = {
  white: PropTypes.bool,
  noFill: PropTypes.bool,
  className: PropTypes.string,
};

DiscipleIcon.defaultProps = {
  className: '',
};

export default DiscipleIcon;
