import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const MentorIcon = ({ white, noFill, className }) => {
  const mentorRef = useRef(null);

  useEffect(() => {
    white && mentorRef.current.classList.add('mentor-icon-white');
    noFill && mentorRef.current.classList.add('mentor-icon-no-fill');

    // eslint-disable-next-line
  }, []);

  return (
    <svg
      id='svg-mentor'
      className={className}
      ref={mentorRef}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 254.49 334.07'>
      <path
        d='M172.07,87c-.2,21.39-21,39-45.61,38.64s-44.69-18.77-44-40.09c.7-20.68,20.93-37.78,44.74-37.8C151.84,47.68,172.29,65.57,172.07,87Z'
        fillRule='evenodd'
      />
      <path
        d='M233.1,281.38c-13.92-8.15-27-17.38-40.3-26.26-6.26-4.19-7.78-10.9-5-16.12,3.11-6,9.54-.59,14.44,0s9.7,1.87,14.55,2.88c9.9,2,18.7.52,25.23-6.63s6.31-14.77,1.2-22.69c-11.85-18.38-23.48-36.87-35.15-55.33-4.53-7.13-11.55-10.55-20.67-12.38-40.84-8.19-81.48-7.41-122.22.4-8.5,1.64-14.62,5.6-18.76,12.14-11.68,18.46-23.25,37-35.12,55.34-5.14,8-5.16,15.53,1.43,22.64s15.42,8.54,25.3,6.48c9.36-1.94,18.75-3.85,27.93-5.72,4.14,11.14-.48,18.2-9.25,23.69-14.63,9.17-29.54,18-44.07,27.27-10.57,6.73-14.9,16.26-11.5,27.17C4.65,325.75,14,333,28,333.63s28.36.15,42.81.15c-7.5-28.6.52-39.79,32.35-46.34,17.54-3.61,35.15-7,52.7-10.58,6.2-1.27,13.73-3.42,15.54,3.82,1.92,7.6-6.79,7.62-12.54,8.82-18.13,3.78-36.24,7.74-54.53,10.92-12.17,2.11-22.5,6.11-21.49,18.21C84,331.76,95.39,334.11,108.57,334c35.52-.28,71.07-.46,106.57.05,17.91.27,32.9-3.3,38.12-19.65C258.34,298.65,247,289.47,233.1,281.38ZM163.62,203.9c-18.93-3.46-34.41-9.8-35.69-31.6-3.41,32.78-35.77,28.34-56.77,37.27-4.49,1.9-9,.11-10.54-4.1C59,201,62.51,198.54,67,197.13c2.91-.92,5.76-2.21,8.75-2.73,19.34-3.26,34.62-9.65,37.37-29.46.81-5.82,5-12.94,14.34-12.91a13.25,13.25,0,0,1,13.37,11.77c2.37,21.72,20.53,26.6,40,31.35,5.64,1.38,16.13,1.95,12.64,10.55-3.4,8.37-11.35,3.13-17.5,1.62-4.16-1-8.19-2.64-12.4-3.42Z'
        fillRule='evenodd'
      />
      <path
        d='M113.7,32.29C88,38.53,71,51.41,56.59,67.12c-1.17-2.44-2.34-4.55-3.16-6.7-1.12-2.94-1.49-6.06-.11-8.94,1-2.12,3.08-4,5-5.9A74.14,74.14,0,0,1,81.12,30c1.63-.74,3.38-1.36,5.14-2-6.54,0-17.06,5-29.18,14,.83-2,1.41-3.53,2.15-5.07a56.75,56.75,0,0,1,17.62-21.4C88.64,6.69,103.57,2.18,120.78.82,130,.1,139.14-.43,148.34.48a53.51,53.51,0,0,1,5.76.92C161.72,3,164.42,5.34,165,10.73c9,1.8,16.15,5,18.79,12a98,98,0,0,0-31.71-5.19c.68.11,1.38.26,2.06.35s1.48.11,2.2.2a138.43,138.43,0,0,1,35.09,10,8.4,8.4,0,0,1,2.74,2.1,22.69,22.69,0,0,1,6.48,12.39c.78,4.93,1,9.85-1.25,14.65A41.44,41.44,0,0,1,197,61.11a74.73,74.73,0,0,0-29.61-16.5c.74.32,1.51.62,2.25,1s1.52.66,2.23,1a89.45,89.45,0,0,1,22.27,16.5A4.36,4.36,0,0,1,195.45,66c-.11,6.87-1.3,13.64-4.87,20.13a21.65,21.65,0,0,1-8.29,8.63,27.66,27.66,0,0,1-12.13,3c1.71-22.57-9.71-38.43-38.62-49.88,9.08-4,18.12-8.51,27.88-11.5l-.25-.44c-5.24,1.28-10.52,2.43-15.66,3.83A141.44,141.44,0,0,0,110.27,53C90.43,64.33,83,79.82,84.41,97.31a3.53,3.53,0,0,1,0,.46C71.81,93,59.49,87.31,57.5,76.57a9.23,9.23,0,0,1,2.71-8.78A163.89,163.89,0,0,1,86.13,45.66a96.81,96.81,0,0,1,26.53-13A5.87,5.87,0,0,0,113.7,32.29Z'
        fillRule='evenodd'
      />
    </svg>
  );
};

MentorIcon.propTypes = {
  white: PropTypes.bool,
  noFill: PropTypes.bool,
  className: PropTypes.string,
};

MentorIcon.defaultProps = {
  white: false,
  noFill: false,
  className: '',
};

export default MentorIcon;
