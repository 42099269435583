import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AssetContext } from '../../contexts/asset/AssetContext';
import Button from '../../components/Generic/Button';
import { LangContext } from '../../contexts/language/LangContext';
import Text from '../../components/Generic/Text';
import firstUp from '../../controllers/firstUp';

const RoleFinalMessage = ({ role }) => {
	const { onlineEducationLaptop } = useContext(AssetContext);

	const {
		lang: {
			TX00048: JoinLang,
			TX00226: messageDisciple_1,
			TX00227: messageDisciple_2,
			TX00228: messageDisciple_3,
			TX00229: messageDisciple_4,
			TX00211: messageMentor_1,
			TX00212: messageMentor_2,
			TX00213: messageMentor_3,
			TX00214: messageMentor_4,
		},
	} = useContext(LangContext);

	const messagesDisciple = [
		messageDisciple_1.content,
		messageDisciple_2.content,
		messageDisciple_3.content,
		messageDisciple_4.content,
	];

	const messagesMentor = [
		messageMentor_1.content,
		messageMentor_2.content,
		messageMentor_3.content,
		messageMentor_4.content,
	];

	return (
		<section className='role-final-message'>
			<img src={onlineEducationLaptop} alt='webinclass_laptop' />
			<div>
				{role === 'education' &&
					messagesDisciple.map((message, index) => (
						<Text key={'role-message-' + index} text={firstUp(message)} />
					))}
				{role === 'mentoring' &&
					messagesMentor.map((message, index) => (
						<Text key={'role-message-' + index} text={firstUp(message)} />
					))}
				<Button primary full text={JoinLang.content} className='fs-s' />
			</div>
		</section>
	);
};

RoleFinalMessage.propTypes = {
	role: PropTypes.oneOf(['mentoring', 'education', '']).isRequired,
};

export default RoleFinalMessage;
