import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import UserPhoto from './UserPhoto';
import Text from './Text';
import { LangContext } from '../../contexts/language/LangContext';
import { AssetContext } from '../../contexts/asset/AssetContext';
import Box from './Box';
import firstUp from '../../controllers/firstUp';
import Button from './Button';
import DisplayBox from './DisplayBox';
import Pill from './Pill';
import Detail from './Detail';
import parseListToString from '../../controllers/parseListToString';
import Input from './Input';
import randNum from '../../controllers/randNum';
import { useHistory } from 'react-router';
import RatingIndicator from '../Indicators/RatingIndicator';

const LandscapeCard = ({
	user,
	user: {
		id,
		photo,
		rating,
		fullName,
		creSession,
		discipline,
		level,
		// disciples,
		experience,
		certified,
		avFor,
		teachingLang,
		// hours,
		// sessionH,
		description,
		video,
		from,
	},
	className,
}) => {
	id = randNum(1000);

	const {
		lang: {
			TX00055: creLang,
			TX00417: levelLang,
			TX00232: profileLang,
			TX00336: messageLang,
			TX00005: sessionLang,
			// TX00061: sessionsLang,
			TX00691: certifiedLang,
			TX00383: avForLang,
			TX00424: teachingLangLang,
			// TX00695: activeLang,
			TX00425: aboutMeLang,
			TX00702: myVideoLang,
		},
	} = useContext(LangContext);

	const { discipleIcon, arabic } = useContext(AssetContext);

	const [favorite, setFavorite] = useState(false);

	const history = useHistory();

	const handlePress = (e) => {
		e.stopPropagation();
		const value = e.target.value;
		const message = firstUp(messageLang.content);

		value === message
			? history.push(`/messages`)
			: history.push(`/user_profile/${id}`, { user });
	};

	const handleFavorite = (e) => {
		e.stopPropagation();
		setFavorite(!favorite);
	};

	return (
		<Box
			className={`landscape-card ${className}`}
			onClick={(e) => handlePress(e)}
		>
			<Input
				id={'favorite-' + id}
				type='checkbox'
				checked={favorite}
				onChange={handleFavorite}
			/>
			<div className='landscape-card-title'>
				<UserPhoto photo={photo} />
				<DisplayBox
					noFill
					content={{
						number: creSession,
						ss: creLang.content,
						info: sessionLang.content,
					}}
				/>
				<RatingIndicator size='s' rating={rating} showRating />
			</div>

			<section className='landscape-card-body-section-1'>
				<Text text={`${fullName}, ${from}`} className='fw-m' />
				<Text
					className='fs-xs'
					icon={arabic}
					text={`${firstUp(discipline)}, ${experience} years, ${firstUp(
						levelLang.content
					)} ${level}${certified && `, ${certifiedLang.content}`}`}
				/>
				<Detail label={firstUp(avForLang.content)}>
					{`${firstUp(levelLang.content)} ${avFor}`}
				</Detail>
				<Detail label={firstUp(teachingLangLang.content)}>
					{parseListToString(teachingLang)}
				</Detail>
				<div className='landscape-card-pills'>
					<Pill content={{ icon: discipleIcon, number: 12, text: 'active' }} />
					<Pill content={{ icon: discipleIcon, number: 12, text: 'active' }} />
					<Pill content={{ icon: discipleIcon, number: 12, text: 'active' }} />
				</div>
			</section>
			<section className='landscape-card-body-section-2'>
				{description && (
					<Detail column label={firstUp(aboutMeLang.content)}>
						{description}
					</Detail>
				)}
				{video && (
					<Detail column label={firstUp(myVideoLang.content)}>
						<video src={video}></video>
					</Detail>
				)}
			</section>

			<div className='landscape-card-buttons'>
				<Button
					primary
					text={profileLang.content}
					onClick={(e) => handlePress(e)}
				/>
				<Button dark text={messageLang.content} onClick={(e) => handlePress(e)} />
			</div>
		</Box>
	);
};

LandscapeCard.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string,
		photo: PropTypes.string,
		rating: PropTypes.number,
		fullName: PropTypes.string.isRequired,
		creSession: PropTypes.number.isRequired,
		discipline: PropTypes.string.isRequired,
		level: PropTypes.number.isRequired,
		disciples: PropTypes.number,
		experience: PropTypes.number.isRequired,
		certified: PropTypes.bool,
		avFor: PropTypes.number.isRequired,
		teachingLang: PropTypes.array.isRequired,
		hours: PropTypes.number,
		sessionH: PropTypes.number,
		description: PropTypes.string,
		video: PropTypes.string,
		from: PropTypes.string,
	}).isRequired,
	className: PropTypes.string,
};

LandscapeCard.defaultProps = {
	className: '',
	user: {
		description: undefined,
		video: undefined,
	},
};

export default LandscapeCard;
