// CL20001

import React, { useContext } from 'react';
import { AssetContext } from '../../contexts/asset/AssetContext';
import firstUp from '../../controllers/firstUp';
import { LangContext } from '../../contexts/language/LangContext';
import Button from '../../components/Generic/Button';

const Hero = () => {
  const {
    lang: {
      TX00001: motto,
      TX00002: subMotto,
      TX00003: platformDesc,
      TX00050: educationLang,
      TX00051: mentoringLang,
    },
  } = useContext(LangContext);

  const { backdrop_end } = useContext(AssetContext);

  return (
    <section className='hero' id='hero'>
      <header className='container'>
        <h1 className='clr-gold'>{firstUp(motto.content)}</h1>
        <h2 className='clr-white fw-b'>{firstUp(subMotto.content)}</h2>
        <p className='clr-white'>{firstUp(platformDesc.content)}</p>
        <div>
          {/* CL100001_1 */}
          <Button
            primary
            text={firstUp(educationLang.content)}
            className='fs-s'
            to={`/role/${educationLang.content}`}
          />

          {/* CL100001_1 */}
          <Button
            secondary
            text={firstUp(mentoringLang.content)}
            className='fs-s'
            to={`/role/${mentoringLang.content}`}
          />
        </div>
      </header>
      <img src={backdrop_end} alt='backdrop_end' />
    </section>
  );
};

export default Hero;
