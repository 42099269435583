import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';
import { LocContext } from '../../contexts/location/LocContext';
import To from '../Navigation/To';
import firstUp from '../../controllers/firstUp';

const LegalLinks = ({ className }) => {
	const {
		lang: {
			TX00283: { content: legalLang },
			TX00284: { content: termsLang },
			TX00285: { content: gdprLang },
			TX00286: { content: contractLang },
		},
	} = useContext(LangContext);

	const { path } = useContext(LocContext);

	const legalLinks = [termsLang, gdprLang, contractLang];

	return (
		<div className={`legal-links ${className}`}>
			<div className='legal-links-content'>
				<h5>{firstUp(legalLang)}</h5>
				{legalLinks.map((link, index) => (
					<To key={'legalLinkKey_' + index} to={`${path}`}>
						{firstUp(link)}
					</To>
				))}
			</div>
		</div>
	);
};

LegalLinks.propTypes = {
	className: PropTypes.string,
};

LegalLinks.defaultProps = {
	className: '',
};

export default LegalLinks;