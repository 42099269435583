import { CHANGE_LANG, SET_AV_LANGS } from './types';

const langReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return {
        ...state,
        lang: action.payload.lang,
        languageSelected: action.payload.short,
      };
    case SET_AV_LANGS:
      return { ...state, availableLangs: action.payload };
    default:
      return state;
  }
};

export default langReducer;
