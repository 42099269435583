import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import firstUp from '../../controllers/firstUp';
import handleTooltip from '../../controllers/handleTooltip';
import Text from '../Generic/Text';
import ArrowIcon from '../Assets/ArrowIcon';
import { LangContext } from '../../contexts/language/LangContext';
import { AssetContext } from '../../contexts/asset/AssetContext';
import To from './To';

const Hamburger = ({ className, white }) => {
	const tooltipRef = useRef(null);
	const hamburgerRef = useRef(null);

	const {
		lang: {
			TX00230: menuLang,
			TX00231: accountLang,
			TX00232: profileLang,
			TX00760: communityLang,
			TX00233: forumLang,
			TX00030: academyLang,
			TX00234: supportLang,
			TX00235: dismissLang,
		},
	} = useContext(LangContext);

	const {
		accountIcon,
		profileIcon,
		communityIcon,
		supportIcon,
		forumIcon,
		academyIcon,
		dismissIcon,
	} = useContext(AssetContext);

	const hamburgerLinks = [
		accountLang.content,
		profileLang.content,
		communityLang.content,
		forumLang.content,
		academyLang.content,
		supportLang.content,
		dismissLang.content,
	];

	const hamburgerIcons = {
		[accountLang.content]: accountIcon,
		[profileLang.content]: profileIcon,
		[communityLang.content]: communityIcon,
		[forumLang.content]: forumIcon,
		[academyLang.content]: academyIcon,
		[supportLang.content]: supportIcon,
		[dismissLang.content]: dismissIcon,
	};

	const handleMenu = () => hamburgerRef.current.classList.toggle('selected');

	return (
		<div
			ref={hamburgerRef}
			className={`hamburger ${className} ${white && 'hamburger-white'}`}
			onClick={() => handleMenu()}
			onMouseEnter={() => handleTooltip(tooltipRef)}
			onMouseLeave={() => handleTooltip(tooltipRef)}
		>
			<div className='hamburger-icon'>
				<ArrowIcon fill white={white && true} />
				<Text textRef={tooltipRef} className='tooltip'>
					{firstUp(menuLang.content)}
				</Text>
			</div>
			<div className='hamburger-menu'>
				<h5>{firstUp(menuLang.content)}</h5>
				<ul>
					{hamburgerLinks.map((link, index) => (
						<Text key={'keyHamburger_' + index} li>
							<To to={`/${link}`}>
								<img src={hamburgerIcons[link]} alt={'wic_' + link} />
								{firstUp(link)}
							</To>
						</Text>
					))}
				</ul>
			</div>
		</div>
	);
};

Hamburger.propTypes = {
	className: PropTypes.string,
	white: PropTypes.bool,
};

Hamburger.defaultProps = {
	className: '',
	white: false,
};

export default Hamburger;
