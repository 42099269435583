const english = {
	TX00001: {
		content: 'learn. Explore. Transform.',
		tooltip: '',
	},
	TX00002: {
		content: 'the most complete online education platform.',
		tooltip: '',
	},
	TX00003: {
		content: 'the learning process is a never ending journey.',
		tooltip: '',
	},
	TX00004: {
		content: 'get to know us better',
		tooltip: '',
	},
	TX00005: {
		content: 'session',
		tooltip: '',
	},
	TX00006: {
		content: 'class',
		tooltip: '',
	},
	TX00007: {
		content: 'webinar',
		tooltip: '',
	},
	TX00008: {
		content: 'live',
		tooltip: '',
	},
	TX00009: {
		content:
			'the matching point of your destination you are yearning for and the montor’s desire to teach.',
		tooltip: '',
	},
	TX00010: {
		content:
			'one to One online sessions are the most effective when trying to learn.',
		tooltip: '',
	},
	TX00011: {
		content:
			'get together with peers that have the same interest as you do and discover the next level of learning',
		tooltip: '',
	},
	TX00012: {
		content: 'define your learning style by analysing a variety of other styles.',
		tooltip: '',
	},
	TX00013: {
		content:
			'variety of topics, result of experience and review of information from on mentor to all of you.',
		tooltip: '',
	},
	TX00014: {
		content: 'up to date, hot and wide topics that will in-light your experience',
		tooltip: '',
	},
	TX00015: {
		content:
			'one mentor. One hour. More questions. Find your answers and clarify scenarios.',
		tooltip: '',
	},
	TX00016: {
		content: 'engage online in a real-time conversation from anywhere.',
		tooltip: '',
	},
	TX00017: {
		content: 'mentor',
		tooltip: '',
	},
	TX00018: {
		content: 'disciple',
		tooltip: '',
	},
	TX00019: {
		content:
			'share your knowledge worldwide. Help others to achieve their goals. You may wonder how to do this? Join our community as a mentor and give others access to your information empire. Be the educational guide you always needed as a novice.',
		tooltip: '',
	},
	TX00020: {
		content:
			'did you ever experience a mismatch between a subject you were passionate about and the one who teaches you that subject? now you can pick the most suitable mentor for yourself. Choose your mentor from our diverse community.',
		tooltip: '',
	},
	TX00021: {
		content: 'begin your journey',
		tooltip: '',
	},
	TX00022: {
		content: 'languages',
		tooltip: '',
	},
	TX00023: {
		content: 'sciences',
		tooltip: '',
	},
	TX00024: {
		content: 'talents',
		tooltip: '',
	},
	TX00025: {
		content:
			'make your world a better place and give you the best gift: understanding others in their own language.',
		tooltip: '',
	},
	TX00026: {
		content:
			'go to the Moon and back! Invent and reinvent yourself through science and improve the wold you live in.',
		tooltip: '',
	},
	TX00027: {
		content:
			'the best way to express your emotions, your passion and your personality is through your talents.',
		tooltip: '',
	},
	TX00028: {
		content: 'library',
		tooltip: 'Check our selection of documents',
	},

	TX00029: {
		content: 'certifications',
		tooltip: '',
	},
	TX00030: {
		content: 'academy',
		tooltip: '',
	},
	TX00031: {
		content:
			'libraries are important cornerstones of a healthy community. Libraries give people the opportunity to find jobs, explore research, experience new ideas, get lost in wonderful stories.',
		tooltip: '',
	},
	TX00032: {
		content:
			'a certification is a credential that you earn to show that you have specific skills or knowledge. They are usually tied to an occupation, technology, or industry.',
		tooltip: '',
	},
	TX00033: {
		content:
			'academies are independent, state-funded schools, which receive their funding directly from central government, rather than through a local authority. They control their own admissions process.',
		tooltip: '',
	},
	TX00034: {
		content: 'don’t take our word for it',
		tooltip: '',
	},
	TX00035: {
		content: 'continue with...',
		tooltip: '',
	},
	TX00036: {
		content: 'facebook',
		tooltip: '',
	},
	TX00037: {
		content: 'google',
		tooltip: '',
	},
	TX00038: {
		content: 'apple',
		tooltip: '',
	},
	TX00039: {
		content: 'e-mail',
		tooltip: '',
	},
	TX00040: {
		content: 'password',
		tooltip: '',
	},
	TX00041: {
		content: 'or',
		tooltip: '',
	},
	TX00042: {
		content: 'already have an account?',
		tooltip: '',
	},
	TX00043: {
		content: 'register',
		tooltip: '',
	},
	TX00044: {
		content: 'sign in',
		tooltip: '',
	},
	TX00045: {
		content: 'having trouble?',
		tooltip: '',
	},
	TX00046: {
		content: 'explore',
		tooltip: 'find a mentor',
	},

	TX00047: {
		content: 'folder',
		tooltip: '',
	},
	TX00048: {
		content: 'join',
		tooltip: 'Enroll in our community',
	},
	TX00049: {
		content: 'more…',
		tooltip: 'More information',
	},
	TX00050: {
		content: 'education',
		tooltip: '',
	},
	TX00051: {
		content: 'mentoring',
		tooltip: '',
	},
	TX00052: {
		content: 'communication is a very important skill.',
		tooltip: '',
	},
	TX00053: {
		content: 'language',
		tooltip: '',
	},
	TX00054: {
		content: 'mentors',
		tooltip: '',
	},
	TX00055: {
		content: 'CRE',
		tooltip: '',
	},
	TX00056: {
		content: 'av.',
		tooltip: '',
	},
	TX00057: {
		content: 'science is the seed of progress.',
		tooltip: '',
	},

	TX00058: {
		content: 'science',
		tooltip: '',
	},
	TX00059: {
		content: 'the expression of your emotions.',
		tooltip: '',
	},
	TX00060: {
		content: 'talent',
		tooltip: '',
	},
	TX00061: {
		content: 'sessions',
		tooltip: '',
	},
	TX00062: {
		content: 'how do you start?',
		tooltip: '',
	},
	TX00063: {
		content: 'create an account',
		tooltip: '',
	},
	TX00064: {
		content:
			'once you create an account don’t forget to add all your info in the account settings section.',
		tooltip: '',
	},
	TX00065: {
		content: 'this will help others trust you more easily.',
		tooltip: '',
	},
	TX00066: {
		content: 'connect your credit card',
		tooltip: '',
	},
	TX00067: {
		content: 'this will be used for purchasing or withdrawing CRE.',
		tooltip: '',
	},
	TX00068: {
		content: '1 CRE = 1 EUR',
		tooltip: '',
	},
	TX00069: {
		content: 'become part of the community',
		tooltip: '',
	},
	TX00070: {
		content: 'add an interest as a disciple.',
		tooltip: '',
	},
	TX00071: {
		content: 'add a discipline as a mentor.',
		tooltip: '',
	},
	TX00072: {
		content:
			'give our vast selection of mentors a look. We are sure you will be able to find a perfect match.',
		tooltip: '',
	},
	TX00073: {
		content: 'what is a session?',
		tooltip: '',
	},
	TX00074: {
		content: 'pilot',
		tooltip: '',
	},
	TX00075: {
		content: 'one to One',
		tooltip: '',
	},
	TX00076: {
		content: 'recurrent',
		tooltip: '',
	},
	TX00077: {
		content: 'certification',
		tooltip: '',
	},
	TX00078: {
		content: 'the first session with your mentor is a pilot session.',
		tooltip: '',
	},
	TX00079: {
		content:
			'note: If by any chance you do not enjoy your pilot session, feel free to ask for refund.',
		tooltip: '',
	},
	TX00080: {
		content: 'ad hoc session with your mentor without any frequency.',
		tooltip: '',
	},
	TX00081: {
		content:
			'a session that will take place based on a weekly recurrency, on the same day and at the same time.',
		tooltip: '',
	},
	TX00082: {
		content: 'a session part of a certification program.',
		tooltip: '',
	},
	TX00083: {
		content: 'session as a disciple',
		tooltip: '',
	},
	TX00084: {
		content:
			'can be scheduled only by a disciple and the CRE will be held until the session is completed',
		tooltip: '',
	},
	TX00085: {
		content:
			'for recurrent sessions feel free to check your mentor’s available packages in order to get discounts',
		tooltip: '',
	},
	TX00086: {
		content:
			'setting the schedule is done from your own agenda or from the availability time frame displayed on a mentor’s profile',
		tooltip: '',
	},
	TX00087: {
		content:
			'if you receive any homework or material you will be able to find it in the library',
		tooltip: '',
	},
	TX00088: {
		content: 'with a minimum of 4 hours before it starts:',
		tooltip: '',
	},
	TX00089: {
		content: '— you can update or cancel the session',
		tooltip: '',
	},
	TX00090: {
		content: '— you can confirm or cancel the session if any changes occurred',
		tooltip: '',
	},
	TX00091: {
		content:
			'when a session has started and the meeting room is set up on the platform you can access the room from your session card in the agenda',
		tooltip: '',
	},
	TX00092: {
		content:
			'once the session is finished you can mark it as complete (after 72 hours it will automatically be marked as complete for you)  ',
		tooltip: '',
	},
	TX00093: {
		content: 'reviews will always help:',
		tooltip: '',
	},
	TX00094: {
		content: '— you to follow your plan with full engagement',
		tooltip: '',
	},
	TX00095: {
		content: '— your mentor to always improve their approach',
		tooltip: '',
	},
	TX00096: {
		content: '— your peers when selecting their mentors',
		tooltip: '',
	},
	TX00097: {
		content: 'session as a mentor',
		tooltip: '',
	},
	TX00098: {
		content:
			'a mentor can set up the availability time frame for a specific discipline and a disciple can schedule a session only during this set period',
		tooltip: '',
	},
	TX00099: {
		content: 'after a session is scheduled you can add to it:',
		tooltip: '',
	},
	TX00100: {
		content:
			'— a title and topic for your disciple to understand what the session will be about',
		tooltip: '',
	},
	TX00101: {
		content: '— a file from the library',
		tooltip: '',
	},
	TX00102: {
		content: '— homework',
		tooltip: '',
	},
	TX00103: {
		content:
			'you can update or cancel the session any time before it starts, but make sure to message your disciple about it',
		tooltip: '',
	},
	TX00104: {
		content:
			'when a session started and the meeting room is set up on the platform you can access the room from your session card in the agenda',
		tooltip: '',
	},
	TX00105: {
		content:
			'once the session is marked as completed (done by the disciple or automatically after 72 hours) CRE will be allocated to your wallet ',
		tooltip: '',
	},
	TX00106: {
		content: 'review and feedback will always help:',
		tooltip: '',
	},
	TX00107: {
		content: '— you to commit to your disciples even more',
		tooltip: '',
	},
	TX00108: {
		content: '— your disciples to keep on track with their goals',
		tooltip: '',
	},
	TX00109: {
		content: 'classes',
		tooltip: '',
	},
	TX00110: {
		content: 'what is a class?',
		tooltip: '',
	},
	TX00111: {
		content: 'one to Many',
		tooltip: '',
	},
	TX00112: {
		content: 'ad hoc class with your mentor and peers without any frequency.',
		tooltip: '',
	},
	TX00113: {
		content:
			'a class that will take place based on a weekly reccurency, on the same day and at the same time.',
		tooltip: '',
	},
	TX00114: {
		content: 'class as a disciple',
		tooltip: '',
	},
	TX00115: {
		content: 'can be scheduled only by a mentor',
		tooltip: '',
	},
	TX00116: {
		content:
			'for recurrent classes feel free to check your mentor’s available packages in order to get discounts',
		tooltip: '',
	},
	TX00117: {
		content:
			'you can attend a class by accepting an invitation from your mentor or by joining through your agenda',
		tooltip: '',
	},
	TX00118: {
		content:
			'when enrolling to a class your CRE will be held until it is completed',
		tooltip: '',
	},
	TX00119: {
		content:
			'if you receive any homework or material you will be able to find it in the library',
		tooltip: '',
	},
	TX00120: {
		content: '— you can cancel the class',
		tooltip: '',
	},
	TX00121: {
		content: '— you can confirm or cancel the class if any changes occurred',
		tooltip: '',
	},
	TX00122: {
		content:
			'when a class has started and the meeting room is set up on the platform you can access the room from your class card in the agenda',
		tooltip: '',
	},
	TX00123: {
		content:
			'once the class is finished you can mark it as complete (after 72 hours it will automatically be marked as complete for you)  ',
		tooltip: '',
	},
	TX00124: {
		content: 'class as a mentor',
		tooltip: '',
	},
	TX00125: {
		content:
			'a mentor can set up classes and then invite or leave the seats empty for disciples to attend',
		tooltip: '',
	},
	TX00126: {
		content: 'when a class is scheduled you can add to it:',
		tooltip: '',
	},
	TX00127: {
		content:
			'— a title and topic for your disciples to understand what the class will be about',
		tooltip: '',
	},
	TX00128: {
		content: '— a file from the library',
		tooltip: '',
	},
	TX00129: {
		content: '— homework',
		tooltip: '',
	},
	TX00130: {
		content:
			'you can update or cancel the class any time before it starts, but make sure to message your disciples about it',
		tooltip: '',
	},
	TX00131: {
		content:
			'when a class started and the meeting room is set up on the platform you can access the room from your class card in the agenda',
		tooltip: '',
	},
	TX00132: {
		content:
			'once the class is marked as completed (done by disciples or automatically after 72 hours) CRE will be allocated to your wallet ',
		tooltip: '',
	},
	TX00133: {
		content: 'webinars',
		tooltip: '',
	},
	TX00134: {
		content: 'what is a webinar?',
		tooltip: '',
	},
	TX00135: {
		content:
			'a webinar allows a speaker from the hosting organization/company to share PowerPoint presentations, videos, web pages or other multimedia content with audiences that can be located anywhere. Webinars typically have audio and visual components.',
		tooltip: '',
	},
	TX00136: {
		content: 'webinar as a disciple',
		tooltip: '',
	},
	TX00137: {
		content:
			'you can attend a webinar by joining through your agenda and your CRE will be held until it is completed',
		tooltip: '',
	},
	TX00138: {
		content: '— you can cancel the webinar',
		tooltip: '',
	},
	TX00139: {
		content: '— you can confirm or cancel the webinar if any changes occurred',
		tooltip: '',
	},
	TX00140: {
		content:
			'when a webinar has started you can access the room from your webinar card in the agenda',
		tooltip: '',
	},
	TX00141: {
		content:
			'once the webinar is finished you can mark it as complete (after 72 hours it will automatically be marked as complete for you)  ',
		tooltip: '',
	},
	TX00142: {
		content: 'webinar as a mentor',
		tooltip: '',
	},
	TX00143: {
		content:
			'a mentor can set up webinars with a maximum number disciple attendance',
		tooltip: '',
	},
	TX00144: {
		content: 'when a webinar is scheduled you can add to it:',
		tooltip: '',
	},
	TX00145: {
		content:
			'— a title and topic for your disciples to understand what the webinar will be about',
		tooltip: '',
	},
	TX00146: {
		content: 'you can update or cancel the webinar any time',
		tooltip: '',
	},
	TX00147: {
		content:
			'when a webinar has started and the meeting room is set up on the platform you can access the room from your webinar card in the agenda',
		tooltip: '',
	},
	TX00148: {
		content:
			'once the webinar is marked as completed (done by disciples or automatically after 72 hours) CRE will be allocated to your wallet ',
		tooltip: '',
	},
	TX00149: {
		content: 'what is live mentoring?',
		tooltip: '',
	},
	TX00150: {
		content:
			'a live allows a speaker from the hosting organization/company to share PowerPoint presentations, videos, web pages or other multimedia content with audiences that can be located anywhere. Webinars typically have audio and visual components.',
		tooltip: '',
	},
	TX00151: {
		content: 'live as a disciple',
		tooltip: '',
	},
	TX00152: {
		content:
			'you can attend a live by joining through your agenda and your CRE will be held until it is completed',
		tooltip: '',
	},
	TX00153: {
		content: 'before it starts:',
		tooltip: '',
	},
	TX00154: {
		content: '— you can cancel the attendancy',
		tooltip: '',
	},
	TX00155: {
		content:
			'when a live has started you can access the room from your live card in the agenda',
		tooltip: '',
	},
	TX00156: {
		content: 'live as a mentor',
		tooltip: '',
	},
	TX00157: {
		content:
			'a mentor can set up a live mentoring activity with an unlimited number of disciples',
		tooltip: '',
	},
	TX00158: {
		content: 'you can cancel the live at any time before it starts',
		tooltip: '',
	},
	TX00159: {
		content:
			'when a live has started you can access the room from your live card in the agenda',
		tooltip: '',
	},
	TX00160: {
		content:
			'once the live is finished CRE will be allocated to your wallet  based on the attendancy list',
		tooltip: '',
	},
	TX00161: {
		content: 'WebinClass',
		tooltip: '',
	},
	TX00162: {
		content: 'the most complete online platform for education.',
		tooltip: '',
	},
	TX00163: {
		content:
			'easy and friendly designed, to cover all types of online mentoring and become a leader in this industry.',
		tooltip: '',
	},
	TX00164: {
		content: 'how it works',
		tooltip: '',
	},
	TX00165: {
		content: 'join our platform! Create an account',
		tooltip: '',
	},
	TX00166: {
		content: 'accept legal requirements',
		tooltip: '',
	},
	TX00167: {
		content: 'become part of the community',
		tooltip: '',
	},
	TX00168: {
		content: 'make the most of your knowledge',
		tooltip: '',
	},
	TX00169: {
		content:
			'once you create an account don’t forget to add all your info in the account settings section.',
		tooltip: '',
	},
	TX00170: {
		content: 'this will help others trust you more easily.',
		tooltip: '',
	},
	TX00171: {
		content: '— connect your credit card. This will be used for withdrawing CRE.',
		tooltip: '',
	},
	TX00172: {
		content: '— read and agree to the terms and conditions.',
		tooltip: '',
	},
	TX00173: {
		content: '— read and agree to the GDPR.',
		tooltip: '',
	},
	TX00174: {
		content: '— sign the contract.',
		tooltip: '',
	},
	TX00175: {
		content: '— connect your credit card. This will be used for purchasing CRE.',
		tooltip: '',
	},
	TX00176: {
		content:
			'add a discipline that defines you as a mentor, challenge yourself to become one of the best!',
		tooltip: '',
	},
	TX00177: {
		content: '— describe yourself as a mentor. Add all details and a video.',
		tooltip: '',
	},
	TX00178: {
		content: '— attach your degrees and show others how well prepared you are.',
		tooltip: '',
	},
	TX00179: {
		content: '— determine charges. Define the value of your work.',
		tooltip: '',
	},
	TX00180: {
		content: '— set up your availability.',
		tooltip: '',
	},
	TX00181: {
		content:
			'add an interest that defines you as a disciple, challenge yourself to become one of the best!',
		tooltip: '',
	},
	TX00182: {
		content: '— evaluate yourself and identify your initial level.',
		tooltip: '',
	},
	TX00183: {
		content: '— define your goal and be the driver of your journey.',
		tooltip: '',
	},
	TX00184: {
		content: '— set up a plan! Consistency and persistency lead to success. ',
		tooltip: '',
	},
	TX00185: {
		content: '— uncover your motivation! Be focused and engaged in your plan.',
		tooltip: '',
	},
	TX00186: {
		content: '— based on your activity CRE wil be allocated to your wallet.',
		tooltip: '',
	},
	TX00187: {
		content:
			'— every month, on the 1st, we deliver pay checks based on your previous month’s activity.',
		tooltip: '',
	},
	TX00188: {
		content: '— more than 1000 CRE can be withdrawn at any time.',
		tooltip: '',
	},
	TX00189: {
		content: '— invest in yourself by purchasing CRE inside your wallet.',
		tooltip: '',
	},
	TX00190: {
		content: '— decide when and how you want to use your CRE.',
		tooltip: '',
	},
	TX00191: {
		content:
			'— go the extra mile! Invest into library materials, tests or certifications.',
		tooltip: '',
	},
	TX00192: {
		content: 'available categories',
		tooltip: '',
	},
	TX00193: {
		content: 'activities',
		tooltip: '',
	},
	TX00194: {
		content: 'our values',
		tooltip: '',
	},
	TX00195: {
		content: 'learning is a never-ending process!',
		tooltip: '',
	},
	TX00196: {
		content:
			'we reward your mentoring practice; fees will be decreasing for those who are the most active! ',
		tooltip: '',
	},
	TX00197: {
		content: 'the Pilot Session is chargeable by the platform.',
		tooltip: '',
	},
	TX00198: {
		content:
			'packages are available for you to activate in order for disciples to get discounts.',
		tooltip: '',
	},
	TX00199: {
		content:
			'in order to compensate both quality and quantity in the same manner, the first threshold reached: credits earned, or activity hours completed on the platform will release the fee level you deserve. Great job!',
		tooltip: '',
	},
	TX00200: {
		content: 'competition is one of the primary motivation!',
		tooltip: '',
	},
	TX00201: {
		content:
			'we are competitive even from before we are born! This is one of the 3 primary needs of every human being.',
		tooltip: '',
	},
	TX00202: {
		content:
			'the more challenges we have, the better we become in the activity we do and it keeps us more passionate about it.',
		tooltip: '',
	},
	TX00203: {
		content: 'let’s enjoy competition: with yourself and with the others. ',
		tooltip: '',
	},
	TX00204: {
		content: 'collect diamonds by teaching and get amazing benefits.',
		tooltip: '',
	},
	TX00205: {
		content: 'high prestige is the result of commitment!',
		tooltip: '',
	},
	TX00206: {
		content:
			'we know you commit to become the best mentor. For you to be selected the one, its important you are visible from our community of mentors based on filters, but more important recommended by reviews and activity.',
		tooltip: '',
	},
	TX00207: {
		content:
			'mentors which have the highest rates, the most activities and best results of their work, will be the ones more visible in the list, and you will be recognised by the level of experience.',
		tooltip: '',
	},
	TX00208: {
		content:
			'nevertheless, the new mentors will also be visible, anyone can have their own chance.',
		tooltip: '',
	},
	TX00209: {
		content: 'become a mentor!',
		tooltip: '',
	},
	TX00210: {
		content: 'become a disciple!',
		tooltip: '',
	},
	TX00211: {
		content:
			'didn’t we all had at least one experience with a teacher that didn’t match our expectations and we literally lost our interest on that specific area?',
		tooltip: '',
	},
	TX00212: {
		content:
			'now its your time! If you find a good opportunity to teach others, to use your knowledge and experience to pass it on to other people.',
		tooltip: '',
	},
	TX00213: {
		content:
			'new or old generation, become a mentor and challenge yourself to be the best version of what you have expected from your teachers. ',
		tooltip: '',
	},
	TX00214: {
		content:
			'transforming skills into wisdom using the right methodology and sending the good energy, this can define an ideal mentoring process.',
		tooltip: '',
	},
	TX00215: {
		content:
			'we reward your investment; discounts will be available for those who are the most active!',
		tooltip: '',
	},
	TX00216: {
		content:
			'choose the mentor you think you vibe with the most. The Pilot Session is fully refundable in case you decide to try another mentor.',
		tooltip: '',
	},
	TX00217: {
		content:
			'packages with discounts may be available for you to purchase if your mentors have activated them.',
		tooltip: '',
	},
	TX00218: {
		content:
			'in order to compensate both quality and quantity in the same manner, the first threshold reached: credits earned, or activity hours completed on the platform will release the discount level you deserve. Great job!',
		tooltip: '',
	},
	TX00219: {
		content:
			'we are competitive even from before we are born! This is one of the 3 primary needs of every human being.',
		tooltip: '',
	},
	TX00220: {
		content:
			'the more challenges we have, the better we become in the learning process and it keeps us more passionate about it.',
		tooltip: '',
	},
	TX00221: {
		content: 'let’s enjoy competition: with yourself and with the others. ',
		tooltip: '',
	},
	TX00222: {
		content: 'collect diamonds by learning and get amazing benefits.',
		tooltip: '',
	},
	TX00223: {
		content:
			'we know you expect to find the best mentors. For you to select the one from our community of mentors, based on your filters, is more important to be recommended by reviews and activity.',
		tooltip: '',
	},
	TX00224: {
		content:
			'mentors which have the highest rates, the most activities and best results of their work, will be the ones more visible in the list, and you will recognise them by the level of experience.',
		tooltip: '',
	},
	TX00225: {
		content:
			'nevertheless, the new mentors will also be visible, anyone can have their own chance.',
		tooltip: '',
	},
	TX00226: {
		content: 'the more you learn, learning becomes fast and easy.',
		tooltip: '',
	},
	TX00227: {
		content:
			'we all are disciples, learning everyday new things, having different interests and desire to explore each of them.',
		tooltip: '',
	},
	TX00228: {
		content:
			'new or old generation, become a disciple and challenge yourself to be the best version of what you envisioned of yourself. ',
		tooltip: '',
	},
	TX00229: {
		content: 'enjoy learning and be confident. The rest is in our hands! ',
		tooltip: '',
	},
	TX00230: {
		content: 'menu',
		tooltip: '',
	},
	TX00231: {
		content: 'account',
		tooltip: '',
	},
	TX00232: {
		content: 'profile',
		tooltip: '',
	},
	TX00233: {
		content: 'forum',
		tooltip: '',
	},
	TX00234: {
		content: 'support',
		tooltip: '',
	},
	TX00235: {
		content: 'dismiss',
		tooltip: '',
	},
	TX00236: {
		content: 'info',
		tooltip: '',
	},
	TX00237: {
		content: 'wallet',
		tooltip: 'wallet',
	},
	TX00238: {
		content: 'share',
		tooltip: '',
	},
	TX00239: {
		content: 'news',
		tooltip: 'be up to date',
	},

	TX00240: {
		content: 'first Name',
		tooltip: '',
	},
	TX00241: {
		content: 'last Name',
		tooltip: '',
	},
	TX00242: {
		content: 'birthday',
		tooltip: '',
	},
	TX00243: {
		content: 'gender',
		tooltip: '',
	},
	TX00244: {
		content: 'email',
		tooltip: '',
	},
	TX00245: {
		content: 'password',
		tooltip: '',
	},
	TX00246: {
		content: 'language (platform)',
		tooltip: '',
	},
	TX00247: {
		content: 'residence',
		tooltip: '',
	},
	TX00248: {
		content: 'timezone',
		tooltip: '',
	},
	TX00249: {
		content: 'from',
		tooltip: '',
	},
	TX00250: {
		content: 'edit',
		tooltip: '',
	},
	TX00251: {
		content: 'cancel',
		tooltip: '',
	},
	TX00252: {
		content: 'save',
		tooltip: '',
	},
	TX00253: {
		content: 'information marked as ',
		tooltip: '',
	},
	TX00254: {
		content: 'will be public on the platform!',
		tooltip: '',
	},
	TX00255: {
		content: 'get free CRE!',
		tooltip: '',
	},
	TX00256: {
		content: 'have you shared with your friends about Webinclass?',
		tooltip: '',
	},
	TX00257: {
		content: 'grow together by online learning.',
		tooltip: '',
	},
	TX00258: {
		content: 'cards',
		tooltip: '',
	},
	TX00259: {
		content: 'history',
		tooltip: '',
	},
	TX00260: {
		content: 'we care about our most dedicated members',
		tooltip: '',
	},
	TX00261: {
		content:
			'enjoy mentoring and get reworded. The more you learn, the more you are reworded!',
		tooltip: '',
	},
	TX00262: {
		content: 'let me teach you something!',
		tooltip: '',
	},
	TX00263: {
		content: 'how it works',
		tooltip: '',
	},
	TX00272: {
		content: 'sharing',
		tooltip: '',
	},
	TX00273: {
		content: 'buffer',
		tooltip: '',
	},
	TX00274: {
		content: 'withdraw',
		tooltip: '',
	},
	TX00277: {
		content: '% out of last 3 months avg.',
		tooltip: '',
	},
	TX00282: {
		content: 'add',
	},
	TX00283: {
		content: 'legal',
	},
	TX00284: {
		content: 'terms and conditions',
	},
	TX00285: {
		content: 'gdpr',
	},
	TX00286: {
		content: 'contract',
	},
	TX00287: {
		content: 'bank account',
	},
	TX00288: {
		content: 'full name',
	},
	TX00289: {
		content: 'country',
	},
	TX00290: {
		content: 'address',
	},
	TX00291: {
		content: 'account number',
	},
	TX00292: {
		content: 'digital signature',
	},
	TX00293: {
		content: 'this will be used for legal purposes.',
	},
	TX00295: {
		content: 'upload',
	},
	TX00298: {
		content: 'card number',
	},
	TX00299: {
		content: 'expire date',
	},
	TX00301: {
		content: 'cardholder',
	},
	TX00302: {
		content: 'card title',
	},
	TX00296: {
		content: 'draw',
	},

	TX00264: {
		content:
			'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime quos adipisci sint repudiandae id eius similique ullam quibusdam, culpa modi quaerat aspernatur, voluptates consectetur nostrum, enim magnam! Possimus voluptatibus incidunt itaque nulla nihil magni odit soluta est, at eos qui!',
		tooltip: '',
	},
	TX00378: {
		content:
			'Invest in yourself and we facilitate your learning by reducing the costs. There will be sessional campaigns with discounts, keep updated and you will get more!',
		tooltip: '',
	},
	TX00451: {
		content: 'recurrent',
		tooltip: '',
	},
	TX00723: {
		content: 'english',
		tooltip: '',
	},
	TX00729: {
		content: 'romanian',
		tooltip: '',
	},
	TX00725: {
		content: 'german',
		tooltip: '',
	},
	TX00332: {
		content: 'all',
		tooltip: '',
	},
	TX00391: {
		content: 'category',
		tooltip: '',
	},
	TX00361: {
		content: 'discipline',
		tooltip: '',
	},
	TX00686: {
		content: 'experience',
		tooltip: '',
	},
	TX00755: {
		content: 'test',
		tooltip: '',
	},
	TX00756: {
		content: 'test',
		tooltip: '',
	},
	TX00757: {
		content: 'test',
		tooltip: '',
	},
	TX00758: {
		content: 'test',
		tooltip: '',
	},
	TX00687: {
		content: 'timezone',
		tooltip: '',
	},
	TX00417: {
		content: 'level',
		tooltip: '',
	},
	TX00383: {
		content: 'available for (above)',
		tooltip: '',
	},
	TX00424: {
		content: 'teaching language',
		tooltip: '',
	},
	TX00394: {
		content: 'disciples',
		tooltip: '',
	},
	TX00695: {
		content: 'active',
		tooltip: '',
	},
	TX00354: {
		content: 'rank',
		tooltip: '',
	},
	TX00642: {
		content: 'initiator',
		tooltip: '',
	},
	TX00643: {
		content: 'explorer',
		tooltip: '',
	},
	TX00644: {
		content: 'coach',
		tooltip: '',
	},
	TX00645: {
		content: 'innovator',
		tooltip: '',
	},
	TX00646: {
		content: 'master',
		tooltip: '',
	},
	TX00647: {
		content: 'guru',
		tooltip: '',
	},
	TX00689: {
		content: 'extra',
		tooltip: '',
	},
	TX00690: {
		content: 'video',
		tooltip: '',
	},
	TX00691: {
		content: 'certified',
		tooltip: '',
	},
	TX00692: {
		content: 'test activated',
		tooltip: '',
	},
	TX00693: {
		content: 'library materials',
		tooltip: '',
	},
	TX00694: {
		content: 'certifications activated',
		tooltip: '',
	},
	TX00336: {
		content: 'message',
		tooltip: '',
	},
	TX00425: {
		content: 'about me',
	},
	TX00702: {
		content: 'my video',
	},
	TX00760: {
		content: 'community',
	},
	TX00703: {
		content: 'community',
	},
	TX00800: {
		content: 'general',
	},
	TX00704: {
		content: 'mentor since',
	},
	TX00499: {
		content: 'materials',
	},
	TX00666: {
		content: 'material',
	},
	TX00705: {
		content: 'born',
	},
	TX00706: {
		content: 'age',
	},
	TX00718: {
		content: 'disciple since',
	},
	TX00509: {
		content: 'interest',
	},
	TX00682: {
		content: 'interests',
	},
	TX00707: {
		content: 'day',
	},
	TX00493: {
		content: 'days',
	},
	TX00700: {
		content: 'hour',
	},
	TX00267: {
		content: 'hours',
	},
};

export default english;
