import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

const Pill = ({ content, small, large, className, onClick }) => {
  const { icon, number, text } = content;

  return (
    <div
      className={`pill ${className} ${small && 'pill-small'} ${
        large && 'pill-large'
      }`}
      onClick={() => onClick(text)}>
      {icon && <img src={icon} alt={`wic-${text}`} />}
      {number && <Text className='fs-xs' text={number} />}
      <Text text={text} className={`${number && 'clr-light-grey'} fs-xs`} />
    </div>
  );
};

Pill.propTypes = {
  content: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string.isRequired,
    number: PropTypes.number,
  }),
  onClick: PropTypes.func,
  small: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
};

Pill.defaultProps = {
  small: false,
  large: false,
  className: '',
  onClick: () => {},
};

export default Pill;
