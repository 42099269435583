import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '../Generic/Box';
import SearchBar from '../Search/SearchBar';
import Text from '../Generic/Text';
import { AssetContext } from '../../contexts/asset/AssetContext';
import Input from '../Generic/Input';
import firstUp from '../../controllers/firstUp';
import { FilterContext } from '../../contexts/filter/FilterContext';

const FilterMore = ({ moreRef, list, type, id, handleChange, name }) => {
  const { closeRed } = useContext(AssetContext);

  const { checkedCheckbox, checkedRadio } = useContext(FilterContext);

  const checked = type === 'checkbox' ? checkedCheckbox : checkedRadio;

  const alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];

  return (
    <Box refProp={moreRef} className='filter-more'>
      <img
        src={closeRed}
        alt='close'
        onClick={() => moreRef.current.classList.remove('filter-more-open')}
      />
      <div className='top'>
        <SearchBar />
        <div className='filter-alpha'>
          {alphabet.map((letter, index) => (
            <Text className='fw-m fs-xs' key={'key_' + letter + index}>
              {letter}
            </Text>
          ))}
        </div>
      </div>
      <div className='bottom'>
        {type === 'checkbox'
          ? list.map((item, index) => (
              <Input
                key={'key_' + id + index}
                type='checkbox'
                id={id + item + index}
                className={id}
                name={`${id}${firstUp(item)}`}
                value={item}
                checked={checked[`${id}`][`${id}${firstUp(item)}`]}
                onChange={handleChange}
              />
            ))
          : list.map((item, index) => (
              <Input
                key={'key_' + name + index}
                type='radio'
                id={id + index}
                value={item}
                name={name}
                checked={checked[`${name}`] === item}
                onClick={handleChange}
              />
            ))}
      </div>
    </Box>
  );
};

FilterMore.propTypes = {
  list: PropTypes.array.isRequired,
  moreRef: PropTypes.object,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default FilterMore;
