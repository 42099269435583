import React, { useContext } from 'react';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';
import CategoriesCard from '../../components/Category/CategoriesCard';

const Categories = () => {
  const {
    lang: {
      TX00022: languages,
      TX00025: textLanguages,
      TX00023: sciences,
      TX00026: textSciences,
      TX00024: talents,
      TX00027: textTalents,
    },
  } = useContext(LangContext);

  const {
    languagesCard_1,
    // languagesCard_2,
    // languagesCard_3,
    sciencesCard_1,
    // sciencesCard_2,
    // sciencesCard_3,
    talentsCard_1,
    // talentsCard_2,
    // talentsCard_3,
  } = useContext(AssetContext);

  const content = [
    {
      title: languages.content,
      text: textLanguages.content,
      img: languagesCard_1,
    },
    {
      title: sciences.content,
      text: textSciences.content,
      img: sciencesCard_1,
    },
    {
      title: talents.content,
      text: textTalents.content,
      img: talentsCard_1,
    },
  ];

  return (
    <section className='categories container'>
      {content.map((entry) => (
        <CategoriesCard key={entry.title} content={entry} />
      ))}
    </section>
  );
};

export default Categories;
