import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AssetContext } from '../../contexts/asset/AssetContext';

import ActivityList from '../../components/Activity/ActivityList';
import ColorSpot from '../../components/Assets/ColorSpot';
import { LangContext } from '../../contexts/language/LangContext';

const ActivityInfo = ({ activity, type }) => {
  const {
    sessionAsDisciple,
    sessionAsMentor,
    classAsDisciple,
    classAsMentor,
    webinarAsDisciple,
    webinarAsMentor,
    liveAsDisciple,
    liveAsMentor,
  } = useContext(AssetContext);

  const {
    lang: {
      TX00084: sessionAsDiscipleText_1,
      TX00085: sessionAsDiscipleText_2,
      TX00086: sessionAsDiscipleText_3,
      TX00087: sessionAsDiscipleText_4,
      TX00088: sessionAsDiscipleText_5,
      TX00089: sessionAsDiscipleText_5_1,
      TX00090: sessionAsDiscipleText_5_2,
      TX00091: sessionAsDiscipleText_6,
      TX00092: sessionAsDiscipleText_7,
      TX00093: sessionAsDiscipleText_8,
      TX00094: sessionAsDiscipleText_8_1,
      TX00095: sessionAsDiscipleText_8_2,
      TX00096: sessionAsDiscipleText_8_3,

      TX00098: sessionAsMentorText_1,
      TX00099: sessionAsMentorText_2,
      TX00100: sessionAsMentorText_2_1,
      TX00101: sessionAsMentorText_2_2,
      TX00102: sessionAsMentorText_2_3,
      TX00103: sessionAsMentorText_3,
      TX00104: sessionAsMentorText_4,
      TX00105: sessionAsMentorText_5,
      TX00106: sessionAsMentorText_6,
      TX00107: sessionAsMentorText_6_1,
      TX00108: sessionAsMentorText_6_2,

      TX00115: classAsDiscipleText_1,
      TX00116: classAsDiscipleText_2,
      TX00117: classAsDiscipleText_3,
      TX00118: classAsDiscipleText_4,
      TX00119: classAsDiscipleText_5,
      TX00088: classAsDiscipleText_6,
      TX00120: classAsDiscipleText_6_1,
      TX00121: classAsDiscipleText_6_2,
      TX00122: classAsDiscipleText_7,
      TX00123: classAsDiscipleText_8,
      TX00093: classAsDiscipleText_9,
      TX00094: classAsDiscipleText_9_1,
      TX00095: classAsDiscipleText_9_2,
      TX00096: classAsDiscipleText_9_3,

      TX00125: classAsMentorText_1,
      TX00126: classAsMentorText_2,
      TX00127: classAsMentorText_2_1,
      TX00128: classAsMentorText_2_2,
      TX00129: classAsMentorText_2_3,
      TX00130: classAsMentorText_3,
      TX00131: classAsMentorText_4,
      TX00132: classAsMentorText_5,

      TX00115: webinarAsDiscipleText_1,
      TX00137: webinarAsDiscipleText_2,
      TX00088: webinarAsDiscipleText_3,
      TX00138: webinarAsDiscipleText_3_1,
      TX00139: webinarAsDiscipleText_3_2,
      TX00140: webinarAsDiscipleText_4,
      TX00141: webinarAsDiscipleText_5,
      TX00093: webinarAsDiscipleText_6,
      TX00094: webinarAsDiscipleText_6_1,
      TX00095: webinarAsDiscipleText_6_2,
      TX00096: webinarAsDiscipleText_6_3,

      TX00143: webinarAsMentorText_1,
      TX00144: webinarAsMentorText_2,
      TX00145: webinarAsMentorText_2_1,
      TX00146: webinarAsMentorText_3,
      TX00147: webinarAsMentorText_4,
      TX00148: webinarAsMentorText_5,

      TX00115: liveAsDiscipleText_1,
      TX00152: liveAsDiscipleText_2,
      TX00153: liveAsDiscipleText_3,
      TX00154: liveAsDiscipleText_3_1,
      TX00155: liveAsDiscipleText_4,
      TX00093: liveAsDiscipleText_5,
      TX00094: liveAsDiscipleText_5_1,
      TX00095: liveAsDiscipleText_5_2,
      TX00096: liveAsDiscipleText_5_3,

      TX00157: liveAsMentorText_1,
      TX00158: liveAsMentorText_2,
      TX00159: liveAsMentorText_3,
      TX00160: liveAsMentorText_4,
    },
  } = useContext(LangContext);

  const activityInfoSession = {
    disciple: {
      img: sessionAsDisciple,
      list: [
        sessionAsDiscipleText_1.content,
        sessionAsDiscipleText_2.content,
        sessionAsDiscipleText_3.content,
        sessionAsDiscipleText_4.content,
        {
          header: sessionAsDiscipleText_5.content,
          list: [
            sessionAsDiscipleText_5_1.content,
            sessionAsDiscipleText_5_2.content,
          ],
        },
        sessionAsDiscipleText_6.content,
        sessionAsDiscipleText_7.content,
        {
          header: sessionAsDiscipleText_8.content,
          list: [
            sessionAsDiscipleText_8_1.content,
            sessionAsDiscipleText_8_2.content,
            sessionAsDiscipleText_8_3.content,
          ],
        },
      ],
    },
    mentor: {
      img: sessionAsMentor,
      list: [
        sessionAsMentorText_1.content,
        {
          header: sessionAsMentorText_2.content,
          list: [
            sessionAsMentorText_2_1.content,
            sessionAsMentorText_2_2.content,
            sessionAsMentorText_2_3.content,
          ],
        },
        sessionAsMentorText_3.content,
        sessionAsMentorText_4.content,
        sessionAsMentorText_5.content,
        {
          header: sessionAsMentorText_6.content,
          list: [
            sessionAsMentorText_6_1.content,
            sessionAsMentorText_6_2.content,
          ],
        },
      ],
    },
  };

  const activityInfoClass = {
    disciple: {
      img: classAsDisciple,
      list: [
        classAsDiscipleText_1.content,
        classAsDiscipleText_2.content,
        classAsDiscipleText_3.content,
        classAsDiscipleText_4.content,
        classAsDiscipleText_5.content,
        {
          header: classAsDiscipleText_6.content,
          list: [
            classAsDiscipleText_6_1.content,
            classAsDiscipleText_6_2.content,
          ],
        },
        classAsDiscipleText_7.content,
        classAsDiscipleText_8.content,
        {
          header: classAsDiscipleText_9.content,
          list: [
            classAsDiscipleText_9_1.content,
            classAsDiscipleText_9_2.content,
            classAsDiscipleText_9_3.content,
          ],
        },
      ],
    },
    mentor: {
      img: classAsMentor,
      list: [
        classAsMentorText_1.content,
        {
          header: classAsMentorText_2.content,
          list: [
            classAsMentorText_2_1.content,
            classAsMentorText_2_2.content,
            classAsMentorText_2_3.content,
          ],
        },
        classAsMentorText_3.content,
        classAsMentorText_4.content,
        classAsMentorText_5.content,
      ],
    },
  };

  const activityInfoWebinar = {
    disciple: {
      img: webinarAsDisciple,
      list: [
        webinarAsDiscipleText_1.content,
        webinarAsDiscipleText_2.content,
        {
          header: webinarAsDiscipleText_3.content,
          list: [
            webinarAsDiscipleText_3_1.content,
            webinarAsDiscipleText_3_2.content,
          ],
        },
        webinarAsDiscipleText_4.content,
        webinarAsDiscipleText_5.content,
        {
          header: webinarAsDiscipleText_6.content,
          list: [
            webinarAsDiscipleText_6_1.content,
            webinarAsDiscipleText_6_2.content,
            webinarAsDiscipleText_6_3.content,
          ],
        },
      ],
    },
    mentor: {
      img: webinarAsMentor,
      list: [
        webinarAsMentorText_1.content,
        {
          header: webinarAsMentorText_2.content,
          list: [webinarAsMentorText_2_1.content],
        },
        webinarAsMentorText_3.content,
        webinarAsMentorText_4.content,
        webinarAsMentorText_5.content,
      ],
    },
  };

  const activityInfoLive = {
    disciple: {
      img: liveAsDisciple,
      list: [
        liveAsDiscipleText_1.content,
        liveAsDiscipleText_2.content,
        {
          header: liveAsDiscipleText_3.content,
          list: [liveAsDiscipleText_3_1.content],
        },
        liveAsDiscipleText_4.content,

        {
          header: liveAsDiscipleText_5.content,
          list: [
            liveAsDiscipleText_5_1.content,
            liveAsDiscipleText_5_2.content,
            liveAsDiscipleText_5_3.content,
          ],
        },
      ],
    },
    mentor: {
      img: liveAsMentor,
      list: [
        liveAsMentorText_1.content,
        liveAsMentorText_2.content,
        liveAsMentorText_3.content,
        liveAsMentorText_4.content,
      ],
    },
  };

  const activityInfoContent = {
    session: activityInfoSession,
    class: activityInfoClass,
    webinar: activityInfoWebinar,
    live: activityInfoLive,
  };

  const { img, list } = activityInfoContent[`${activity}`][`${type}`];
  const reverse = type === 'mentor' ? true : false;

  return (
    <section className={`activity-info ${reverse && 'reverse'}`}>
      <ActivityList list={list} />
      <img src={img} alt='activity-info-presentation' />
      <ColorSpot left={reverse && true} bgColor={activity} />
    </section>
  );
};

ActivityInfo.propTypes = {
  activity: PropTypes.string,
  type: PropTypes.string.isRequired,
};

ActivityInfo.defaultProps = {
  activity: '',
};

export default ActivityInfo;
