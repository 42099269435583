import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth/AuthContext';
import { LangContext } from '../../contexts/language/LangContext';
import { LocContext } from '../../contexts/location/LocContext';
import firstUp from '../../controllers/firstUp';
import getSidebarContent from '../../controllers/getSidebarContent';
import UserPhoto from '../Generic/UserPhoto';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
	const sidebarRef = useRef(null);

	const {
		user: { photo },
	} = useContext(AuthContext);

	const {
		lang: {
			TX00231: { content: accountLang },
			TX00232: { content: profileLang },
		},
	} = useContext(LangContext);

	const { location, setSideBar, possibleRoutes } = useContext(LocContext);

	const locationTitles = {
		account: firstUp(accountLang),
		profile: firstUp(profileLang),
	};

	const content = getSidebarContent(location);

	const handleSidebar = () =>
		sidebarRef.current.classList.toggle('sidebar-closed');

	const { pathname } = useLocation();

	useEffect(() => {
		const slug = pathname.split('/')[2];

		setSideBar(slug, possibleRoutes[location]);
		// eslint-disable-next-line
	}, [location]);

	return (
		<aside ref={sidebarRef} className='sidebar'>
			<input
				type='checkbox'
				className='sidebar-close'
				onChange={() => handleSidebar()}
			/>
			<div className='sidebar-title'>
				<UserPhoto type='m' photo={photo} rank={6} male role='disciple' />
				<h5>{locationTitles[location]}</h5>
			</div>
			<ul className='sidebar-links'>
				{content &&
					content.map((link, index) => (
						<SidebarLink key={'key_' + location + index} link={link} index={index} />
					))}
			</ul>
		</aside>
	);
};

// Sidebar.propTypes = {};

export default Sidebar;
