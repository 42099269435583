import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Text from '../Generic/Text';
import Box from '../Generic/Box';
import ArrowIcon from '../Assets/ArrowIcon';
import firstUp from '../../controllers/firstUp';
import { LangContext } from '../../contexts/language/LangContext';
import handleCheckbox from '../../controllers/filter/handleCheckbox';
import { FilterContext } from '../../contexts/filter/FilterContext';
import FilterMore from './FilterMore';
import Input from '../Generic/Input';

const FilterCheckbox = ({ header, list, id }) => {
  const moreRef = useRef(null);

  const {
    lang: { TX00049: moreLang, TX00332: allLang },
  } = useContext(LangContext);

  const {
    checkedCheckbox,
    setCheckedCheckbox,
    setSelected,
    removeSelected,
    isFilterTracked,
  } = useContext(FilterContext);

  const handleChange = (e) => {
    const filter = e.target.className;
    const filterField = e.target.name;
    const value = e.target.value;
    const filterFieldValue = checkedCheckbox[filter][filterField];
    const isSelected = filterFieldValue === value;

    isFilterTracked(filter)
      ? isSelected
        ? (removeSelected(value), setCheckedCheckbox({ [filter]: '' }))
        : (removeSelected(checkedCheckbox[`${filter}`]),
          setSelected(value),
          setCheckedCheckbox({ [filter]: value }))
      : setCheckedCheckbox({ [filter]: isSelected ? '' : value });

    handleCheckbox(
      e,
      list,
      allLang.content,
      checkedCheckbox,
      setCheckedCheckbox
    );
  };

  return (
    <Box className='filter-item filter-item-checkbox fs-xs'>
      {header && (
        <div
          className='filter-title'
          onClick={(e) => e.target.classList.toggle('filter-close')}>
          <Text>{firstUp(header)}</Text>
          <ArrowIcon />
        </div>
      )}
      <form className='filter-body'>
        {list.map(
          (item, index) =>
            index <= 6 && (
              <Input
                key={'key_' + id + index}
                type='checkbox'
                id={id + item + index}
                className={id}
                name={`${id}${firstUp(item)}`}
                value={item}
                checked={checkedCheckbox[`${id}`][`${id}${firstUp(item)}`]}
                onChange={handleChange}
              />
            )
        )}
        {list.length > 7 && (
          <input
            type='button'
            value={moreLang.content}
            onClick={() => moreRef.current.classList.add('filter-more-open')}
          />
        )}
      </form>
      <FilterMore
        type='checkbox'
        id={id}
        list={list}
        moreRef={moreRef}
        handleChange={handleChange}
      />
    </Box>
  );
};

FilterCheckbox.propTypes = {
  header: PropTypes.string,
  list: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
};

export default FilterCheckbox;
