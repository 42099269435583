const hideCardNumber = (number) => {
	let result = '';
	const numLen = number.toString().length;

	for (let i = 0; i < numLen; i++) {
		if (i < numLen - 4) result += '*';
		if (i === 3 || i === 7 || i === 11) result += ' ';
	}

	const lastFour = number
		.toString()
		.split('')
		.splice(-number.toString().length + number.toString().length - 4);

	result += lastFour.reduce((acc, curr) => (acc += curr));

	return result;
};

export default hideCardNumber;
