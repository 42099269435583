import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LangContext } from '../../contexts/language/LangContext';
import firstUp from '../../controllers/firstUp';
import DiscipleIcon from '../../components/Assets/DiscipleIcon';
import MentorIcon from '../../components/Assets/MentorIcon';
import Owl from '../../components/Assets/Owl';

const RolesPresentation = () => {
  const {
    lang: {
      TX00017: mentor,
      TX00018: disciple,
      TX00019: mentorText,
      TX00020: discipleText,
      TX00049: more,
      TX00050: educationLang,
      TX00051: mentoringLang,
    },
  } = useContext(LangContext);

  return (
    <section className='roles'>
      <div className='roles-mentor'>
        <MentorIcon white className='roles-mentor-icon' />
        <h3 className='fs-xl clr-white'>{firstUp(mentor.content)}</h3>
        <p className='fs-xs clr-white'>{firstUp(mentorText.content)}</p>
        <Link to={`/role/${mentoringLang.content}`} className='fs-xs'>
          {more.content}
        </Link>
      </div>
      <Owl className='roles-owl' />
      <div className='roles-disciple'>
        <DiscipleIcon white className='roles-disciple-icon' />
        <h3 className='fs-xl clr-white'>{firstUp(disciple.content)}</h3>
        <p className='fs-xs clr-white'>{firstUp(discipleText.content)}</p>
        <Link to={`/role/${educationLang.content}`} className='fs-xs'>
          {more.content}
        </Link>
      </div>
    </section>
  );
};

export default RolesPresentation;
