import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../contexts/auth/AuthContext';

const Bill = ({ className, from }) => {
	const {
		wallet: { cre },
	} = useContext(AuthContext);

	if (from) {
		const { title, cre } = from;

		return (
			<span>
				<div className={`cre-bill ${className}`}>
					<p className='fs-xxxl fw-m'>{cre}</p>
				</div>
				<p className='cre-bill-title'>{title}</p>
			</span>
		);
	}

	return (
		<div className={`cre-bill ${className}`}>
			<p className='fs-xxxl fw-m'>{cre}</p>
		</div>
	);
};

Bill.propTypes = {
	from: PropTypes.shape({
		cre: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
	}),
	className: PropTypes.string,
};

Bill.defaultProps = {
	className: '',
};

export default Bill;
