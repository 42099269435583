import React, { useContext, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import Button from '../Generic/Button';
import Text from '../Generic/Text';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';
import hideCardNumber from '../../controllers/hideCardNumber';

const Creditcards = ({ className, setShowModal }) => {
	const creditcards = [
		{
			bank: 'ING CENTRAL',
			number: 1234567891234567,
			expMonth: 12,
			expYear: 24,
			fullName: 'John Doe',
		},
		{
			bank: 'BRD',
			number: 1234567891234567,
			expMonth: 12,
			expYear: 24,
			fullName: 'John Doe',
		},
	];

	// this will come from wallet context
	const [selectedCard, setSelectedCard] = useState(
		creditcards ? creditcards[0] : localStorage.selectedCard
	);
	const cardsListRef = useRef(null);
	const { plusIcon } = useContext(AssetContext);

	const {
		lang: {
			TX00282: { content: addLang },
			// TX00283: { content: legalLang },
			// TX00284: { content: termsOSLang },
			// TX00285: { content: gdprLang },
			// TX00286: { content: contractLang },
			// TX00287: { content: bankAccountLang },
			// TX00288: { content: fullNameLang },
			// TX00289: { content: countryLang },
			// TX00290: { content: addressLang },
			// TX00291: { content: accountNumberLang },
			// TX00292: { content: digitalSignatureLang },
			// TX00293: { content: thisWillHelpLang },
			// TX00295: { content: uploadLang },
			// TX00296: { content: drawLang },
		},
	} = useContext(LangContext);

	// this is gonna come from wallet context

	const { bank, number, expMonth, expYear, fullName } = selectedCard;

	const multiple = creditcards.length > 1 ? 'multiple' : '';

	const handleSelect = (e, index) => {
		e.stopPropagation();
		setSelectedCard(creditcards[index]);
		cardsListRef.current.classList.add('hide');
	};

	const handleClick = (e) => (
		e.stopPropagation(),
		cardsListRef.current.className.includes('hide') &&
			cardsListRef.current.classList.remove('hide')
	);

	return (
		<div
			className={`creditcards ${className} ${multiple}`}
			onClick={() => cardsListRef.current.classList.remove('open')}
		>
			<div className={`creditcard`}>
				<h5 className='creditcard-bank fs-m'>{bank}</h5>
				<p className='creditcard-number fs-m'>{hideCardNumber(number)}</p>
				<p className='creditcard-expire'>
					{expMonth}/{expYear}
				</p>
				<p className='creditcard-holder'>{fullName}</p>
			</div>
			<Button className='btn-add' onClick={() => setShowModal(true)}>
				<img src={plusIcon} alt='plus' />
				<p>{addLang}</p>
			</Button>
			{creditcards.length > 1 && (
				<Button className='dots-menu' onClick={(e) => handleClick(e)}>
					<div className='dots' />
					<ul className='hide' ref={cardsListRef}>
						{creditcards.map(
							(card, index) =>
								card.bank !== bank && (
									<Text
										li
										key={'cardKey_' + index}
										onClick={(e) => handleSelect(e, index)}
									>
										{card.bank}
									</Text>
								)
						)}
					</ul>
				</Button>
			)}
		</div>
	);
};

Creditcards.propTypes = {
	setShowModal: PropTypes.func,
	className: PropTypes.string,
};

Creditcards.defaultProps = {
	className: '',
};

export default Creditcards;
