import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RoleStep from '../../components/Role/RoleStep';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';

const RoleSteps = ({ role }) => {
	const { userMale, creBill } = useContext(AssetContext);

	const {
		lang: {
			TX00165: JoinPlatformLang,
			TX00169: OnceCreateAccountLang,
			TX00170: ThisWillHelpOthersLang,

			TX00166: AcceptLegalLang,
			TX00175: ConnectCardLang,
			TX00172: AgreeTermsLang,
			TX00173: AgreeGDPRLang,
			TX00174: SignContractLang,

			TX00167: BecomeCommunityLang,
			TX00181: AddInterestLang,
			TX00182: EvaluateYourselfLang,
			TX00183: DefineGoalLang,
			TX00184: SetUpPlanLang,
			TX00185: UncoverMotivationLang,
			TX00176: AddDisciplineLang,
			TX00177: DescribeYourselfLang,
			TX00178: AttachDegreeLang,
			TX00179: DetermineChargesLang,
			TX00180: SetAvailabilityLang,

			TX00168: MakeMostOfKnowledge,
			TX00189: InvestInYourselfLang,
			TX00190: DecideUseCreLang,
			TX00191: GoExtraMileLang,
			TX00186: BasedOnActivityLang,
			TX00187: EveryMonthLang,
			TX00188: MoreThan1000CreLang,
		},
	} = useContext(LangContext);

	const stepsDisciple = [
		{
			icon: userMale,
			title: JoinPlatformLang.content,
			text1: OnceCreateAccountLang.content,
			text2: ThisWillHelpOthersLang.content,
		},
		{
			icon: 'logo',
			title: AcceptLegalLang.content,
			text2: [
				ConnectCardLang.content,
				AgreeTermsLang.content,
				AgreeGDPRLang.content,
			],
		},
		{
			icon: 'disciple',
			title: BecomeCommunityLang.content,
			text1: AddDisciplineLang.content,
			text2: [
				EvaluateYourselfLang.content,
				DefineGoalLang.content,
				SetUpPlanLang.content,
				UncoverMotivationLang.content,
			],
		},
		{
			icon: creBill,
			title: MakeMostOfKnowledge.content,
			text2: [
				InvestInYourselfLang.content,
				DecideUseCreLang.content,
				GoExtraMileLang.content,
			],
		},
	];

	const stepsMentor = [
		{
			icon: userMale,
			title: JoinPlatformLang.content,
			text1: OnceCreateAccountLang.content,
			text2: ThisWillHelpOthersLang.content,
		},
		{
			icon: 'logo',
			title: AcceptLegalLang.content,
			text2: [
				ConnectCardLang.content,
				AgreeTermsLang.content,
				AgreeGDPRLang.content,
				SignContractLang.content,
			],
		},
		{
			icon: 'mentor',
			title: BecomeCommunityLang.content,
			text1: AddInterestLang.content,
			text2: [
				DescribeYourselfLang.content,
				AttachDegreeLang.content,
				DetermineChargesLang.content,
				SetAvailabilityLang.content,
			],
		},
		{
			icon: creBill,
			title: MakeMostOfKnowledge.content,
			text2: [
				BasedOnActivityLang.content,
				EveryMonthLang.content,
				MoreThan1000CreLang.content,
			],
		},
	];

	return (
		<section className='role-steps'>
			{role === 'education'
				? stepsDisciple.map((step, index) => (
						<RoleStep
							key={'role_step_' + index}
							content={step}
							right={index % 2 === 1 && true}
							mentor={role === 'education' ? false : true}
							disciple={role === 'mentoring' ? false : true}
						/>
				  ))
				: stepsMentor.map((step, index) => (
						<RoleStep
							key={'role_step_' + index}
							content={step}
							right={index % 2 === 1 && true}
							mentor={role === 'education' ? false : true}
							disciple={role === 'mentoring' ? false : true}
						/>
				  ))}
		</section>
	);
};

RoleSteps.propTypes = {
	role: PropTypes.oneOf(['mentoring', 'education', '']).isRequired,
};

export default RoleSteps;
