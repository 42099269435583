import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Generic/Input';

const FormFields = ({ fields, handleChange }) => {
	return (
		<>
			{fields.map((field, index) => (
				<Input
					id={field.name}
					name={field.name}
					type={field.type}
					key={'accountInfo_' + index}
					content={{ label: field.label, list: field.list }}
					value={field.text}
					onChange={handleChange}
					column
				/>
			))}
		</>
	);
};

FormFields.propTypes = {
	fields: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			list: PropTypes.arrayOf(PropTypes.string),
			name: PropTypes.string.isRequired,
		})
	).isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default FormFields;
