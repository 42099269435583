import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import LangState from './contexts/language/LangContext';
import AssetState from './contexts/asset/AssetContext';
import LocState from './contexts/location/LocContext';
import AuthState from './contexts/auth/AuthContext';
import FilterState from './contexts/filter/FilterContext';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AssetState>
        <LangState>
          <AuthState>
            <LocState>
              <FilterState>
                <App />
              </FilterState>
            </LocState>
          </AuthState>
        </LangState>
      </AssetState>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
