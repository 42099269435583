import React, { useContext } from 'react';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';
import PresentationCard from '../../components/Generic/PresentationCard';

const Activities = () => {
  const { sessionIcon, webinarIcon, liveIcon, classIcon } =
    useContext(AssetContext);

  const {
    lang: {
      TX00005: sessionsLang,
      TX00009: text1sessions,
      TX00010: text2sessions,

      TX00006: classesLang,
      TX00011: text1classes,
      TX00012: text2classes,

      TX00007: webinarsLang,
      TX00013: text1webinars,
      TX00014: text2webinars,

      TX00008: liveLang,
      TX00015: text1live,
      TX00016: text2live,
    },
  } = useContext(LangContext);

  const content = [
    {
      icon: sessionIcon,
      title: sessionsLang.content,
      text1: text1sessions.content,
      text2: text2sessions.content,
    },
    {
      icon: classIcon,
      title: classesLang.content,
      text1: text1classes.content,
      text2: text2classes.content,
    },
    {
      icon: webinarIcon,
      title: webinarsLang.content,
      text1: text1webinars.content,
      text2: text2webinars.content,
    },
    {
      icon: liveIcon,
      title: liveLang.content,
      text1: text1live.content,
      text2: text2live.content,
    },
  ];

  return (
    <section className='activities'>
      {content.map((entry) => (
        <PresentationCard
          key={entry.title}
          content={{
            icon: entry.icon,
            title: entry.title,
            text1: entry.text1,
            text2: entry.text2,
          }}
        />
      ))}
    </section>
  );
};

export default Activities;
