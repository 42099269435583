import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CategorySlide from '../../components/Category/CategorySlide';
import { LangContext } from '../../contexts/language/LangContext';

const CategorySlides = ({ className }) => {
  const {
    lang: {
      TX00022: languagesLang,
      TX00023: sciencesLang,
      TX00024: talentsLang,
    },
  } = useContext(LangContext);

  return (
    <section className={`category-slides ${className}`}>
      <div className='category-slides-content'>
        <CategorySlide
          className='category-slide'
          category={languagesLang.content}
        />
        <CategorySlide
          className='category-slide'
          category={sciencesLang.content}
        />
        <CategorySlide
          className='category-slide'
          category={talentsLang.content}
        />
      </div>
    </section>
  );
};

CategorySlides.propTypes = {
  className: PropTypes.string,
};
CategorySlides.defaultProps = {
  className: '',
};

export default CategorySlides;
