import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Owl from '../Assets/Owl';
import { LangContext } from '../../contexts/language/LangContext';
import firstUp from '../../controllers/firstUp';
import Text from './Text';

const OwlTeacher = ({ className, content }) => {
	const owlRef = useRef(null);

	const {
		lang: { TX00262: letMeTeachYou },
	} = useContext(LangContext);

	const handleOwlTeacher = () => owlRef.current.classList.toggle('open');

	return (
		<div
			ref={owlRef}
			className={`owl-teacher ${className}`}
			data-text-owl={firstUp(letMeTeachYou.content)}
			onClick={() => handleOwlTeacher()}
		>
			<Owl />
			<div className={`owl-teacher-content ${className}`}>
				<h5 className='clr-white'>{firstUp(content.title)}</h5>
				{content.list.map((text, index) => (
					<Text key={'owlTeacherKey_' + index} className='fs-xs clr-white'>
						{text}
					</Text>
				))}
			</div>
		</div>
	);
};

OwlTeacher.propTypes = {
	className: PropTypes.string,
	content: PropTypes.shape({
		title: PropTypes.string.isRequired,
		list: PropTypes.arrayOf(PropTypes.string).isRequired,
	}).isRequired,
};

OwlTeacher.defaultProps = {
	className: '',
};

export default OwlTeacher;
