import React, { useContext } from 'react';
import FilterItem from '../../components/Filter/FilterItem';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';
import TYPES from './filterTypes';
import { FilterContext } from '../../contexts/filter/FilterContext';
import { LocContext } from '../../contexts/location/LocContext';

const Filter = () => {
  const {
    lang: { TX00046: exploreLang },
  } = useContext(LangContext);

  const { filterIcon, closeRed } = useContext(AssetContext);

  const {
    filterDefaults: { exploreFilters },
    isFilterEmpty,
    clearFilter,

    // interval: { checkedInterval },
  } = useContext(FilterContext);

  const { location } = useContext(LocContext);

  return (
    <aside className='filter'>
      {!isFilterEmpty() ? (
        <FilterItem
          content={{
            header: 'clear all filters',
            icon: closeRed,
            type: TYPES.TITLE_CLEAR,
          }}
          onClick={clearFilter}
        />
      ) : (
        <FilterItem
          content={{ header: 'filter', icon: filterIcon, type: TYPES.TITLE }}
        />
      )}
      {location === exploreLang.content &&
        exploreFilters.map((filter, index) => (
          <FilterItem key={'filter_' + index} content={filter} />
        ))}
    </aside>
  );
};

export default Filter;
