import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Text from '../Generic/Text';
import firstUp from '../../controllers/firstUp';
import handleTooltip from '../../controllers/handleTooltip';
import To from './To';

const NavActiveLink = ({ className, content }) => {
  const tooltipRef = useRef(null);

  return (
    <li className={`nav-active-link ${className}`}>
      {content.icon && (
        <To to={`/${content.link}`}>
          <img
            src={content.icon}
            alt={'link-' + content.tooltip}
            onMouseEnter={() => handleTooltip(tooltipRef)}
            onMouseLeave={() => handleTooltip(tooltipRef)}
          />
          {content.tooltip && (
            <Text textRef={tooltipRef} className='tooltip'>
              {firstUp(content.tooltip)}
            </Text>
          )}
        </To>
      )}
    </li>
  );
};

NavActiveLink.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object,
};

NavActiveLink.defaultProps = {
  className: '',
};

export default NavActiveLink;
