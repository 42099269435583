import React from 'react';
import PropTypes from 'prop-types';

const ColorSpot = ({ left, bgColor }) => {
  if (left) {
    return (
      <svg
        className={`svg-color-spot color-spot-${bgColor}`}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 309.04 299.49'>
        <path d='M50.44,259.34C-24.2,193.78-11.29,25.83,58.24,4.2c79.51-24.73,80.49,66.35,122.25,103.32,53,46.92,132.58,67.72,128.39,128.2C304.63,296.91,131.72,330.74,50.44,259.34Z' />
      </svg>
    );
  }

  return (
    <svg
      className={`svg-color-spot color-spot-${bgColor}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 351.45 286.88'>
      <path d='M2.86,46.77c3.9-12.12,11.86-21.08,21.08-28.64a81.94,81.94,0,0,1,14-8.83C62-2.74,89.16-1.93,117.14,5.16c7.46,1.88,15,4.22,22.53,6.88,37.19,13.19,71.46,32.54,107.87,47.21,5.23,2.07,10.53,4,15.9,5.81,4.93,1.15,10,2.14,15.21,3.67,8.09,1.13,14.76,2.06,26.64,1,31.19-2.18,54.37,33.81,43.4,60.73A93.64,93.64,0,0,1,333.45,156,152.25,152.25,0,0,1,313,175.68c-4.64,4.24-9.49,8.35-14.48,12.38s-10.11,8-15.29,11.94c-30.93,24.38-45,68.68-81.61,83.18C165.18,297.61,116,267.85,97.23,229,86.3,207,87,180.16,77.87,156.38a107.78,107.78,0,0,0-8.12-15.55c-9.43-14.81-23.07-27.25-43.64-35.7C8.91,98.48-1.39,78.86.15,59.07A48.14,48.14,0,0,1,2.86,46.77Z' />
    </svg>
  );
};

ColorSpot.propTypes = {
  left: PropTypes.bool,
  bgColor: PropTypes.string,
};

ColorSpot.defaultProps = {
  left: false,
  bgColor: '',
};

export default ColorSpot;
