import React, { useContext } from 'react';
import { LangContext } from '../../contexts/language/LangContext';
import Text from './Text';
import firstUp from '../../controllers/firstUp';

const WICShowcaseText = () => {
  const {
    lang: { TX00162: showcaseParagraph_1, TX00163: showcaseParagraph_2 },
  } = useContext(LangContext);

  return (
    <section className='wic-showcase-text'>
      <h3 className='fs-huge'>WebinClass</h3>
      <Text text={firstUp(showcaseParagraph_1.content)} className='fw-b fs-m' />
      <Text text={firstUp(showcaseParagraph_2.content)} className='fs-xs' />
    </section>
  );
};

export default WICShowcaseText;
