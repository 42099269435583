import firstUp from './firstUp';

const parseListToString = (list) =>
  list.length > 1
    ? list.reduce(
        (acc, elem, index) =>
          (acc =
            index === 1
              ? firstUp(acc) + `, ${firstUp(elem)}`
              : acc + `, ${firstUp(elem)}`)
      )
    : firstUp(list[0]);

export default parseListToString;
