// CL100001

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import firstUp from '../../controllers/firstUp';

const Button = ({
	text,
	to,
	className,
	primary,
	secondary,
	dark,
	full,
	onClick,
	submit,
	children,
}) => {
	const btnRef = useRef(null);

	const handleMsD = () => btnRef.current.classList.add('clicked');

	const handleMsU = () => btnRef.current.classList.remove('clicked');

	if (to) {
		return (
			<Link
				ref={btnRef}
				className={`button fs-xs fw-m ${className} ${primary && 'primary'} ${
					secondary && 'secondary'
				} ${full && 'full'} ${dark && 'dark'}`}
				to={to}
				onMouseDown={() => handleMsD()}
				onMouseUp={(e) => (handleMsU(), onClick(e))}
			>
				{firstUp(text)}
			</Link>
		);
	}

	if (submit)
		return (
			<input
				ref={btnRef}
				type='submit'
				value={firstUp(text)}
				className={`button fs-xs fw-m ${className} ${primary && 'primary'} ${
					secondary && 'secondary'
				} ${full && 'full'} ${dark && 'dark'}`}
				onMouseDown={() => handleMsD()}
				onMouseUp={(e) => (handleMsU(), onClick(e))}
			/>
		);

	return (
		<button
			ref={btnRef}
			value={firstUp(text)}
			className={`button fs-xs fw-m ${className} ${primary && 'primary'} ${
				secondary && 'secondary'
			} ${full && 'full'} ${dark && 'dark'}`}
			onMouseDown={() => handleMsD()}
			onMouseUp={(e) => (handleMsU(), onClick(e))}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	text: PropTypes.string,
	to: PropTypes.string,
	className: PropTypes.string,
	primary: PropTypes.bool,
	secondary: PropTypes.bool,
	full: PropTypes.bool,
	onClick: PropTypes.func,
	dark: PropTypes.bool,
	submit: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
};

Button.defaultProps = {
	text: '',
	className: '',
	primary: false,
	secondary: false,
	full: false,
	dark: false,
	submit: false,
	onClick: () => {},
};

export default Button;
