import React, { useContext } from 'react';
import Box from '../../../components/Generic/Box';
// import Text from '../../../components/Generic/Text';
// import PropTypes from 'prop-types'
import { LangContext } from '../../../contexts/language/LangContext';
// import firstUp from '../../../controllers/firstUp';
import Bill from '../../../components/Wallet/Bill';
import AvIndicator from '../../../components/Indicators/AvIndicator';
import { AssetContext } from '../../../contexts/asset/AssetContext';

const WalletCre = () => {
	const {
		lang: {
			TX00272: { content: sharingLang },
			TX00273: { content: bufferLang },
			TX00274: { content: withdrawLang },
		},
	} = useContext(LangContext);

	const { walletGraph } = useContext(AssetContext);

	const content = {
		from: 'earned',
		month: 'march',
		av: 120,
		total: 20.753,
		percentage: 100,
	};

	return (
		<section className='wallet-cre mw-70'>
			<Box className='wallet-cre-bills'>
				<Bill />
				<Bill from={{ cre: 120, title: sharingLang }} className='cre-bill-small' />
				<Bill from={{ cre: 120, title: bufferLang }} className='cre-bill-small' />
				<Bill from={{ cre: 120, title: withdrawLang }} className='cre-bill-small' />
			</Box>
			<Box className='wallet-cre-stats'>
				<AvIndicator content={content} />
				<AvIndicator content={content} />
				<img src={walletGraph} alt='wallet_graph' />
			</Box>
		</section>
	);
};

// Wallet.propTypes = {};

export default WalletCre;
