// CHANGE ALL KEYS TO TEXT IDS

// eslint-disable-next-line
module.exports = {
	LOCATIONS: {
		HOME: 'home',
		EXPLORE: 'explore',
		ACTIVITY: 'activity',
		CATEGORY: 'category',
		ROLE: 'role',
		ACCOUNT: 'account',
		JOIN: 'join',
		PROFILE: 'profile',
	},
	SET_LOCATION: 'SET_LOCATION',
	SET_SIDE: 'SET_SIDE',
	SET_PATH: 'SET_PATH',
};
