import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';
import ActivityCard from '../../components/Generic/ActivityCard';

const ActivityCards = ({ className, activity }) => {
  const {
    lang: {
      TX00074: PilotLang,
      TX00075: OneToOneLang,
      TX00111: OneToManyLang,
      TX00451: RecurrentLang,
      TX00077: CertificationLang,

      TX00078: SessionPilotText_1,
      TX00079: SessionPilotText_2,
      TX00080: SessionOneToOneText_1,
      TX00081: SessionRecurrentText_1,
      TX00082: SessionCertificationText_1,

      TX00112: ClassOneToManyText_1,
      TX00113: ClassRecurrentText_1,

      TX00135: WebinarOneToManyText_1,

      TX00150: LiveOneToManyText_1,
    },
  } = useContext(LangContext);

  const contentSession = [
    {
      header: PilotLang.content,
      subheader: SessionPilotText_1.content,
      paragraph: SessionPilotText_2.content,
    },
    {
      header: OneToOneLang.content,
      subheader: SessionOneToOneText_1.content,
    },
    {
      header: RecurrentLang.content,
      subheader: SessionRecurrentText_1.content,
    },
    {
      header: CertificationLang.content,
      subheader: SessionCertificationText_1.content,
    },
  ];

  const contentClass = [
    {
      header: OneToManyLang.content,
      subheader: ClassOneToManyText_1.content,
    },
    {
      header: RecurrentLang.content,
      subheader: ClassRecurrentText_1.content,
    },
  ];

  const contentWebinar = [
    {
      header: OneToManyLang.content,
      subheader: WebinarOneToManyText_1.content,
    },
  ];

  const contentLive = [
    {
      header: OneToManyLang.content,
      subheader: LiveOneToManyText_1.content,
    },
  ];

  const activitiesContent = {
    session: contentSession,
    class: contentClass,
    webinar: contentWebinar,
    live: contentLive,
  };

  return (
    <section
      className={`container activity-cards activity-cards-${activity} ${className}`}>
      {activitiesContent[`${activity}`].map((entry, index) => (
        <ActivityCard key={'activityCard_' + index} content={entry} />
      ))}
    </section>
  );
};

ActivityCards.propTypes = {
  className: PropTypes.string,
  activity: PropTypes.string.isRequired,
};

ActivityCards.defaultProps = {
  className: '',
};

export default ActivityCards;
