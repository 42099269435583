import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AssetContext } from '../../contexts/asset/AssetContext';

const Box = ({ children, className, refProp, onClick, withClose }) => {
	const { closeRed } = useContext(AssetContext);

	return (
		<div ref={refProp} className={`box ${className}`} onClick={(e) => onClick(e)}>
			{withClose && (
				<img src={closeRed} alt='close-modal' onClick={() => withClose()} />
			)}
			{children}
		</div>
	);
};

Box.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
		PropTypes.array,
	]).isRequired,
	className: PropTypes.string,
	refProp: PropTypes.object,
	onClick: PropTypes.func,
	withClose: PropTypes.func,
};

Box.defaultProps = {
	className: '',
	onClick: () => {},
	withClose: undefined,
};

export default Box;
