import {
  SET_SELECTED,
  REMOVE_SELECTED,
  SET_CHECKBOX,
  SET_RADIO,
  SET_INTERVAL,
  CLEAR_FILTER,
  SET_ORIENTATION,
  SET_PREFERENCES,
} from './types';

const filterReducer = (state, action) => {
  switch (action.type) {
    case SET_SELECTED:
      return {
        ...state,
        selected: !state.selected.some((item) => item === action.payload) && [
          ...state.selected,
          action.payload,
        ],
      };
    case SET_CHECKBOX:
      return {
        ...state,
        checkedCheckbox: { ...state.checkedCheckbox, ...action.payload },
      };
    case SET_RADIO:
      return {
        ...state,
        checkedRadio: { ...state.checkedRadio, ...action.payload },
      };
    case SET_INTERVAL:
      return {
        ...state,
        checkedInterval: { ...state.checkedInterval, ...action.payload },
      };
    case CLEAR_FILTER:
      return action.payload;
    case REMOVE_SELECTED:
      return {
        ...state,
        selected: state.selected.filter((entry) => entry !== action.payload),
      };
    case SET_ORIENTATION:
      return { ...state, orientation: action.payload };
    case SET_PREFERENCES:
      return {
        ...state,
        preferences: { ...state.preferences, ...action.payload },
      };

    default:
      return state;
  }
};

export default filterReducer;
