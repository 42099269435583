import React from 'react';
import PropTypes from 'prop-types';

const InkBackdrop = ({ className }) => {
  return (
    <div className='ink-backdrop'>
      <svg
        id='svg-ink-backdrop'
        className={`${className}`}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1280 589.33'>
        <path
          d='M114.49,496.48c-12.86,8-25.38,16.76-38.76,23.52C52.54,531.68,25.15,541.64,0,541.15V0H1280V339.7a483.92,483.92,0,0,0-135-55c-11.84-2.84-81.6-19.05-125.52-4.09a167,167,0,0,0-38.73,19.59c-11.44,8.19-14.61,13.17-20.5,18.11-25.38,21.25-59.94,12.51-108.25,15.24-26,1.47-53,6.4-71.78,27.76-56.3,64,28.55,150.38-66,203.1-79.87,44.52-219.57,22.1-299.25-10-80.23-32.29-166.89-93.23-256.7-76.79C142.29,480.51,128.19,488,114.49,496.48Z'
          fill='#23b89a'
        />
      </svg>
    </div>
  );
};

InkBackdrop.propTypes = {
  className: PropTypes.string,
};

InkBackdrop.defaultProps = {
  className: '',
};

export default InkBackdrop;
