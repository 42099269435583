import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '../Generic/Box';
import Category from '../../pages/Category/Category';

const CategorySlide = ({ className, category }) => {
  const slideRef = useRef(null);

  useEffect(() => {
    slideRef && slideRef.current.classList.add('slide');

    // eslint-disable-next-line
  }, []);

  return (
    <Box refProp={slideRef} className={`category-slide ${className}`}>
      <Category white={false} categoryProp={category} />
    </Box>
  );
};

CategorySlide.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string.isRequired,
};

CategorySlide.defaultProps = {
  className: '',
};

export default CategorySlide;
