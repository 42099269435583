import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '../Generic/Box';
import ArrowIcon from '../Assets/ArrowIcon';
import Text from '../Generic/Text';
import firstUp from '../../controllers/firstUp';
import { LangContext } from '../../contexts/language/LangContext';
import { FilterContext } from '../../contexts/filter/FilterContext';
import Input from '../Generic/Input';
import FilterMore from './FilterMore';

const FilterRadio = ({ header, list, id }) => {
  const moreRef = useRef(null);
  list = list ?? [];

  const {
    lang: { TX00049: moreLang },
  } = useContext(LangContext);

  const {
    radio: { checkedRadio, setCheckedRadio },
    removeSelected,
    setSelected,
    isFilterTracked,
  } = useContext(FilterContext);

  const handleChange = (e) => {
    const filter = e.target.name;
    const value = e.target.value;
    const filterIsSelected = checkedRadio[`${filter}`] === value;

    isFilterTracked(filter)
      ? filterIsSelected
        ? (removeSelected(value), setCheckedRadio({ [filter]: '' }))
        : (removeSelected(checkedRadio[`${filter}`]),
          setSelected(value),
          setCheckedRadio({ [filter]: value }))
      : setCheckedRadio({ [filter]: filterIsSelected ? '' : value });
  };

  return (
    <Box className='filter-item filter-item-radio fs-xs'>
      <div
        className='filter-title'
        onClick={(e) => e.target.classList.toggle('filter-close')}>
        <Text>{firstUp(header)}</Text>
        <ArrowIcon />
      </div>
      <form className='filter-body'>
        {list.map(
          (item, index) =>
            index < 5 && (
              <Input
                key={'key_' + id + index}
                type='radio'
                id={header + index}
                value={item}
                name={id}
                checked={checkedRadio[`${id}`] === item}
                onClick={(e) => handleChange(e)}
              />
            )
        )}
        {list.length > 7 && (
          <input
            type='button'
            value={moreLang.content}
            onClick={() => moreRef.current.classList.add('filter-more-open')}
          />
        )}
      </form>
      <FilterMore
        type='radio'
        id={header}
        name={id}
        list={list}
        moreRef={moreRef}
        handleChange={handleChange}
      />
    </Box>
  );
};

FilterRadio.propTypes = {
  header: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
};

export default FilterRadio;
