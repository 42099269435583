import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AssetContext } from '../../contexts/asset/AssetContext';

const UserPhoto = ({ photo, type, male, rank, role, edit, border }) => {
  const {
    userFemale,
    userMale,
    discipleRank_1,
    discipleRank_2,
    discipleRank_3,
    discipleRank_4,
    discipleRank_5,
    discipleRank_6,
    mentorRank_1,
    mentorRank_2,
    mentorRank_3,
    mentorRank_4,
    mentorRank_5,
    mentorRank_6,
  } = useContext(AssetContext);

  const discipleRanks = [
    discipleRank_1,
    discipleRank_2,
    discipleRank_3,
    discipleRank_4,
    discipleRank_5,
    discipleRank_6,
  ];
  const mentorRanks = [
    mentorRank_1,
    mentorRank_2,
    mentorRank_3,
    mentorRank_4,
    mentorRank_5,
    mentorRank_6,
  ];

  return (
    <span className={`user-photo ${type} ${border && 'user-photo-border'}`}>
      {role && rank && (
        <img
          src={
            role === 'disciple'
              ? discipleRanks[rank - 1]
              : mentorRanks[rank - 1]
          }
          alt='rank_img'
          className='user-photo-rank'
        />
      )}
      <img
        src={photo !== '' ? photo : male ? userMale : userFemale}
        alt='user'
        className='user-photo-img'
      />
      {edit && (
        <label htmlFor='edit-photo'>
          <button id='edit-photo' name='edit photo' value='edit photo' />
        </label>
      )}
    </span>
  );
};

UserPhoto.propTypes = {
  photo: PropTypes.string,
  type: PropTypes.string,
  male: PropTypes.bool,
  rank: PropTypes.number,
  role: PropTypes.string,
  edit: PropTypes.bool,
  border: PropTypes.bool,
};

UserPhoto.defaultProps = {
  type: 'm',
  male: false,
  rank: null,
  role: '',
  photo: '',
  edit: false,
  border: false,
};

export default UserPhoto;
