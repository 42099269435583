import english from './english';
import romanian from './romanian';
import german from './german';

const languages = {
  english,
  romanian,
  german,
};

export default languages;
