import React from 'react';
import PropTypes from 'prop-types';
import MentorIcon from './MentorIcon';
import DiscipleIcon from './DiscipleIcon';

const ActivityIcon = ({ activity, mentor, disciple, className }) => {
  return (
    <div className={`activity-icon activity-icon-${activity} ${className}`}>
      {mentor && <MentorIcon white activity={activity} />}
      {disciple && <DiscipleIcon white activity={activity} />}
    </div>
  );
};

ActivityIcon.propTypes = {
  activity: PropTypes.string.isRequired,
  mentor: PropTypes.bool,
  disciple: PropTypes.bool,
  className: PropTypes.string,
};

ActivityIcon.defaultProps = {
  className: '',
  mentor: false,
  disciple: false,
};

export default ActivityIcon;
