import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import UserPhoto from './UserPhoto';
import Text from './Text';
import { LangContext } from '../../contexts/language/LangContext';
import { AssetContext } from '../../contexts/asset/AssetContext';
import Box from './Box';
import firstUp from '../../controllers/firstUp';
import Button from './Button';
import Input from './Input';
import randNum from '../../controllers/randNum';
import { useHistory } from 'react-router-dom';
import RatingIndicator from '../Indicators/RatingIndicator';

const PortraitCard = ({ user, className }) => {
	let { id, photo, rating, fullName, creSession, discipline, level, disciples } =
		user;

	// TEMP ID
	id = randNum(1000);

	const {
		lang: {
			TX00055: creLang,
			TX00417: levelLang,
			TX00232: profileLang,
			TX00336: messageLang,
		},
	} = useContext(LangContext);

	const { discipleIcon } = useContext(AssetContext);

	const [favorite, setFavorite] = useState(false);

	const history = useHistory();

	const handlePress = (e) => {
		e.stopPropagation();
		const value = e.target.value;
		const message = firstUp(messageLang.content);

		value === message
			? history.push(`/messages`)
			: history.push(`/user_profile/${id}`, { user });
	};

	const handleFavorite = (e) => {
		e.stopPropagation();
		setFavorite(!favorite);
	};

	return (
		<Box className={`portrait-card ${className}`} onClick={(e) => handlePress(e)}>
			<Input
				id={'favorite-' + id}
				type='checkbox'
				checked={favorite}
				onChange={handleFavorite}
			/>
			<div className='portrait-card-title'>
				<UserPhoto photo={photo} />
				<h5 className='fs-s fw-m'>{fullName}</h5>
				<RatingIndicator size='m' rating={rating} showRating />
			</div>
			<div className='portrait-card-body'>
				<Text className='fs-xs'>
					{`${firstUp(discipline)}, ${firstUp(levelLang.content)} ${level}, `}
				</Text>
				<Text className='fs-xs' text={disciples} icon={discipleIcon} />
				<Text className='fs-xl' ss={creLang.content}>
					{creSession}
				</Text>
			</div>
			<div className='portrait-card-buttons'>
				<Button
					primary
					text={profileLang.content}
					onClick={(e) => handlePress(e)}
				/>
				<Button dark text={messageLang.content} onClick={(e) => handlePress(e)} />
			</div>
		</Box>
	);
};

PortraitCard.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string,
		photo: PropTypes.string,
		rating: PropTypes.number,
		fullName: PropTypes.string.isRequired,
		creSession: PropTypes.number.isRequired,
		discipline: PropTypes.string.isRequired,
		level: PropTypes.number.isRequired,
		disciples: PropTypes.number,
	}).isRequired,
	className: PropTypes.string,
};

PortraitCard.defaultProps = {
	className: '',
};

export default PortraitCard;
