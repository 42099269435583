const romanian = {
  TX00001: {
    content: 'Invata. Descopera. Transforma.',
    tooltip: '',
  },
  TX00002: {
    content: 'cea mai completă platformă de educație online.',
    tooltip: '',
  },
  TX00003: {
    content: 'procesul de învățare este o călătorie fără sfârșit.',
    tooltip: '',
  },
  TX00004: {
    content: 'cunoaște-ne mai bine',
    tooltip: '',
  },
  TX00005: {
    content: 'sesiune',
    tooltip: '',
  },
  TX00006: {
    content: 'clasă',
    tooltip: '',
  },
  TX00007: {
    content: 'webinar',
    tooltip: '',
  },
  TX00008: {
    content: 'live',
    tooltip: '',
  },
  TX00009: {
    content:
      'punctul de potrivire al destinației pe care îl tânjești și dorința montorului de a preda.',
    tooltip: '',
  },
  TX00010: {
    content:
      'sesiunile online one to one sunt cele mai eficiente atunci când încercăm să învățăm.',
    tooltip: '',
  },
  TX00011: {
    content:
      'adunați-vă împreună cu colegii care au același interes ca dvs. și descoperiți următorul nivel de învățare.',
    tooltip: '',
  },
  TX00012: {
    content:
      'definiți-vă stilul de învățare analizând o varietate de alte stiluri.',
    tooltip: '',
  },
  TX00013: {
    content:
      'varietate de subiecte, rezultatul experienței și revizuirea informațiilor de la mentor către voi toți.',
    tooltip: '',
  },
  TX00014: {
    content:
      'subiecte actualizate, fierbinți și largi, care vă vor lumina experiența',
    tooltip: '',
  },
  TX00015: {
    content:
      'un mentor. O ora. Mai multe întrebări. Găsiți răspunsurile și clarificați scenariile.',
    tooltip: '',
  },
  TX00016: {
    content: 'participați online la o conversație în timp real de oriunde.',
    tooltip: '',
  },
  TX00017: {
    content: 'mentor',
    tooltip: '',
  },
  TX00018: {
    content: 'discipol',
    tooltip: '',
  },
  TX00019: {
    content:
      'împărtășiți-vă cunoștințele în întreaga lume. Ajută-i pe ceilalți să-și atingă obiectivele. S-ar putea să vă întrebați cum să faceți acest lucru? Alăturați-vă comunității noastre ca mentor și oferiți altora acces la imperiul dvs. informațional. Fii ghidul educațional de care ai avut nevoie întotdeauna ca novice.',
    tooltip: '',
  },
  TX00020: {
    content:
      'ați experimentat vreodată o nepotrivire între un subiect de care erați pasionat și cel care vă învață subiectul respectiv? acum puteți alege cel mai potrivit mentor pentru dvs. Alegeți mentorul dvs. din comunitatea noastră diversă.',
    tooltip: '',
  },
  TX00021: {
    content: 'începe călătoria ta',
    tooltip: '',
  },
  TX00022: {
    content: 'limbi',
    tooltip: '',
  },
  TX00023: {
    content: 'științe',
    tooltip: '',
  },
  TX00024: {
    content: 'talente',
    tooltip: '',
  },
  TX00025: {
    content:
      'faceți din lumea voastră un loc mai bun și oferiți-vă cel mai bun cadou: înțelegerea celorlalți în propria lor limbă.',
    tooltip: '',
  },
  TX00026: {
    content:
      'du-te pe Lună și înapoi! Inventează-te și reinventează-te prin știință și îmbunătățește veselia în care trăiești.',
    tooltip: '',
  },
  TX00027: {
    content:
      'cel mai bun mod de a-ți exprima emoțiile, pasiunea și personalitatea este prin talentele tale.',
    tooltip: '',
  },
  TX00028: {
    content: 'bibliotecă',
    tooltip: 'Check our selection of documents',
  },
  TX00029: {
    content: 'certificări',
    tooltip: '',
  },
  TX00030: {
    content: 'academie',
    tooltip: '',
  },
  TX00031: {
    content:
      'bibliotecile sunt pietrele de temelie importante ale unei comunități sănătoase. Bibliotecile oferă oamenilor posibilitatea de a găsi locuri de muncă, de a explora cercetări, de a experimenta idei noi, de a se pierde în povești minunate.',
    tooltip: '',
  },
  TX00032: {
    content:
      'o certificare este o acreditare pe care o câștigați pentru a arăta că aveți abilități sau cunoștințe specifice. De obicei, acestea sunt legate de o ocupație, tehnologie sau industrie.',
    tooltip: '',
  },
  TX00033: {
    content:
      'academiile sunt școli independente, finanțate de stat, care își primesc finanțarea direct de la guvernul central, mai degrabă decât prin intermediul unei autorități locale. Ei își controlează propriul proces de admitere.',
    tooltip: '',
  },
  TX00034: {
    content: 'nu ne crede pe cuvânt',
    tooltip: '',
  },
  TX00035: {
    content: 'continuă cu',
    tooltip: '',
  },
  TX00036: {
    content: 'Facebook',
    tooltip: '',
  },
  TX00037: {
    content: 'Google',
    tooltip: '',
  },
  TX00038: {
    content: 'Apple',
    tooltip: '',
  },
  TX00039: {
    content: 'e-mail',
    tooltip: '',
  },
  TX00040: {
    content: 'parola',
    tooltip: '',
  },
  TX00041: {
    content: 'sau',
    tooltip: '',
  },
  TX00042: {
    content: 'ai deja un cont?',
    tooltip: '',
  },
  TX00043: {
    content: 'Inregistreaza-te',
    tooltip: '',
  },
  TX00044: {
    content: 'conectare',
    tooltip: '',
  },
  TX00045: {
    content: 'a avea probleme?',
    tooltip: '',
  },
  TX00046: {
    content: 'explora',
    tooltip: 'find a mentor',
  },
  TX00047: {
    content: 'pliant',
    tooltip: '',
  },
  TX00048: {
    content: 'a te alatura',
    tooltip: 'Enroll in our community',
  },
  TX00049: {
    content: 'Mai mult…',
    tooltip: 'More information',
  },
  TX00050: {
    content: 'educaţie',
    tooltip: '',
  },
  TX00051: {
    content: 'mentorat',
    tooltip: '',
  },
  TX00052: {
    content: 'comunicarea este o abilitate foarte importantă.',
    tooltip: '',
  },
  TX00053: {
    content: 'limba',
    tooltip: '',
  },
  TX00054: {
    content: 'mentori',
    tooltip: '',
  },
  TX00055: {
    content: 'CRE',
    tooltip: '',
  },
  TX00056: {
    content: 'av.',
    tooltip: '',
  },
  TX00057: {
    content: 'știința este sămânța progresului.',
    tooltip: '',
  },
  TX00058: {
    content: 'ştiinţă',
    tooltip: '',
  },
  TX00059: {
    content: 'expresia emoțiilor tale.',
    tooltip: '',
  },
  TX00060: {
    content: 'talent',
    tooltip: '',
  },
  TX00061: {
    content: 'sesiuni',
    tooltip: '',
  },
  TX00062: {
    content: 'cum începi?',
    tooltip: '',
  },
  TX00063: {
    content: 'creează un cont',
    tooltip: '',
  },
  TX00064: {
    content:
      'după ce creați un cont, nu uitați să adăugați toate informațiile dvs. în secțiunea de setări a contului.',
    tooltip: '',
  },
  TX00065: {
    content:
      'acest lucru îi va ajuta pe ceilalți să aibă încredere în tine mai ușor.',
    tooltip: '',
  },
  TX00066: {
    content: 'conectați-vă cardul de credit',
    tooltip: '',
  },
  TX00067: {
    content:
      'aceasta va fi utilizată pentru achiziționarea sau retragerea CRE.',
    tooltip: '',
  },
  TX00068: {
    content: '1 CRE = 1 EUR',
    tooltip: '',
  },
  TX00069: {
    content: 'face parte din comunitate',
    tooltip: '',
  },
  TX00070: {
    content: 'adaugă un interes ca discipol.',
    tooltip: '',
  },
  TX00071: {
    content: 'adăugați o disciplină ca mentor.',
    tooltip: '',
  },
  TX00072: {
    content:
      'aruncă o privire asupra selecției noastre vaste de mentori. Suntem siguri că veți putea găsi o potrivire perfectă.',
    tooltip: '',
  },
  TX00073: {
    content: 'ce este o sesiune?',
    tooltip: '',
  },
  TX00074: {
    content: 'pilot',
    tooltip: '',
  },
  TX00075: {
    content: 'unu la unu',
    tooltip: '',
  },
  TX00076: {
    content: 'recurent',
    tooltip: '',
  },
  TX00077: {
    content: 'certificare',
    tooltip: '',
  },
  TX00078: {
    content: 'prima sesiune cu mentorul dvs. este o sesiune pilot.',
    tooltip: '',
  },
  TX00079: {
    content:
      'notă: dacă, din întâmplare, nu vă bucurați de sesiunea dvs. de pilot, nu ezitați să solicitați rambursarea.',
    tooltip: '',
  },
  TX00080: {
    content: 'sesiune ad hoc cu mentorul dvs. fără nicio frecvență.',
    tooltip: '',
  },
  TX00081: {
    content:
      'o sesiune care va avea loc pe baza unei recurente săptămânale, în aceeași zi și la aceeași oră.',
    tooltip: '',
  },
  TX00082: {
    content: 'o sesiune parte a unui program de certificare.',
    tooltip: '',
  },
  TX00083: {
    content: 'sesiune ca discipol',
    tooltip: '',
  },
  TX00084: {
    content:
      'poate fi programat doar de un discipol și CRE va avea loc până la finalizarea sesiunii',
    tooltip: '',
  },
  TX00085: {
    content:
      'pentru sesiuni recurente, nu ezitați să verificați pachetele disponibile ale mentorului pentru a obține reduceri',
    tooltip: '',
  },
  TX00086: {
    content:
      'setarea programului se face din agenda dvs. proprie sau din intervalul de disponibilitate afișat în profilul unui mentor',
    tooltip: '',
  },
  TX00087: {
    content:
      'dacă primiți orice temă sau material, o veți putea găsi în bibliotecă',
    tooltip: '',
  },
  TX00088: {
    content: 'cu minimum 4 ore înainte de a începe:',
    tooltip: '',
  },
  TX00089: {
    content: '',
    tooltip: '',
  },
  TX00090: {
    content: '',
    tooltip: '',
  },
  TX00091: {
    content:
      'când a început o sesiune și sala de ședințe este configurată pe platformă, puteți accesa camera de pe cardul de sesiune din agendă',
    tooltip: '',
  },
  TX00092: {
    content:
      'odată ce sesiunea este terminată, o puteți marca ca terminată (după 72 de ore va fi marcată automat ca completă pentru dvs.)',
    tooltip: '',
  },
  TX00093: {
    content: 'recenziile vor ajuta întotdeauna:',
    tooltip: '',
  },
  TX00094: {
    content: '',
    tooltip: '',
  },
  TX00095: {
    content: '',
    tooltip: '',
  },
  TX00096: {
    content: '',
    tooltip: '',
  },
  TX00097: {
    content: 'sesiune ca mentor',
    tooltip: '',
  },
  TX00098: {
    content:
      'un mentor poate seta intervalul de disponibilitate pentru o anumită disciplină și un discipol poate programa o sesiune numai în această perioadă stabilită',
    tooltip: '',
  },
  TX00099: {
    content: 'după programarea unei sesiuni puteți adăuga la aceasta:',
    tooltip: '',
  },
  TX00100: {
    content: '',
    tooltip: '',
  },
  TX00101: {
    content: '',
    tooltip: '',
  },
  TX00102: {
    content: '',
    tooltip: '',
  },
  TX00103: {
    content:
      'puteți actualiza sau anula sesiunea oricând înainte de a începe, dar asigurați-vă că îi trimiteți un mesaj discipolului despre aceasta',
    tooltip: '',
  },
  TX00104: {
    content:
      'când a început o sesiune și sala de ședințe este configurată pe platformă, puteți accesa camera de pe cardul de sesiune din agendă',
    tooltip: '',
  },
  TX00105: {
    content: 'odată ce sesiunea este marcată ca co',
    tooltip: '',
  },
  TX00106: {
    content: 'review and feedback will always help:',
    tooltip: '',
  },
  TX00107: {
    content: '— you to commit to your disciples even more',
    tooltip: '',
  },
  TX00108: {
    content: '— your disciples to keep on track with their goals',
    tooltip: '',
  },
  TX00109: {
    content: 'classes',
    tooltip: '',
  },
  TX00110: {
    content: 'what is a class?',
    tooltip: '',
  },
  TX00111: {
    content: 'one to Many',
    tooltip: '',
  },
  TX00112: {
    content: 'ad hoc class with your mentor and peers without any frequency.',
    tooltip: '',
  },
  TX00113: {
    content:
      'a class that will take place based on a weekly reccurency, on the same day and at the same time.',
    tooltip: '',
  },
  TX00114: {
    content: 'class as a disciple',
    tooltip: '',
  },
  TX00115: {
    content: 'can be scheduled only by a mentor',
    tooltip: '',
  },
  TX00116: {
    content:
      'for recurrent classes feel free to check your mentor’s available packages in order to get discounts',
    tooltip: '',
  },
  TX00117: {
    content:
      'you can attend a class by accepting an invitation from your mentor or by joining through your agenda',
    tooltip: '',
  },
  TX00118: {
    content:
      'when enrolling to a class your CRE will be held until it is completed',
    tooltip: '',
  },
  TX00119: {
    content:
      'if you receive any homework or material you will be able to find it in the library',
    tooltip: '',
  },
  TX00120: {
    content: '— you can cancel the class',
    tooltip: '',
  },
  TX00121: {
    content: '— you can confirm or cancel the class if any changes occurred',
    tooltip: '',
  },
  TX00122: {
    content:
      'when a class has started and the meeting room is set up on the platform you can access the room from your class card in the agenda',
    tooltip: '',
  },
  TX00123: {
    content:
      'once the class is finished you can mark it as complete (after 72 hours it will automatically be marked as complete for you)  ',
    tooltip: '',
  },
  TX00124: {
    content: 'class as a mentor',
    tooltip: '',
  },
  TX00125: {
    content:
      'a mentor can set up classes and then invite or leave the seats empty for disciples to attend',
    tooltip: '',
  },
  TX00126: {
    content: 'when a class is scheduled you can add to it:',
    tooltip: '',
  },
  TX00127: {
    content:
      '— a title and topic for your disciples to understand what the class will be about',
    tooltip: '',
  },
  TX00128: {
    content: '— a file from the library',
    tooltip: '',
  },
  TX00129: {
    content: '— homework',
    tooltip: '',
  },
  TX00130: {
    content:
      'you can update or cancel the class any time before it starts, but make sure to message your disciples about it',
    tooltip: '',
  },
  TX00131: {
    content:
      'when a class started and the meeting room is set up on the platform you can access the room from your class card in the agenda',
    tooltip: '',
  },
  TX00132: {
    content:
      'once the class is marked as completed (done by disciples or automatically after 72 hours) CRE will be allocated to your wallet ',
    tooltip: '',
  },
  TX00133: {
    content: 'webinars',
    tooltip: '',
  },
  TX00134: {
    content: 'what is a webinar?',
    tooltip: '',
  },
  TX00135: {
    content:
      'a webinar allows a speaker from the hosting organization/company to share PowerPoint presentations, videos, web pages or other multimedia content with audiences that can be located anywhere. Webinars typically have audio and visual components.',
    tooltip: '',
  },
  TX00136: {
    content: 'webinar as a disciple',
    tooltip: '',
  },
  TX00137: {
    content:
      'you can attend a webinar by joining through your agenda and your CRE will be held until it is completed',
    tooltip: '',
  },
  TX00138: {
    content: '— you can cancel the webinar',
    tooltip: '',
  },
  TX00139: {
    content: '— you can confirm or cancel the webinar if any changes occurred',
    tooltip: '',
  },
  TX00140: {
    content:
      'when a webinar has started you can access the room from your webinar card in the agenda',
    tooltip: '',
  },
  TX00141: {
    content:
      'once the webinar is finished you can mark it as complete (after 72 hours it will automatically be marked as complete for you)  ',
    tooltip: '',
  },
  TX00142: {
    content: 'webinar as a mentor',
    tooltip: '',
  },
  TX00143: {
    content:
      'a mentor can set up webinars with a maximum number disciple attendance',
    tooltip: '',
  },
  TX00144: {
    content: 'when a webinar is scheduled you can add to it:',
    tooltip: '',
  },
  TX00145: {
    content:
      '— a title and topic for your disciples to understand what the webinar will be about',
    tooltip: '',
  },
  TX00146: {
    content: 'you can update or cancel the webinar any time',
    tooltip: '',
  },
  TX00147: {
    content:
      'when a webinar has started and the meeting room is set up on the platform you can access the room from your webinar card in the agenda',
    tooltip: '',
  },
  TX00148: {
    content:
      'once the webinar is marked as completed (done by disciples or automatically after 72 hours) CRE will be allocated to your wallet ',
    tooltip: '',
  },
  TX00149: {
    content: 'what is live mentoring?',
    tooltip: '',
  },
  TX00150: {
    content:
      'a live allows a speaker from the hosting organization/company to share PowerPoint presentations, videos, web pages or other multimedia content with audiences that can be located anywhere. Webinars typically have audio and visual components.',
    tooltip: '',
  },
  TX00151: {
    content: 'live as a disciple',
    tooltip: '',
  },
  TX00152: {
    content:
      'you can attend a live by joining through your agenda and your CRE will be held until it is completed',
    tooltip: '',
  },
  TX00153: {
    content: 'before it starts:',
    tooltip: '',
  },
  TX00154: {
    content: '— you can cancel the attendancy',
    tooltip: '',
  },
  TX00155: {
    content:
      'when a live has started you can access the room from your live card in the agenda',
    tooltip: '',
  },
  TX00156: {
    content: 'live as a mentor',
    tooltip: '',
  },
  TX00157: {
    content:
      'a mentor can set up a live mentoring activity with an unlimited number of disciples',
    tooltip: '',
  },
  TX00158: {
    content: 'you can cancel the live at any time before it starts',
    tooltip: '',
  },
  TX00159: {
    content:
      'when a live has started you can access the room from your live card in the agenda',
    tooltip: '',
  },
  TX00160: {
    content:
      'once the live is finished CRE will be allocated to your wallet  based on the attendancy list',
    tooltip: '',
  },
  TX00161: {
    content: 'WebinClass',
    tooltip: '',
  },
  TX00162: {
    content: 'the most complete online platform for education.',
    tooltip: '',
  },
  TX00163: {
    content:
      'easy and friendly designed, to cover all types of online mentoring and become a leader in this industry.',
    tooltip: '',
  },
  TX00164: {
    content: 'how it works',
    tooltip: '',
  },
  TX00165: {
    content: 'join our platform! Create an account',
    tooltip: '',
  },
  TX00166: {
    content: 'accept legal requirements',
    tooltip: '',
  },
  TX00167: {
    content: 'become part of the community',
    tooltip: '',
  },
  TX00168: {
    content: 'make the most of your knowledge',
    tooltip: '',
  },
  TX00169: {
    content:
      'once you create an account don’t forget to add all your info in the account settings section.',
    tooltip: '',
  },
  TX00170: {
    content: 'this will help others trust you more easily.',
    tooltip: '',
  },
  TX00171: {
    content:
      '— connect your credit card. This will be used for withdrawing CRE.',
    tooltip: '',
  },
  TX00172: {
    content: '— read and agree to the terms and conditions.',
    tooltip: '',
  },
  TX00173: {
    content: '— read and agree to the GDPR.',
    tooltip: '',
  },
  TX00174: {
    content: '— sign the contract.',
    tooltip: '',
  },
  TX00175: {
    content:
      '— connect your credit card. This will be used for purchasing CRE.',
    tooltip: '',
  },
  TX00176: {
    content:
      'add a discipline that defines you as a mentor, challenge yourself to become one of the best!',
    tooltip: '',
  },
  TX00177: {
    content: '— describe yourself as a mentor. Add all details and a video.',
    tooltip: '',
  },
  TX00178: {
    content: '— attach your degrees and show others how well prepared you are.',
    tooltip: '',
  },
  TX00179: {
    content: '— determine charges. Define the value of your work.',
    tooltip: '',
  },
  TX00180: {
    content: '— set up your availability.',
    tooltip: '',
  },
  TX00181: {
    content:
      'add an interest that defines you as a disciple, challenge yourself to become one of the best!',
    tooltip: '',
  },
  TX00182: {
    content: '— evaluate yourself and identify your initial level.',
    tooltip: '',
  },
  TX00183: {
    content: '— define your goal and be the driver of your journey.',
    tooltip: '',
  },
  TX00184: {
    content: '— set up a plan! Consistency and persistency lead to success. ',
    tooltip: '',
  },
  TX00185: {
    content: '— uncover your motivation! Be focused and engaged in your plan.',
    tooltip: '',
  },
  TX00186: {
    content: '— based on your activity CRE wil be allocated to your wallet.',
    tooltip: '',
  },
  TX00187: {
    content:
      '— every month, on the 1st, we deliver pay checks based on your previous month’s activity.',
    tooltip: '',
  },
  TX00188: {
    content: '— more than 1000 CRE can be withdrawn at any time.',
    tooltip: '',
  },
  TX00189: {
    content: '— invest in yourself by purchasing CRE inside your wallet.',
    tooltip: '',
  },
  TX00190: {
    content: '— decide when and how you want to use your CRE.',
    tooltip: '',
  },
  TX00191: {
    content:
      '— go the extra mile! Invest into library materials, tests or certifications.',
    tooltip: '',
  },
  TX00192: {
    content: 'available categories',
    tooltip: '',
  },
  TX00193: {
    content: 'activities',
    tooltip: '',
  },
  TX00194: {
    content: 'our values',
    tooltip: '',
  },
  TX00195: {
    content: 'learning is a never-ending process!',
    tooltip: '',
  },
  TX00196: {
    content:
      'we reward your mentoring practice; fees will be decreasing for those who are the most active! ',
    tooltip: '',
  },
  TX00197: {
    content: 'the Pilot Session is chargeable by the platform.',
    tooltip: '',
  },
  TX00198: {
    content:
      'packages are available for you to activate in order for disciples to get discounts.',
    tooltip: '',
  },
  TX00199: {
    content:
      'in order to compensate both quality and quantity in the same manner, the first threshold reached: credits earned, or activity hours completed on the platform will release the fee level you deserve. Great job!',
    tooltip: '',
  },
  TX00200: {
    content: 'competition is one of the primary motivation!',
    tooltip: '',
  },
  TX00201: {
    content:
      'we are competitive even from before we are born! This is one of the 3 primary needs of every human being.',
    tooltip: '',
  },
  TX00202: {
    content:
      'the more challenges we have, the better we become in the activity we do and it keeps us more passionate about it.',
    tooltip: '',
  },
  TX00203: {
    content: 'let’s enjoy competition: with yourself and with the others. ',
    tooltip: '',
  },
  TX00204: {
    content: 'collect diamonds by teaching and get amazing benefits.',
    tooltip: '',
  },
  TX00205: {
    content: 'high prestige is the result of commitment!',
    tooltip: '',
  },
  TX00206: {
    content:
      'we know you commit to become the best mentor. For you to be selected the one, its important you are visible from our community of mentors based on filters, but more important recommended by reviews and activity.',
    tooltip: '',
  },
  TX00207: {
    content:
      'mentors which have the highest rates, the most activities and best results of their work, will be the ones more visible in the list, and you will be recognised by the level of experience.',
    tooltip: '',
  },
  TX00208: {
    content:
      'nevertheless, the new mentors will also be visible, anyone can have their own chance.',
    tooltip: '',
  },
  TX00209: {
    content: 'become a mentor!',
    tooltip: '',
  },
  TX00210: {
    content: 'become a disciple!',
    tooltip: '',
  },
  TX00211: {
    content:
      'didn’t we all had at least one experience with a teacher that didn’t match our expectations and we literally lost our interest on that specific area?',
    tooltip: '',
  },
  TX00212: {
    content:
      'now its your time! If you find a good opportunity to teach others, to use your knowledge and experience to pass it on to other people.',
    tooltip: '',
  },
  TX00213: {
    content:
      'new or old generation, become a mentor and challenge yourself to be the best version of what you have expected from your teachers. ',
    tooltip: '',
  },
  TX00214: {
    content:
      'transforming skills into wisdom using the right methodology and sending the good energy, this can define an ideal mentoring process.',
    tooltip: '',
  },
  TX00215: {
    content:
      'we reward your investment; discounts will be available for those who are the most active!',
    tooltip: '',
  },
  TX00216: {
    content:
      'choose the mentor you think you vibe with the most. The Pilot Session is fully refundable in case you decide to try another mentor.',
    tooltip: '',
  },
  TX00217: {
    content:
      'packages with discounts may be available for you to purchase if your mentors have activated them.',
    tooltip: '',
  },
  TX00218: {
    content:
      'in order to compensate both quality and quantity in the same manner, the first threshold reached: credits earned, or activity hours completed on the platform will release the discount level you deserve. Great job!',
    tooltip: '',
  },
  TX00219: {
    content:
      'we are competitive even from before we are born! This is one of the 3 primary needs of every human being.',
    tooltip: '',
  },
  TX00220: {
    content:
      'the more challenges we have, the better we become in the learning process and it keeps us more passionate about it.',
    tooltip: '',
  },
  TX00221: {
    content: 'let’s enjoy competition: with yourself and with the others. ',
    tooltip: '',
  },
  TX00222: {
    content: 'collect diamonds by learning and get amazing benefits.',
    tooltip: '',
  },
  TX00223: {
    content:
      'we know you expect to find the best mentors. For you to select the one from our community of mentors, based on your filters, is more important to be recommended by reviews and activity.',
    tooltip: '',
  },
  TX00224: {
    content:
      'mentors which have the highest rates, the most activities and best results of their work, will be the ones more visible in the list, and you will recognise them by the level of experience.',
    tooltip: '',
  },
  TX00225: {
    content:
      'nevertheless, the new mentors will also be visible, anyone can have their own chance.',
    tooltip: '',
  },
  TX00226: {
    content: 'the more you learn, learning becomes fast and easy.',
    tooltip: '',
  },
  TX00227: {
    content:
      'we all are disciples, learning everyday new things, having different interests and desire to explore each of them.',
    tooltip: '',
  },
  TX00228: {
    content:
      'new or old generation, become a disciple and challenge yourself to be the best version of what you envisioned of yourself. ',
    tooltip: '',
  },
  TX00229: {
    content: 'enjoy learning and be confident. The rest is in our hands! ',
    tooltip: '',
  },
  TX00230: {
    content: 'menu',
    tooltip: '',
  },
  TX00231: {
    content: 'account',
    tooltip: '',
  },
  TX00232: {
    content: 'profile',
    tooltip: '',
  },
  TX00233: {
    content: 'forum',
    tooltip: '',
  },
  TX00234: {
    content: 'support',
    tooltip: '',
  },
  TX00235: {
    content: 'dismiss',
    tooltip: '',
  },
  TX00236: {
    content: 'info',
    tooltip: '',
  },
  TX00237: {
    content: 'wallet',
    tooltip: '',
  },
  TX00238: {
    content: 'share',
    tooltip: '',
  },
  TX00239: {
    content: 'news',
    tooltip: 'be up to date',
  },
  TX00240: {
    content: 'first Name',
    tooltip: '',
  },
  TX00241: {
    content: 'last Name',
    tooltip: '',
  },
  TX00242: {
    content: 'birthday',
    tooltip: '',
  },
  TX00243: {
    content: 'gender',
    tooltip: '',
  },
  TX00244: {
    content: 'email',
    tooltip: '',
  },
  TX00245: {
    content: 'password',
    tooltip: '',
  },
  TX00246: {
    content: 'language (platform)',
    tooltip: '',
  },
  TX00247: {
    content: 'residence',
    tooltip: '',
  },
  TX00248: {
    content: 'timezone',
    tooltip: '',
  },
  TX00249: {
    content: 'from',
    tooltip: '',
  },
  TX00250: {
    content: 'edit',
    tooltip: '',
  },
  TX00251: {
    content: 'cancel',
    tooltip: '',
  },
  TX00252: {
    content: 'save',
    tooltip: '',
  },
  TX00253: {
    content: 'information marked as ',
    tooltip: '',
  },
  TX00254: {
    content: 'will be public on the platform!',
    tooltip: '',
  },
  TX00255: {
    content: 'get free CRE!',
    tooltip: '',
  },
  TX00256: {
    content: 'have you shared with your friends about Webinclass?',
    tooltip: '',
  },
  TX00257: {
    content: 'grow together by online learning.',
    tooltip: '',
  },
  TX00258: {
    content: 'cards',
    tooltip: '',
  },
  TX00259: {
    content: 'history',
    tooltip: '',
  },
  TX00260: {
    content: 'we care about our most dedicated members',
    tooltip: '',
  },
  TX00723: {
    content: 'engleză',
    tooltip: '',
  },
  TX00729: {
    content: 'română',
    tooltip: '',
  },
  TX00725: {
    content: 'germană',
    tooltip: '',
  },
  TX00900: {
    content: 'category',
    tooltip: '',
  },
  TX00451: {
    content: 'recurrent',
    tooltip: '',
  },
};

export default romanian;
