import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';

const RoleBackdrop = ({ role }) => {
  const { mentoringBackdrop, educationBackdrop } = useContext(AssetContext);

  const {
    lang: { TX00050: educationLang, TX00051: mentoringLang },
  } = useContext(LangContext);

  return (
    <section className='role-backdrop'>
      <h1 className='clr-white'>
        {role === 'education' ? educationLang.content : mentoringLang.content}
      </h1>
      <img
        src={role === 'education' ? educationBackdrop : mentoringBackdrop}
        alt={`${role} backdrop`}
      />
    </section>
  );
};

RoleBackdrop.propTypes = {
  role: PropTypes.string.isRequired,
};

export default RoleBackdrop;
