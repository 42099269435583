import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Owl = ({ spread, className }) => {
	const owlRef = useRef(null);

	useEffect(() => {
		spread && owlRef.current.classList.add('owl-spread');

		// eslint-disable-next-line
	}, []);

	if (!spread)
		return (
			<svg
				id='svg-owl'
				className={className}
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
				viewBox='0 0 459.94 386.02'
			>
				<defs>
					<clipPath id='clipPath' transform='translate(0)'>
						<path
							d='M0,0V386H459.94V0ZM171.7,252.18c-30,0-54.36-24.86-54.39-55.56S141.59,141,171.62,141,226,165.82,226,196.53,201.72,252.16,171.7,252.18Zm110.42,0c-30,0-54.32-24.94-54.3-55.65s24.37-55.6,54.38-55.58,54.33,25,54.31,55.67S312.14,252.21,282.12,252.18Z'
							fill='none'
						/>
					</clipPath>
					<clipPath id='clipPath-2' transform='translate(0)'>
						<path
							d='M296.35,203.68c-.44.71-.9,1.42-1.35,2.13h0c-1.25,7.58-.08,15.24-.87,22.84h0A65.87,65.87,0,0,1,294,245.6h0c-.7,6,0,11.94-.49,17.89h0c.32,5.89.75,11.77-.23,17.64h0c-.69,5.73,0,11.51-.5,17.24h0c.31,6.16.8,12.3-.26,18.42h0c-.6,5.49-.21,11-.26,16.46h0c-.11,5.64.66,11.3-.46,16.9h0c-.61,5.53-.21,11.07-.26,16.59h0c.17,4.22.49,8.43.64,12.63h0c.05,1.25-.77,5.28.23,6.08h0c20.12-33.5,42.27-71.48,62.36-105h0c8.36-13.94,17.71-28.68,26-42.64h0c.19-2-2.61-1.72-4-2.28h0c-6.87-2.8-13.56-6-20.59-8.5h0c-10.18-3.61-19.93-8.22-29.81-12.52h0c-8.67-3.78-17.83-6.41-26.3-10.65h0a5.1,5.1,0,0,0-2.23-.66h0a2.77,2.77,0,0,0-1.55.47m-4,181.77c-.18.3-.08.15,0,0h0a1.71,1.71,0,0,0,.11-.17h0l-.12.17'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(89.35, 0, 0, -89.35, 3299.81, 72083.51)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#29a1da' />
						<stop offset='1' stopColor='#79cede' />
					</linearGradient>
					<clipPath id='clipPath-3' transform='translate(0)'>
						<path
							d='M453.73,110.66c-51.65,30.44-102.67,59.72-154.29,90.2h0c-1.16.69-2.75,1.06-3.09,2.7h0c2.43.28,4.4,1.65,6.59,2.5h0c8.82,3.46,17.87,6.46,26.41,10.47h0c12.9,6,26.35,10.75,39.38,16.43h0a87.24,87.24,0,0,1,11.17,5.5h0c.35-.46,48.66-80.89,72.56-120.66h0c1.86-3.11,4.09-6.62,6-9.88h0c-1.29.65-2,1.17-4.76,2.74'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-2'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(162.14, 0, 0, -162.14, 5755.75, 130451.73)'
						xlinkHref='#linear-gradient-owl'
					/>
					<clipPath id='clipPath-4' transform='translate(0)'>
						<path
							d='M240.61,296.24a2.88,2.88,0,0,0,.15,3.46h0c3.35,5.49,6.6,11.05,9.91,16.57h0Q271,350.14,291.3,384h0c1.82-.49.86-1.89.92-2.86h0c.29-4.84-.43-9.7.4-14.53h0l0-16.74h0c.64-1.31.24-2.71.32-4.07h0c.23-4.22-.42-8.46.37-12.67h0q0-8.36,0-16.71h0a105.75,105.75,0,0,0,0-18.06h0a7.51,7.51,0,0,0,.68-3.68h0c0-4.6,0-9.19,0-13.79h0a96.9,96.9,0,0,0,0-17.37h0a7.5,7.5,0,0,0,.67-3.68h0c0-4.83,0-9.66,0-14.48h0a90.13,90.13,0,0,0,0-16.67h0a92.2,92.2,0,0,0,.14-23h0q-27.19,45.3-54.39,90.56'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-3'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(55.55, 0, 0, -55.55, 2110.49, 44928.32)'
						xlinkHref='#linear-gradient-owl'
					/>
					<clipPath id='clipPath-5' transform='translate(0)'>
						<path
							d='M284.64,219.78q-22.74,37.87-45.48,75.71h0a4.27,4.27,0,0,0,.16,5h0c2,3.35,4,6.72,6,10.09h0c1.29,2.16,2.56,4.32,3.85,6.47h0c9.32,15.55,18.66,29.12,29.64,46.34h0L292.3,385.6s.22-2.7.29-3.78h0c.64-9.57.66-9.1,1-15.33h0c.38-8.74.86-13.24,1.09-20h0c.14-3.79.2-7.59.24-11.37h0c.14-13.05.42-26.08.56-39.12h0c.15-12.57.89-25.11,1-37.67h0c0-3.26.16-6.52.28-9.78h0c.26-7.17-.1-14.33,0-21.49h0c.14-7.16.67-14.35-.14-21.5h0l-.55-4.85Zm-4.55,142.36-28-46.62h0l-3.85-6.46h0c-2-3.38-4-6.77-6.06-10.12h0c-.55-.91-.57-1.24-.16-1.95h0q22.8-37.82,45.49-75.7h0l6.26-10.42a86.66,86.66,0,0,1-.59,17.61h0l0,.16v.16a87.12,87.12,0,0,1,0,16.41h0l0,4.06c0,3.51,0,7,0,10.57h0a6.3,6.3,0,0,1-.5,3h0l-.2.37,0,.43a95.59,95.59,0,0,1,0,17.11h0l0,3.9c0,3.34,0,6.69,0,10h0a6.27,6.27,0,0,1-.51,3h0l-.2.38,0,.42a104.77,104.77,0,0,1,0,17.81h0l0,16.71a38.83,38.83,0,0,0-.37,8h0c0,1.62.09,3.16,0,4.7h0a13.64,13.64,0,0,0,0,1.44h0a4.08,4.08,0,0,1-.2,2.06h0l-.15.31,0,17a47.81,47.81,0,0,0-.36,8.91h0c0,1.42.06,2.86,0,4.3h0Z'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-4'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(58.66, 0, 0, -58.66, 2213.39, 47421.77)'
						xlinkHref='#linear-gradient-owl'
					/>
					<linearGradient
						id='linear-gradient-owl-5'
						x1='-33.67'
						y1='807.27'
						x2='-32.67'
						y2='807.27'
						gradientTransform='translate(5819.51 -132310.35) scale(164.06)'
						xlinkHref='#linear-gradient-owl'
					/>
					<clipPath id='clipPath-6' transform='translate(0)'>
						<path
							d='M206,247.93c-1.06.69-.52,1.25-.15,1.93h0Q213.89,265,222,280.08h0c2,3.65,3.94,7.51,6.13,11.12h0c0-17.8,0-35.77,0-53.57h0c0-1.4,0-3,0-4.37h0c-7.33,4.86-14.8,9.85-22.15,14.67'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-6'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(22.78, 0, 0, -22.78, 972.41, 18566.71)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#8b519f' />
						<stop offset='0.08' stopColor='#8b519f' />
						<stop offset='0.97' stopColor='#7c2987' />
						<stop offset='1' stopColor='#7c2987' />
					</linearGradient>
					<clipPath id='clipPath-7' transform='translate(0)'>
						<path
							d='M228.13,237.63c0,17.8,0,35.77,0,53.57h0c2.18-3.61,4.15-7.47,6.13-11.12h0c5.43-10.05,10.79-20.14,16.18-30.22h0c.37-.68.91-1.24-.15-1.93h0c-7.35-4.82-14.83-9.81-22.15-14.67h0c0,1.4,0,3,0,4.37'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-7'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='translate(-516.16 18566.71) rotate(180) scale(22.78)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#79cede' />
						<stop offset='0.01' stopColor='#79cede' />
						<stop offset='0.2' stopColor='#79cede' />
						<stop offset='1' stopColor='#29a1da' />
					</linearGradient>
					<clipPath id='clipPath-9' transform='translate(0)'>
						<path
							d='M0,107.17c.24.43.43.84.67,1.23h0Q31.3,159.52,62,210.63h0c7.46,12.42,15,24.77,22.53,37.15h0c1.77.42,3-.78,4.22-1.53h0c23.37-14.12,46.08-29.17,69.36-43.42h0a12.24,12.24,0,0,0,2.85-3.77h0c-1.9-2.5-7.44-4.7-10.1-6.27h0c-49.86-29.34-98.39-55.67-148.28-85h0c-.52-.3-1-1-1.7-1h0a1.54,1.54,0,0,0-.85.29'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-8'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(160.93, 0, 0, -160.93, 5418.5, 129479.82)'
						xlinkHref='#linear-gradient-owl-6'
					/>
					<clipPath id='clipPath-10' transform='translate(0)'>
						<path
							d='M154.65,204c-9.42,6-18.76,12.16-28.24,18.09h0c-13.57,8.49-27.24,16.83-40.87,25.24h0a17.85,17.85,0,0,0,.85,1.89h0l66.29,112.06h0c4,6.73,8,13.46,12,20.15h0c.42.71.78,3.4,2.21.5h0c1-4.4-.36-8.76-.23-13.14h0c.39-13.1-.94-26.16-.86-39.27h0c0-4.7-.45-9.47-.87-14.2h0a35.94,35.94,0,0,1-.16-12.54h0c-.17-3.12-.38-6.23-.27-9.34h0c.33-9-.62-18-1-26.94h0a27.07,27.07,0,0,1-.11-11h0c-.4-4.33-.19-8.69-.66-13h0a37.61,37.61,0,0,1-.1-12.3h0c-.46-6.61-.26-13.23-.25-19.84h0a22.79,22.79,0,0,0-1.82-9.15h0a1,1,0,0,0-.1-.56h0c-1.94,1.11-3.93,2.15-5.81,3.35'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-9'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(81.75, 0, 0, -81.75, 2838.07, 65976.14)'
						xlinkHref='#linear-gradient-owl-6'
					/>
					<clipPath id='clipPath-11' transform='translate(0)'>
						<path
							d='M159.61,199.63l-1.82,1h0c-1.34.74-2.73,1.52-4.06,2.37h0c-3.58,2.28-7.15,4.59-10.72,6.89h0c-5.72,3.69-11.64,7.51-17.5,11.18h0C116,227,106.33,233,96.94,238.77h0l-12.3,7.57h0l-.59.53s2,3.53,2.29,4h0c22.09,37.36,42.82,74.1,64.92,111.45h0l.29.51c0,.25.47.8.61,1h0c.2.34.4.68.61,1h0L154,367h0c.65,1.09,1.3,2.19,1.94,3.29h0c.51.87,1,1.75,1.5,2.62h0c.89,1.57,1.36,2.43,2.29,4h0c1.61,2.71,2.87,5,4.82,8.15h0c.6,1,.61,1.1.77.79h0c.74-1.5.62-1.2.9-1.82h0a5,5,0,0,1,.83-2.07h0a4.25,4.25,0,0,1,1.17-2h0a3.58,3.58,0,0,0,.79-2.29h0c0-1.24-.29-2.47-.42-3.7h0a36.91,36.91,0,0,1-.29-4.86h0c.21-7.24-.1-14.57-.4-21.65h0c-.24-5.79-.5-11.78-.46-17.65h0c0-4.34-.37-8.75-.76-13h0l-.12-1.33,0-.19a35.35,35.35,0,0,1-.14-12h0l0-.14-.07-1.33c-.15-2.63-.3-5.35-.2-8h0c.23-6.33-.17-12.7-.55-18.87h0c-.17-2.68-.35-5.46-.46-8.19h0l0-.26a25.67,25.67,0,0,1-.1-10.4h0l0-.19v-.2c-.18-2-.24-4-.29-6h0c0-2.29-.12-4.66-.38-7h0l0-.13a36.65,36.65,0,0,1-.07-11.82h0l0-.15v-.14c-.36-5-.32-10.16-.27-15.13h0c0-1.53,0-3.07,0-4.61h0a24.11,24.11,0,0,0-1.81-9.45h0a2.26,2.26,0,0,0-.27-.94h0l-.88-.9Zm-5.2,161.71-.3-.51L87.82,248.77h0c-.09-.17-.18-.35-.26-.53h0l11.17-6.88h0c9.38-5.77,19.09-11.73,28.58-17.68h0c5.89-3.68,11.82-7.5,17.54-11.2h0q5.35-3.45,10.72-6.88h0c1.25-.8,2.59-1.55,3.88-2.27h0l.07,0h0a21.67,21.67,0,0,1,1.22,7.37h0c0,1.53,0,3.06,0,4.59h0c0,5-.08,10.12.27,15.21h0A39.67,39.67,0,0,0,161.1,243h0c.24,2.21.3,4.49.36,6.7h0c0,2,.1,4,.28,6h0a28.42,28.42,0,0,0,.13,11.25h0c.12,2.7.29,5.45.46,8.11h0c.37,6.09.6,24.15.75,26.85h0s1.12,23.66,1.1,27.85h0c0,5.94.21,12,.46,17.79h0c.3,7,.6,14.3.39,21.44h0a41.4,41.4,0,0,0,.31,5.26h0a30.74,30.74,0,0,1,.25,5.79h0c-3.77-6.24-7.53-12.6-11.18-18.76'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-10'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(84.96, 0, 0, -84.96, 2944.74, 68557.58)'
						xlinkHref='#linear-gradient-owl-6'
					/>
					<clipPath id='clipPath-12' transform='translate(0)'>
						<path
							d='M161,215.67c-.06,5.52-.74,11.09.56,16.57h0a44.71,44.71,0,0,0,0,11.5h0c.16,4.34,0,8.24.28,13.08h0c.16,3.53.3,6.28.44,10.05h0c0,10.28.78,20,.76,30.8h0c-.09,1.83.49,11.85.69,16.85h0c.16,4.5.43,9,.64,13.48h0c.61,13.64.49,27.3.94,40.94h0c.13,3.64-.46,11.14.17,16.33h0c11.18-18.08,28.89-50.39,51.1-86h0c1.33-2.14,1.27-3.64-.05-5.76h0c-15-24-29.81-48.09-44.8-72.09h0c-3.75-6-6.06-12.93-10.7-18.44h0c0,4.07,0,8.62-.06,12.71'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-11'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(56.82, 0, 0, -56.82, 2073.77, 45944.49)'
						xlinkHref='#linear-gradient-owl-6'
					/>
					<clipPath id='clipPath-13' transform='translate(0)'>
						<path
							d='M161.06,208.71q0,3.09-.05,6.2h0c0,4.47-.07,8.94,0,13.4h0c.12,9.37.48,18.73.86,28.09h0l0,.76h0c.19,4.39.45,8.77.69,13.15h0c.7,12.46.59,24.9.84,37.61h0c.08,4,.4,8,.59,12h0c.8,16.47.83,33,1.38,49.44h0c.12,3.64-.41,11.22-.21,16.64h0c11.43-19,29.51-50.7,51.9-86.34h0c1.35-2.14,1.29-3.63,0-5.76h0c-15.11-24-30.05-48.08-45.17-72.08h0c-5.46-8.69-7.74-14.14-10.79-18.85h0c.62,1,0,4.57,0,5.74'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-12'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(57.09, 0, 0, -57.09, 2083.22, 46165.72)'
						xlinkHref='#linear-gradient-owl-6'
					/>
					<clipPath id='clipPath-14' transform='translate(0)'>
						<path
							d='M229.64,193.08c0,29.63,21.92,53.67,49,53.69h0c27.1,0,49.07-24,49.1-53.61h0c0-29.62-21.92-53.66-49-53.69h0c-27.1,0-49.06,24-49.08,53.61'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-13'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(-98.13, 0, 0, 98.13, -2976.13, -78648.32)'
						xlinkHref='#linear-gradient-owl'
					/>
					<clipPath id='clipPath-15' transform='translate(0)'>
						<path
							d='M261.94,141.48a7.27,7.27,0,0,1,4.19-4.43h0c-15.78,3.17-31.08,17.32-33.48,33.72h0l.22-.11c11.19-11.94,23.71-16.08,39.77-16.54h0c-1.24-1.65-1.5-2.67-3.59-3.71h0c6.79,1,12.92,2.28,20,1.81h0c-.61-.38-1.22-.76-1.84-1.12h0c4.57,1.67,9.87,1.47,14.67,1.12h0a16.79,16.79,0,0,0-1.78-1.38h0c12.61,4,31.33.38,31.59-14.32h0c-6.51,3.73-11.95,7.76-20.07,7h0c-7.84-.77-14.31-4.31-22.34-4.61h0a26.41,26.41,0,0,1,5.47-2.57h0a20.74,20.74,0,0,0-8.54-1.77h0c-8.37,0-16.67,4.4-24.25,6.95'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-14'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(99.03, 0, 0, -99.03, 3566.87, 79717.53)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#79cede' />
						<stop offset='1' stopColor='#29a1da' />
					</linearGradient>
					<clipPath id='clipPath-16' transform='translate(0)'>
						<path
							d='M239.17,197.41a32,32,0,0,0,32,32h0a32,32,0,0,0,32-32h0a32,32,0,0,0-32-32.05h0a32,32,0,0,0-32,32'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='translate(-2161.54 57187.45) rotate(-179.96) scale(70.92)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fcd600' />
						<stop offset='0.38' stopColor='#fcd600' />
						<stop offset='0.62' stopColor='#f89e1b' />
						<stop offset='1' stopColor='#f89e1b' />
					</radialGradient>
					<clipPath id='clipPath-17' transform='translate(0)'>
						<path
							d='M254.58,198a16.71,16.71,0,0,0,16.69,16.7h0A16.71,16.71,0,0,0,288,198.05h0a16.69,16.69,0,0,0-16.67-16.7h0A16.69,16.69,0,0,0,254.58,198'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient-2'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='matrix(-16.69, -0.01, 0.01, -16.69, -300.85, 13606.74)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#545456' />
						<stop offset='1' stopColor='#402312' />
					</radialGradient>
					<clipPath id='clipPath-18' transform='translate(0)'>
						<path
							d='M175.07,139.47c-27.1,0-49,24.07-49,53.69h0c0,29.63,22,53.63,49.1,53.61h0c27.11,0,49-24.06,49-53.69h0c0-29.61-22-53.61-49.08-53.61h0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-15'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='translate(3430.05 -78650.68) scale(98.13)'
						xlinkHref='#linear-gradient-owl'
					/>
					<clipPath id='clipPath-19' transform='translate(0)'>
						<path
							d='M159.1,136.3a26.66,26.66,0,0,1,5.47,2.57h0c-8,.3-14.51,3.84-22.35,4.61h0c-8.12.8-13.56-3.23-20.08-7h0c.27,14.71,19,18.34,31.61,14.31h0a17.29,17.29,0,0,0-1.79,1.39h0c4.81.35,10.1.54,14.67-1.12h0c-.62.36-1.23.74-1.83,1.12h0c7.06.47,13.18-.79,20-1.81h0c-2.09,1-2.35,2.06-3.58,3.71h0c16.06.46,28.57,4.6,39.76,16.54h0l.22.11c-2.4-16.4-17.69-30.55-33.48-33.73h0a7.26,7.26,0,0,1,4.19,4.44h0c-7.58-2.55-15.88-6.95-24.25-6.95h0a20.66,20.66,0,0,0-8.53,1.77'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-16'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='translate(-3113.18 79720.67) rotate(180) scale(99.03)'
						xlinkHref='#linear-gradient-owl-14'
					/>
					<clipPath id='clipPath-20' transform='translate(0)'>
						<path
							d='M182.61,165.41a32,32,0,0,0-32,32.05h0a32,32,0,0,0,32,32h0a32,32,0,0,0,32-32h0a32,32,0,0,0-32-32h0'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient-3'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='matrix(70.91, -0.05, -0.05, -70.91, 2615.18, 57183.06)'
						xlinkHref='#radial-gradient'
					/>
					<clipPath id='clipPath-21' transform='translate(0)'>
						<path
							d='M182.53,181.35a16.68,16.68,0,0,0-16.66,16.7h0a16.7,16.7,0,0,0,16.68,16.68h0A16.71,16.71,0,0,0,199.24,198h0a16.69,16.69,0,0,0-16.7-16.68h0'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient-4'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='matrix(16.69, -0.01, -0.01, -16.69, 754.65, 13606.27)'
						xlinkHref='#radial-gradient-2'
					/>
					<clipPath id='clipPath-22' transform='translate(0)'>
						<path
							d='M97.38,41.32l-17,2.78c-2.51.24-7.24,2.51-7.24,9.29h0v57.08a3.7,3.7,0,0,0,3.72,3.7h0a3.68,3.68,0,0,0,3.69-3.7h0V53.39c0-.67.1-1.78.8-2h0l20.26-7.06s-1.95-3.08-3.95-3.08h0l-.3,0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-17'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(0, 72.87, 72.87, 0, -58462.47, 2494.86)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fbb03b' />
						<stop offset='0.98' stopColor='#c76e28' />
						<stop offset='1' stopColor='#c76e28' />
					</linearGradient>
					<clipPath id='clipPath-23' transform='translate(0)'>
						<path
							d='M80.64,46.57c-.19,0-5,.55-5,6.82h0v57.08a1.21,1.21,0,0,0,1.23,1.23h0a1.23,1.23,0,0,0,1.23-1.23h0V53.39c0-3.36,1.88-4.17,2.46-4.28h0l13.7-4.77Z'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient-5'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='matrix(24.71, 0, 0, -24.71, 916.9, 19930.79)'
						xlinkHref='#radial-gradient'
					/>
					<clipPath id='clipPath-24' transform='translate(0)'>
						<path
							d='M65.36,189.78c0,3.46,23,3.65,23,0h0c0-26.73-5.15-79.31-11.5-79.31h0c-6.38,0-11.52,52.58-11.52,79.31'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-18'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(23.02, 0, 0, -23.02, 840.46, 18647.78)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#db7c27' />
						<stop offset='0.02' stopColor='#db7c27' />
						<stop offset='0.5' stopColor='#d18629' />
						<stop offset='1' stopColor='#cc7428' />
					</linearGradient>
					<clipPath id='clipPath-25' transform='translate(0)'>
						<path
							d='M67.39,188.67c.7.53,4.27,1.3,9.7,1.3h0c4.16,0,6.86-.43,8.07-.43h0c.46,0,.72.05.75.24h0c0-30.41-5.41-73.59-8.43-77h0c-4.25,3.41-9.66,46.58-10.09,75.9'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient-6'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='matrix(28.07, 0, 0, -28.07, 1021.74, 22704.22)'
						xlinkHref='#radial-gradient'
					/>
					<clipPath id='clipPath-26' transform='translate(0)'>
						<path
							d='M67.14,111.53a9.5,9.5,0,0,0,9.74,9.28h0a9.51,9.51,0,0,0,9.73-9.28h0a9.53,9.53,0,0,0-9.73-9.28h0a9.52,9.52,0,0,0-9.74,9.28'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient-7'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='matrix(14.77, 0, 0, -14.77, 574.06, 11968.7)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#f89b1c' />
						<stop offset='0.98' stopColor='#d57527' />
						<stop offset='1' stopColor='#d57527' />
					</radialGradient>
					<clipPath id='clipPath-27' transform='translate(0)'>
						<path
							d='M69.61,111.53a7,7,0,0,0,7.27,6.8h0a7,7,0,0,0,7.24-6.8h0a7,7,0,0,0-7.24-6.8h0a7,7,0,0,0-7.27,6.8'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient-8'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='matrix(10.87, 0, 0, -10.87, 442.72, 8836.63)'
						xlinkHref='#radial-gradient'
					/>
					<clipPath id='clipPath-28' transform='translate(0)'>
						<path
							d='M229.65,45.64l-.36,0h0l0,.05c-35.38.15-66.66,9.61-86.45,24.19h0L130,117.32c66.55,0,75.76,25.19,99.27,25.19h0c.11,0,.25-.14.36-.14h0c.14,0,.29.14.39.14h0c25.64,0,32.73-25.19,99.27-25.19h0L316.49,69.86c-19.75-14.58-51-24-86.41-24.19h0l0-.05-.39,0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-19'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(199.29, 0, 0, -199.29, 6840.18, 160219.54)'
						xlinkHref='#linear-gradient-owl-6'
					/>
					<clipPath id='clipPath-29' transform='translate(0)'>
						<path
							d='M229.65,45.64l-.36,0h0l0,.05c-35.38.15-66.66,9.61-86.45,24.19h0l-12.67,47L242,46.09c-3.92-.25-7.89-.4-11.93-.42h0l0-.05-.39,0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-20'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(58.62, -101.53, -101.53, -58.62, 83700.68, 43808.37)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#8b519f' />
						<stop offset='0.08' stopColor='#8b519f' />
						<stop offset='0.82' stopColor='#7c2987' />
						<stop offset='0.97' stopColor='#7c2987' />
						<stop offset='1' stopColor='#7c2987' />
					</linearGradient>
					<clipPath id='clipPath-30' transform='translate(0)'>
						<path
							d='M229.8,45.64l-.39,0h0l0,.05c-4,0-8,.17-11.92.42h0l111.86,70.79-12.67-47c-19.78-14.58-51.07-24-86.44-24.19h0l0-.05-.37,0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-21'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(-114.55, -66.13, 66.13, -114.55, -56654.54, 89909.03)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#8b519f' />
						<stop offset='0.57' stopColor='#7c2987' />
						<stop offset='0.97' stopColor='#7c2987' />
						<stop offset='1' stopColor='#7c2987' />
					</linearGradient>
					<clipPath id='clipPath-31' transform='translate(0)'>
						<path
							d='M223.24,0a3,3,0,0,0-.57.09h0C209.05,2.09,76.88,53,72.27,55.27h0c-4.72,2.4-10.22,5.13-10.22,9.46h0c0,4.3,6.65,9,10.62,10.59h0c3.89,1.55,61.86,14.91,61.86,14.91H311.91s75.62-6.42,79.52-8h0c4-1.58,10.62-6.3,10.62-10.6h0c0-4.32-5.51-7.06-10.23-9.45h0c-4.61-2.29-154.43-60.12-168-62.12h0a4.41,4.41,0,0,0-.52-.09h0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-22'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(340, 0, 0, -340, 11509.81, 273224.7)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#8b519f' />
						<stop offset='0.08' stopColor='#8b519f' />
						<stop offset='0.25' stopColor='#7c2987' />
						<stop offset='0.97' stopColor='#7c2987' />
						<stop offset='1' stopColor='#7c2987' />
					</linearGradient>
					<clipPath id='clipPath-32' transform='translate(0)'>
						<path
							d='M223.2,3.7C210.8,5.54,80.06,55.52,73.77,58.6h0c-3.21,1.64-8.08,4.09-8.08,6.13h0c0,1.66,332.67,8.59,332.67,6.93h0c0-2-5.07-4.59-8.18-6.17h0c-8-3.91-154.86-60-166.94-61.79h0Z'
							fill='none'
						/>
					</clipPath>
					<radialGradient
						id='radial-gradient-9'
						cx='-33.67'
						cy='803.47'
						r='1'
						gradientTransform='matrix(287.95, 0, 0, -287.95, 9921.47, 231318.39)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fff' />
						<stop offset='0.49' stopColor='#90278e' />
						<stop offset='0.87' stopColor='#90278e' />
						<stop offset='1' stopColor='#90278e' />
					</radialGradient>
					<clipPath id='clipPath-33' transform='translate(0)'>
						<polygon
							points='68.84 65.02 132.65 81.49 224.49 81.82 224.49 8 68.84 65.02'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-23'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(155.65, 0, 0, -155.65, 5309.56, 125104.81)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#7c2987' />
						<stop offset='0.33' stopColor='#7c2987' />
						<stop offset='0.93' stopColor='#8b519f' />
						<stop offset='0.99' stopColor='#8b519f' />
						<stop offset='1' stopColor='#8b519f' />
					</linearGradient>
					<clipPath id='clipPath-34' transform='translate(0)'>
						<polygon
							points='224.49 81.82 316.5 81.98 399.38 72.89 224.49 8 224.49 81.82'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-24'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(55.66, -86.05, -86.05, -55.66, 71282.19, 41936.11)'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#8b519f' />
						<stop offset='0.55' stopColor='#7c2987' />
						<stop offset='0.97' stopColor='#7c2987' />
						<stop offset='1' stopColor='#7c2987' />
					</linearGradient>
					<clipPath id='clipPath-35' transform='translate(0)'>
						<path
							d='M230.55,47.67l-.37,0h0l0,.05c-37,.14-69.7,9.33-90.38,23.5h0l-13.38,46.08c69.58,0,79.22,24.46,103.8,24.46h0c.1,0,.25-.14.37-.14h0c.15,0,.3.14.41.14h0c26.8,0,34.21-24.46,103.78-24.46h0L321.34,71.2C300.69,57,268,47.84,231,47.7h0l0-.05-.41,0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-25'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(208.36, 0, 0, -208.36, 7141.81, 167504.77)'
						xlinkHref='#linear-gradient-owl-6'
					/>
					<clipPath id='clipPath-36' transform='translate(0)'>
						<path
							d='M230.55,47.67l-.37,0h0l0,.05c-37,.14-69.7,9.33-90.38,23.5h0l-13.25,45.65,117-68.74c-4.1-.24-8.24-.39-12.47-.41h0l0-.05-.41,0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-26'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(59, -102.2, -102.2, -59, 84248.68, 44096.89)'
						xlinkHref='#linear-gradient-owl-20'
					/>
					<clipPath id='clipPath-37' transform='translate(0)'>
						<path
							d='M230.71,47.67l-.41,0h0l0,.05c-4.22,0-8.37.17-12.46.41h0l116.94,68.74L321.5,71.2C300.82,57,268.11,47.84,231.12,47.7h0l0-.05-.38,0'
							fill='none'
						/>
					</clipPath>
					<linearGradient
						id='linear-gradient-owl-27'
						x1='-33.67'
						y1='803.47'
						x2='-32.67'
						y2='803.47'
						gradientTransform='matrix(-117.47, -67.82, 67.82, -117.47, -58107.18, 92206.67)'
						xlinkHref='#linear-gradient-owl-21'
					/>
				</defs>
				<g id='Layer_2' data-name='Layer 2'>
					<g id='Layer_2-2' data-name='Layer 2'>
						<g clipPath='url(#clipPath)'>
							<g clipPath='url(#clipPath-2)'>
								<g id='g3042'>
									<g id='g3044'>
										<path
											id='path3052'
											d='M296.35,203.68c-.44.71-.9,1.42-1.35,2.13h0c-1.25,7.58-.08,15.24-.87,22.84h0A65.87,65.87,0,0,1,294,245.6h0c-.7,6,0,11.94-.49,17.89h0c.32,5.89.75,11.77-.23,17.64h0c-.69,5.73,0,11.51-.5,17.24h0c.31,6.16.8,12.3-.26,18.42h0c-.6,5.49-.21,11-.26,16.46h0c-.11,5.64.66,11.3-.46,16.9h0c-.61,5.53-.21,11.07-.26,16.59h0c.17,4.22.49,8.43.64,12.63h0c.05,1.25-.77,5.28.23,6.08h0c20.12-33.5,42.27-71.48,62.36-105h0c8.36-13.94,17.71-28.68,26-42.64h0c.19-2-2.61-1.72-4-2.28h0c-6.87-2.8-13.56-6-20.59-8.5h0c-10.18-3.61-19.93-8.22-29.81-12.52h0c-8.67-3.78-17.83-6.41-26.3-10.65h0a5.1,5.1,0,0,0-2.23-.66h0a2.77,2.77,0,0,0-1.55.47m-4,181.77c-.18.3-.08.15,0,0h0a1.71,1.71,0,0,0,.11-.17h0l-.12.17'
											transform='translate(0)'
											fill='url(#linear-gradient-owl)'
										/>
									</g>
								</g>
							</g>
							<g clipPath='url(#clipPath-3)'>
								<g id='g3062'>
									<g id='g3064'>
										<path
											id='path3072'
											d='M453.73,110.66c-51.65,30.44-102.67,59.72-154.29,90.2h0c-1.16.69-2.75,1.06-3.09,2.7h0c2.43.28,4.4,1.65,6.59,2.5h0c8.82,3.46,17.87,6.46,26.41,10.47h0c12.9,6,26.35,10.75,39.38,16.43h0a87.24,87.24,0,0,1,11.17,5.5h0c.35-.46,48.66-80.89,72.56-120.66h0c1.86-3.11,4.09-6.62,6-9.88h0c-1.29.65-2,1.17-4.76,2.74'
											transform='translate(0)'
											fill='url(#linear-gradient-owl-2)'
										/>
									</g>
								</g>
							</g>
							<g clipPath='url(#clipPath-4)'>
								<g id='g3100'>
									<g id='g3102'>
										<path
											id='path3110'
											d='M240.61,296.24a2.88,2.88,0,0,0,.15,3.46h0c3.35,5.49,6.6,11.05,9.91,16.57h0Q271,350.14,291.3,384h0c1.82-.49.86-1.89.92-2.86h0c.29-4.84-.43-9.7.4-14.53h0l0-16.74h0c.64-1.31.24-2.71.32-4.07h0c.23-4.22-.42-8.46.37-12.67h0q0-8.36,0-16.71h0a105.75,105.75,0,0,0,0-18.06h0a7.51,7.51,0,0,0,.68-3.68h0c0-4.6,0-9.19,0-13.79h0a96.9,96.9,0,0,0,0-17.37h0a7.5,7.5,0,0,0,.67-3.68h0c0-4.83,0-9.66,0-14.48h0a90.13,90.13,0,0,0,0-16.67h0a92.2,92.2,0,0,0,.14-23h0q-27.19,45.3-54.39,90.56'
											transform='translate(0)'
											fill='url(#linear-gradient-owl-3)'
										/>
									</g>
								</g>
							</g>
							<g clipPath='url(#clipPath-5)'>
								<g id='g3120'>
									<g id='g3122'>
										<path
											id='path3130'
											d='M284.64,219.78q-22.74,37.87-45.48,75.71h0a4.27,4.27,0,0,0,.16,5h0c2,3.35,4,6.72,6,10.09h0c1.29,2.16,2.56,4.32,3.85,6.47h0c9.32,15.55,18.66,29.12,29.64,46.34h0L292.3,385.6s.22-2.7.29-3.78h0c.64-9.57.66-9.1,1-15.33h0c.38-8.74.86-13.24,1.09-20h0c.14-3.79.2-7.59.24-11.37h0c.14-13.05.42-26.08.56-39.12h0c.15-12.57.89-25.11,1-37.67h0c0-3.26.16-6.52.28-9.78h0c.26-7.17-.1-14.33,0-21.49h0c.14-7.16.67-14.35-.14-21.5h0l-.55-4.85Zm-4.55,142.36-28-46.62h0l-3.85-6.46h0c-2-3.38-4-6.77-6.06-10.12h0c-.55-.91-.57-1.24-.16-1.95h0q22.8-37.82,45.49-75.7h0l6.26-10.42a86.66,86.66,0,0,1-.59,17.61h0l0,.16v.16a87.12,87.12,0,0,1,0,16.41h0l0,4.06c0,3.51,0,7,0,10.57h0a6.3,6.3,0,0,1-.5,3h0l-.2.37,0,.43a95.59,95.59,0,0,1,0,17.11h0l0,3.9c0,3.34,0,6.69,0,10h0a6.27,6.27,0,0,1-.51,3h0l-.2.38,0,.42a104.77,104.77,0,0,1,0,17.81h0l0,16.71a38.83,38.83,0,0,0-.37,8h0c0,1.62.09,3.16,0,4.7h0a13.64,13.64,0,0,0,0,1.44h0a4.08,4.08,0,0,1-.2,2.06h0l-.15.31,0,17a47.81,47.81,0,0,0-.36,8.91h0c0,1.42.06,2.86,0,4.3h0Z'
											transform='translate(0)'
											fill='url(#linear-gradient-owl-4)'
										/>
									</g>
								</g>
							</g>
							<g id='g3076'>
								<g id='g3082'>
									<path
										id='path3090'
										d='M379.9,238.46a87.24,87.24,0,0,0-11.17-5.5c-13-5.68-26.48-10.39-39.38-16.43-8.54-4-17.59-7-26.41-10.47-2.19-.85-4.16-2.22-6.59-2.5.34-1.64,1.93-2,3.09-2.7,51.62-30.48,102.64-59.76,154.29-90.2,2.73-1.57,3.47-2.09,4.76-2.74-1.94,3.26-4.17,6.77-6,9.88C428.56,157.57,380.25,238,379.9,238.46Z'
										transform='translate(0)'
										fill='none'
										strokeMiterlimit='10'
										stroke='url(#linear-gradient-owl-5)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-6)'>
							<g id='g3140'>
								<g id='g3142'>
									<path
										id='path3154'
										d='M206,247.93c-1.06.69-.52,1.25-.15,1.93h0Q213.89,265,222,280.08h0c2,3.65,3.94,7.51,6.13,11.12h0c0-17.8,0-35.77,0-53.57h0c0-1.4,0-3,0-4.37h0c-7.33,4.86-14.8,9.85-22.15,14.67'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-6)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-7)'>
							<g id='g3164'>
								<g id='g3166'>
									<path
										id='path3178'
										d='M228.13,237.63c0,17.8,0,35.77,0,53.57h0c2.18-3.61,4.15-7.47,6.13-11.12h0c5.43-10.05,10.79-20.14,16.18-30.22h0c.37-.68.91-1.24-.15-1.93h0c-7.35-4.82-14.83-9.81-22.15-14.67h0c0,1.4,0,3,0,4.37'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-7)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath)'>
							<g clipPath='url(#clipPath-9)'>
								<g id='g3188'>
									<g id='g3190'>
										<path
											id='path3202'
											d='M0,107.17c.24.43.43.84.67,1.23h0Q31.3,159.52,62,210.63h0c7.46,12.42,15,24.77,22.53,37.15h0c1.77.42,3-.78,4.22-1.53h0c23.37-14.12,46.08-29.17,69.36-43.42h0a12.24,12.24,0,0,0,2.85-3.77h0c-1.9-2.5-7.44-4.7-10.1-6.27h0c-49.86-29.34-98.39-55.67-148.28-85h0c-.52-.3-1-1-1.7-1h0a1.54,1.54,0,0,0-.85.29'
											transform='translate(0)'
											fill='url(#linear-gradient-owl-8)'
										/>
									</g>
								</g>
							</g>
							<g clipPath='url(#clipPath-10)'>
								<g id='g3212'>
									<g id='g3214'>
										<path
											id='path3226'
											d='M154.65,204c-9.42,6-18.76,12.16-28.24,18.09h0c-13.57,8.49-27.24,16.83-40.87,25.24h0a17.85,17.85,0,0,0,.85,1.89h0l66.29,112.06h0c4,6.73,8,13.46,12,20.15h0c.42.71.78,3.4,2.21.5h0c1-4.4-.36-8.76-.23-13.14h0c.39-13.1-.94-26.16-.86-39.27h0c0-4.7-.45-9.47-.87-14.2h0a35.94,35.94,0,0,1-.16-12.54h0c-.17-3.12-.38-6.23-.27-9.34h0c.33-9-.62-18-1-26.94h0a27.07,27.07,0,0,1-.11-11h0c-.4-4.33-.19-8.69-.66-13h0a37.61,37.61,0,0,1-.1-12.3h0c-.46-6.61-.26-13.23-.25-19.84h0a22.79,22.79,0,0,0-1.82-9.15h0a1,1,0,0,0-.1-.56h0c-1.94,1.11-3.93,2.15-5.81,3.35'
											transform='translate(0)'
											fill='url(#linear-gradient-owl-9)'
										/>
									</g>
								</g>
							</g>
							<g clipPath='url(#clipPath-11)'>
								<g id='g3236'>
									<g id='g3238'>
										<path
											id='path3250'
											d='M159.61,199.63l-1.82,1h0c-1.34.74-2.73,1.52-4.06,2.37h0c-3.58,2.28-7.15,4.59-10.72,6.89h0c-5.72,3.69-11.64,7.51-17.5,11.18h0C116,227,106.33,233,96.94,238.77h0l-12.3,7.57h0l-.59.53s2,3.53,2.29,4h0c22.09,37.36,42.82,74.1,64.92,111.45h0l.29.51c0,.25.47.8.61,1h0c.2.34.4.68.61,1h0L154,367h0c.65,1.09,1.3,2.19,1.94,3.29h0c.51.87,1,1.75,1.5,2.62h0c.89,1.57,1.36,2.43,2.29,4h0c1.61,2.71,2.87,5,4.82,8.15h0c.6,1,.61,1.1.77.79h0c.74-1.5.62-1.2.9-1.82h0a5,5,0,0,1,.83-2.07h0a4.25,4.25,0,0,1,1.17-2h0a3.58,3.58,0,0,0,.79-2.29h0c0-1.24-.29-2.47-.42-3.7h0a36.91,36.91,0,0,1-.29-4.86h0c.21-7.24-.1-14.57-.4-21.65h0c-.24-5.79-.5-11.78-.46-17.65h0c0-4.34-.37-8.75-.76-13h0l-.12-1.33,0-.19a35.35,35.35,0,0,1-.14-12h0l0-.14-.07-1.33c-.15-2.63-.3-5.35-.2-8h0c.23-6.33-.17-12.7-.55-18.87h0c-.17-2.68-.35-5.46-.46-8.19h0l0-.26a25.67,25.67,0,0,1-.1-10.4h0l0-.19v-.2c-.18-2-.24-4-.29-6h0c0-2.29-.12-4.66-.38-7h0l0-.13a36.65,36.65,0,0,1-.07-11.82h0l0-.15v-.14c-.36-5-.32-10.16-.27-15.13h0c0-1.53,0-3.07,0-4.61h0a24.11,24.11,0,0,0-1.81-9.45h0a2.26,2.26,0,0,0-.27-.94h0l-.88-.9Zm-5.2,161.71-.3-.51L87.82,248.77h0c-.09-.17-.18-.35-.26-.53h0l11.17-6.88h0c9.38-5.77,19.09-11.73,28.58-17.68h0c5.89-3.68,11.82-7.5,17.54-11.2h0q5.35-3.45,10.72-6.88h0c1.25-.8,2.59-1.55,3.88-2.27h0l.07,0h0a21.67,21.67,0,0,1,1.22,7.37h0c0,1.53,0,3.06,0,4.59h0c0,5-.08,10.12.27,15.21h0A39.67,39.67,0,0,0,161.1,243h0c.24,2.21.3,4.49.36,6.7h0c0,2,.1,4,.28,6h0a28.42,28.42,0,0,0,.13,11.25h0c.12,2.7.29,5.45.46,8.11h0c.37,6.09.6,24.15.75,26.85h0s1.12,23.66,1.1,27.85h0c0,5.94.21,12,.46,17.79h0c.3,7,.6,14.3.39,21.44h0a41.4,41.4,0,0,0,.31,5.26h0a30.74,30.74,0,0,1,.25,5.79h0c-3.77-6.24-7.53-12.6-11.18-18.76'
											transform='translate(0)'
											fill='url(#linear-gradient-owl-10)'
										/>
									</g>
								</g>
							</g>
							<g clipPath='url(#clipPath-12)'>
								<g id='g3260'>
									<g id='g3262'>
										<path
											id='path3274'
											d='M161,215.67c-.06,5.52-.74,11.09.56,16.57h0a44.71,44.71,0,0,0,0,11.5h0c.16,4.34,0,8.24.28,13.08h0c.16,3.53.3,6.28.44,10.05h0c0,10.28.78,20,.76,30.8h0c-.09,1.83.49,11.85.69,16.85h0c.16,4.5.43,9,.64,13.48h0c.61,13.64.49,27.3.94,40.94h0c.13,3.64-.46,11.14.17,16.33h0c11.18-18.08,28.89-50.39,51.1-86h0c1.33-2.14,1.27-3.64-.05-5.76h0c-15-24-29.81-48.09-44.8-72.09h0c-3.75-6-6.06-12.93-10.7-18.44h0c0,4.07,0,8.62-.06,12.71'
											transform='translate(0)'
											fill='url(#linear-gradient-owl-11)'
										/>
									</g>
								</g>
							</g>
							<g clipPath='url(#clipPath-13)'>
								<g id='g3284'>
									<g id='g3286'>
										<path
											id='path3298'
											d='M161.06,208.71q0,3.09-.05,6.2h0c0,4.47-.07,8.94,0,13.4h0c.12,9.37.48,18.73.86,28.09h0l0,.76h0c.19,4.39.45,8.77.69,13.15h0c.7,12.46.59,24.9.84,37.61h0c.08,4,.4,8,.59,12h0c.8,16.47.83,33,1.38,49.44h0c.12,3.64-.41,11.22-.21,16.64h0c11.43-19,29.51-50.7,51.9-86.34h0c1.35-2.14,1.29-3.63,0-5.76h0c-15.11-24-30.05-48.08-45.17-72.08h0c-5.46-8.69-7.74-14.14-10.79-18.85h0c.62,1,0,4.57,0,5.74'
											transform='translate(0)'
											fill='url(#linear-gradient-owl-12)'
										/>
									</g>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-14)'>
							<g id='g3320'>
								<g id='g3322'>
									<path
										id='path3330'
										d='M229.64,193.08c0,29.63,21.92,53.67,49,53.69h0c27.1,0,49.07-24,49.1-53.61h0c0-29.62-21.92-53.66-49-53.69h0c-27.1,0-49.06,24-49.08,53.61'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-13)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-15)'>
							<g id='g3340'>
								<g id='g3342'>
									<path
										id='path3350'
										d='M261.94,141.48a7.27,7.27,0,0,1,4.19-4.43h0c-15.78,3.17-31.08,17.32-33.48,33.72h0l.22-.11c11.19-11.94,23.71-16.08,39.77-16.54h0c-1.24-1.65-1.5-2.67-3.59-3.71h0c6.79,1,12.92,2.28,20,1.81h0c-.61-.38-1.22-.76-1.84-1.12h0c4.57,1.67,9.87,1.47,14.67,1.12h0a16.79,16.79,0,0,0-1.78-1.38h0c12.61,4,31.33.38,31.59-14.32h0c-6.51,3.73-11.95,7.76-20.07,7h0c-7.84-.77-14.31-4.31-22.34-4.61h0a26.41,26.41,0,0,1,5.47-2.57h0a20.74,20.74,0,0,0-8.54-1.77h0c-8.37,0-16.67,4.4-24.25,6.95'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-14)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-16)'>
							<g id='g3360'>
								<g id='g3362'>
									<path
										id='path3374'
										d='M239.17,197.41a32,32,0,0,0,32,32h0a32,32,0,0,0,32-32h0a32,32,0,0,0-32-32.05h0a32,32,0,0,0-32,32'
										transform='translate(0)'
										fill='url(#radial-gradient)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-17)'>
							<g id='g3396'>
								<g id='g3398'>
									<path
										id='path3406'
										d='M254.58,198a16.71,16.71,0,0,0,16.69,16.7h0A16.71,16.71,0,0,0,288,198.05h0a16.69,16.69,0,0,0-16.67-16.7h0A16.69,16.69,0,0,0,254.58,198'
										transform='translate(0)'
										fill='url(#radial-gradient-2)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-18)'>
							<g id='g3432'>
								<g id='g3434'>
									<path
										id='path3442'
										d='M175.07,139.47c-27.1,0-49,24.07-49,53.69h0c0,29.63,22,53.63,49.1,53.61h0c27.11,0,49-24.06,49-53.69h0c0-29.61-22-53.61-49.08-53.61h0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-15)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-19)'>
							<g id='g3452'>
								<g id='g3454'>
									<path
										id='path3462'
										d='M159.1,136.3a26.66,26.66,0,0,1,5.47,2.57h0c-8,.3-14.51,3.84-22.35,4.61h0c-8.12.8-13.56-3.23-20.08-7h0c.27,14.71,19,18.34,31.61,14.31h0a17.29,17.29,0,0,0-1.79,1.39h0c4.81.35,10.1.54,14.67-1.12h0c-.62.36-1.23.74-1.83,1.12h0c7.06.47,13.18-.79,20-1.81h0c-2.09,1-2.35,2.06-3.58,3.71h0c16.06.46,28.57,4.6,39.76,16.54h0l.22.11c-2.4-16.4-17.69-30.55-33.48-33.73h0a7.26,7.26,0,0,1,4.19,4.44h0c-7.58-2.55-15.88-6.95-24.25-6.95h0a20.66,20.66,0,0,0-8.53,1.77'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-16)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-20)'>
							<g id='g3472'>
								<g id='g3474'>
									<path
										id='path3486'
										d='M182.61,165.41a32,32,0,0,0-32,32.05h0a32,32,0,0,0,32,32h0a32,32,0,0,0,32-32h0a32,32,0,0,0-32-32h0'
										transform='translate(0)'
										fill='url(#radial-gradient-3)'
									/>
								</g>
							</g>
						</g>
						<g id='g3490'>
							<g id='g3496'>
								<path
									id='path3498'
									d='M182.62,167.29a30.14,30.14,0,1,0,30.15,30.12,30.16,30.16,0,0,0-30.15-30.12m0,64a33.89,33.89,0,1,1,33.85-33.92,33.93,33.93,0,0,1-33.85,33.92'
									transform='translate(0)'
									fill='#a67e53'
								/>
							</g>
						</g>
						<g clipPath='url(#clipPath-21)'>
							<g id='g3508'>
								<g id='g3510'>
									<path
										id='path3518'
										d='M182.53,181.35a16.68,16.68,0,0,0-16.66,16.7h0a16.7,16.7,0,0,0,16.68,16.68h0A16.71,16.71,0,0,0,199.24,198h0a16.69,16.69,0,0,0-16.7-16.68h0'
										transform='translate(0)'
										fill='url(#radial-gradient-4)'
									/>
								</g>
							</g>
						</g>
						<g id='g3416'>
							<path
								id='path3418'
								d='M274.69,187.58a4.58,4.58,0,0,1-4.82,4.27c-2.68,0-4.85-1.91-4.85-4.27a4.87,4.87,0,0,1,9.67,0'
								transform='translate(0)'
								fill='#efeceb'
							/>
						</g>
						<g clipPath='url(#clipPath-22)'>
							<g id='g3528'>
								<g id='g3530'>
									<path
										id='path3540'
										d='M97.38,41.32l-17,2.78c-2.51.24-7.24,2.51-7.24,9.29h0v57.08a3.7,3.7,0,0,0,3.72,3.7h0a3.68,3.68,0,0,0,3.69-3.7h0V53.39c0-.67.1-1.78.8-2h0l20.26-7.06s-1.95-3.08-3.95-3.08h0l-.3,0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-17)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-23)'>
							<g id='g3550'>
								<g id='g3552'>
									<path
										id='path3564'
										d='M80.64,46.57c-.19,0-5,.55-5,6.82h0v57.08a1.21,1.21,0,0,0,1.23,1.23h0a1.23,1.23,0,0,0,1.23-1.23h0V53.39c0-3.36,1.88-4.17,2.46-4.28h0l13.7-4.77Z'
										transform='translate(0)'
										fill='url(#radial-gradient-5)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-24)'>
							<g id='g3574'>
								<g id='g3576'>
									<path
										id='path3588'
										d='M65.36,189.78c0,3.46,23,3.65,23,0h0c0-26.73-5.15-79.31-11.5-79.31h0c-6.38,0-11.52,52.58-11.52,79.31'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-18)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-25)'>
							<g id='g3598'>
								<g id='g3600'>
									<path
										id='path3612'
										d='M67.39,188.67c.7.53,4.27,1.3,9.7,1.3h0c4.16,0,6.86-.43,8.07-.43h0c.46,0,.72.05.75.24h0c0-30.41-5.41-73.59-8.43-77h0c-4.25,3.41-9.66,46.58-10.09,75.9'
										transform='translate(0)'
										fill='url(#radial-gradient-6)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-26)'>
							<g id='g3622'>
								<g id='g3624'>
									<path
										id='path3634'
										d='M67.14,111.53a9.5,9.5,0,0,0,9.74,9.28h0a9.51,9.51,0,0,0,9.73-9.28h0a9.53,9.53,0,0,0-9.73-9.28h0a9.52,9.52,0,0,0-9.74,9.28'
										transform='translate(0)'
										fill='url(#radial-gradient-7)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-27)'>
							<g id='g3644'>
								<g id='g3646'>
									<path
										id='path3658'
										d='M69.61,111.53a7,7,0,0,0,7.27,6.8h0a7,7,0,0,0,7.24-6.8h0a7,7,0,0,0-7.24-6.8h0a7,7,0,0,0-7.27,6.8'
										transform='translate(0)'
										fill='url(#radial-gradient-8)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-28)'>
							<g id='g3668'>
								<g id='g3670'>
									<path
										id='path3682'
										d='M229.65,45.64l-.36,0h0l0,.05c-35.38.15-66.66,9.61-86.45,24.19h0L130,117.32c66.55,0,75.76,25.19,99.27,25.19h0c.11,0,.25-.14.36-.14h0c.14,0,.29.14.39.14h0c25.64,0,32.73-25.19,99.27-25.19h0L316.49,69.86c-19.75-14.58-51-24-86.41-24.19h0l0-.05-.39,0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-19)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-29)'>
							<g id='g3692'>
								<g id='g3694'>
									<path
										id='path3708'
										d='M229.65,45.64l-.36,0h0l0,.05c-35.38.15-66.66,9.61-86.45,24.19h0l-12.67,47L242,46.09c-3.92-.25-7.89-.4-11.93-.42h0l0-.05-.39,0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-20)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-30)'>
							<g id='g3718'>
								<g id='g3720'>
									<path
										id='path3732'
										d='M229.8,45.64l-.39,0h0l0,.05c-4,0-8,.17-11.92.42h0l111.86,70.79-12.67-47c-19.78-14.58-51.07-24-86.44-24.19h0l0-.05-.37,0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-21)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-31)'>
							<g id='g3742'>
								<g id='g3744'>
									<path
										id='path3758'
										d='M223.24,0a3,3,0,0,0-.57.09h0C209.05,2.09,76.88,53,72.27,55.27h0c-4.72,2.4-10.22,5.13-10.22,9.46h0c0,4.3,6.65,9,10.62,10.59h0c3.89,1.55,61.86,14.91,61.86,14.91H311.91s75.62-6.42,79.52-8h0c4-1.58,10.62-6.3,10.62-10.6h0c0-4.32-5.51-7.06-10.23-9.45h0c-4.61-2.29-154.43-60.12-168-62.12h0a4.41,4.41,0,0,0-.52-.09h0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-22)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-32)'>
							<g id='g3768'>
								<g id='g3770'>
									<path
										id='path3782'
										d='M223.2,3.7C210.8,5.54,80.06,55.52,73.77,58.6h0c-3.21,1.64-8.08,4.09-8.08,6.13h0c0,1.66,332.67,8.59,332.67,6.93h0c0-2-5.07-4.59-8.18-6.17h0c-8-3.91-154.86-60-166.94-61.79h0Z'
										transform='translate(0)'
										fill='url(#radial-gradient-9)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-33)'>
							<g id='g3792'>
								<g id='g3794'>
									<path
										id='path3808'
										d='M68.84,65l63.81,16.47,91.84.33V8Z'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-23)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-34)'>
							<g id='g3818'>
								<g id='g3820'>
									<path
										id='path3832'
										d='M224.49,81.82l92,.17,82.88-9.1L224.49,8Z'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-24)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-35)'>
							<g id='g3842'>
								<g id='g3844'>
									<path
										id='path3856'
										d='M230.55,47.67l-.37,0h0l0,.05c-37,.14-69.7,9.33-90.38,23.5h0l-13.38,46.08c69.58,0,79.22,24.46,103.8,24.46h0c.1,0,.25-.14.37-.14h0c.15,0,.3.14.41.14h0c26.8,0,34.21-24.46,103.78-24.46h0L321.34,71.2C300.69,57,268,47.84,231,47.7h0l0-.05-.41,0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-25)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-36)'>
							<g id='g3866'>
								<g id='g3868'>
									<path
										id='path3882'
										d='M230.55,47.67l-.37,0h0l0,.05c-37,.14-69.7,9.33-90.38,23.5h0l-13.25,45.65,117-68.74c-4.1-.24-8.24-.39-12.47-.41h0l0-.05-.41,0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-26)'
									/>
								</g>
							</g>
						</g>
						<g clipPath='url(#clipPath-37)'>
							<g id='g3892'>
								<g id='g3894'>
									<path
										id='path3906'
										d='M230.71,47.67l-.41,0h0l0,.05c-4.22,0-8.37.17-12.46.41h0l116.94,68.74L321.5,71.2C300.82,57,268.11,47.84,231.12,47.7h0l0-.05-.38,0'
										transform='translate(0)'
										fill='url(#linear-gradient-owl-27)'
									/>
								</g>
							</g>
						</g>
						<g id='g3916'>
							<path
								id='path3918'
								d='M187.47,187.58a4.58,4.58,0,0,1-4.83,4.27c-2.68,0-4.85-1.91-4.84-4.27a4.87,4.87,0,0,1,9.67,0'
								transform='translate(0)'
								fill='#efeceb'
							/>
						</g>
						<g id='g3378'>
							<g id='g3384'>
								<path
									id='path3386'
									d='M271.21,167.29a30.14,30.14,0,1,0,30.12,30.17,30.18,30.18,0,0,0-30.12-30.17m0,64a33.89,33.89,0,1,1,33.92-33.86,33.92,33.92,0,0,1-33.92,33.86'
									transform='translate(0)'
									fill='#a67e53'
								/>
							</g>
						</g>
						<rect width='459.94' height='386.02' fill='none' />
					</g>
				</g>
			</svg>
		);

	return (
		<svg
			id='svg-owl'
			className={className}
			ref={owlRef}
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			viewBox='0 0 764.61 193.85'
		>
			<defs>
				<clipPath id='clipPath-owl' transform='translate(0 -0.24)'>
					<path
						d='M0,0V194H764.61V0ZM353.43,126.87c-15.07,0-27.3-12.49-27.31-27.91A28.11,28.11,0,0,1,337,76.58a18.53,18.53,0,0,0,2.1.15,27.93,27.93,0,0,0-8.63,20.49c0,14.89,11,26.94,24.66,26.93s24.63-12.08,24.63-27a29.1,29.1,0,0,0-2.42-11.63,28.41,28.41,0,0,1,3.33,13.36C380.72,114.34,368.51,126.86,353.43,126.87Zm55.47,0c-15.08,0-27.29-12.53-27.28-27.95A28.34,28.34,0,0,1,385,85.56a28.92,28.92,0,0,0-2.41,11.63c0,14.88,11,27,24.63,27s24.64-12,24.66-26.93a28,28,0,0,0-8.63-20.49,18.19,18.19,0,0,0,2.09-.15A28.09,28.09,0,0,1,436.21,99C436.2,114.38,424,126.88,408.9,126.87Z'
						fill='none'
					/>
				</clipPath>
				<linearGradient
					id='linear-gradient-owl-owl'
					x1='-1439.82'
					y1='56.58'
					x2='-1376.26'
					y2='56.58'
					gradientTransform='translate(-1376.26 195.96) rotate(180)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.08' stopColor='#8b519f' />
					<stop offset='0.97' stopColor='#7c2987' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-2'
					x1='-832.56'
					y1='96.29'
					x2='-653.23'
					y2='116.19'
					gradientTransform='translate(-548.22 195.96) rotate(180)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.13' stopColor='#7c2987' />
					<stop offset='0.21' stopColor='#7e2e8a' />
					<stop offset='0.7' stopColor='#874799' />
					<stop offset='1' stopColor='#8b519f' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-3'
					x1='-705.38'
					y1='74.19'
					x2='-770.51'
					y2='-38.86'
					gradientTransform='translate(-548.22 195.96) rotate(180)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-4'
					x1='483.41'
					y1='97.01'
					x2='660.71'
					y2='116.69'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.16)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.04' stopColor='#29a1da' />
					<stop offset='0.42' stopColor='#59bcdc' />
					<stop offset='0.65' stopColor='#6cc7dd' />
					<stop offset='0.87' stopColor='#79cede' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-5'
					x1='607.31'
					y1='74.34'
					x2='542.07'
					y2='-38.91'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.16)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#79cede' />
					<stop offset='0.9' stopColor='#29a1da' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-6'
					x1='701'
					y1='56.81'
					x2='764.61'
					y2='56.81'
					xlinkHref='#linear-gradient-owl-owl-4'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-7'
					x1='414.01'
					y1='48.31'
					x2='458.89'
					y2='48.31'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#29a1da' />
					<stop offset='1' stopColor='#79cede' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-8'
					x1='416.04'
					y1='109.36'
					x2='497.49'
					y2='109.36'
					xlinkHref='#linear-gradient-owl-owl-7'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-9'
					x1='415.45'
					y1='109.74'
					x2='498.81'
					y2='109.74'
					xlinkHref='#linear-gradient-owl-owl-7'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-10'
					x1='387.79'
					y1='47.94'
					x2='415.69'
					y2='47.94'
					xlinkHref='#linear-gradient-owl-owl-7'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-11'
					x1='386.98'
					y1='48.75'
					x2='416.43'
					y2='48.75'
					xlinkHref='#linear-gradient-owl-owl-7'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-12'
					x1='267.19'
					y1='106.9'
					x2='348.07'
					y2='106.9'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-13'
					x1='310.16'
					y1='49.35'
					x2='351.22'
					y2='49.35'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-14'
					x1='309.38'
					y1='48.98'
					x2='352.06'
					y2='48.98'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-15'
					x1='347.96'
					y1='48.26'
					x2='376.5'
					y2='48.26'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-16'
					x1='348.04'
					y1='48.09'
					x2='376.72'
					y2='48.09'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-17'
					x1='370.34'
					y1='64.28'
					x2='381.78'
					y2='64.28'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-18'
					x1='-3329.44'
					y1='64.28'
					x2='-3318'
					y2='64.28'
					gradientTransform='translate(-2936.23 196.2) rotate(180)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.01' stopColor='#79cede' />
					<stop offset='0.24' stopColor='#6cc7dd' />
					<stop offset='0.65' stopColor='#4ab4dc' />
					<stop offset='1' stopColor='#29a1da' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-19'
					x1='-575.03'
					y1='425.25'
					x2='-525.74'
					y2='425.25'
					gradientTransform='matrix(-1, 0, 0, 1, -143.2, -328.07)'
					xlinkHref='#linear-gradient-owl-owl-7'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-20'
					x1='384.05'
					y1='119.3'
					x2='433.79'
					y2='119.3'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#79cede' />
					<stop offset='1' stopColor='#29a1da' />
				</linearGradient>
				<radialGradient
					id='radial-gradient'
					cx='249.55'
					cy='211.43'
					r='30.1'
					gradientTransform='translate(701.48 355.38) rotate(180) scale(1.18)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.38' stopColor='#fcd600' />
					<stop offset='0.62' stopColor='#f89e1b' />
				</radialGradient>
				<radialGradient
					id='radial-gradient-2'
					cx='248.86'
					cy='215.82'
					r='7.08'
					gradientTransform='translate(701.48 355.38) rotate(180) scale(1.18)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#2e2e2e' />
					<stop offset='1' stopColor='#402312' />
				</radialGradient>
				<linearGradient
					id='linear-gradient-owl-owl-21'
					x1='3104.55'
					y1='425.25'
					x2='3153.84'
					y2='425.25'
					gradientTransform='translate(-2774.04 -328.07)'
					xlinkHref='#linear-gradient-owl-owl-7'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-22'
					x1='-3295.52'
					y1='119.3'
					x2='-3245.78'
					y2='119.3'
					gradientTransform='translate(-2917.24 196.2) rotate(180)'
					xlinkHref='#linear-gradient-owl-owl-20'
				/>
				<radialGradient
					id='radial-gradient-3'
					cx='3361.6'
					cy='209.47'
					r='30.1'
					gradientTransform='matrix(1.18, 0, 0, -1.18, -3618.19, 354.9)'
					xlinkHref='#radial-gradient'
				/>
				<radialGradient
					id='radial-gradient-4'
					cx='3360.91'
					cy='213.86'
					r='7.08'
					gradientTransform='matrix(1.18, 0, 0, -1.18, -3618.19, 354.9)'
					xlinkHref='#radial-gradient-2'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-23'
					x1='311.09'
					y1='175.25'
					x2='311.09'
					y2='138.65'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#fbb03b' />
					<stop offset='0.98' stopColor='#c76e28' />
				</linearGradient>
				<radialGradient
					id='radial-gradient-5'
					cx='309.86'
					cy='156.82'
					r='12.4'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#radial-gradient'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-24'
					x1='300.02'
					y1='119.92'
					x2='311.58'
					y2='119.92'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.02' stopColor='#db7c27' />
					<stop offset='0.5' stopColor='#d18629' />
					<stop offset='1' stopColor='#cc7428' />
				</linearGradient>
				<radialGradient
					id='radial-gradient-6'
					cx='305.69'
					cy='119.94'
					r='14.12'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#radial-gradient'
				/>
				<radialGradient
					id='radial-gradient-7'
					cx='-81.9'
					cy='488.25'
					r='0.74'
					gradientTransform='matrix(9.96, 0, 0, -9.96, 1122.49, 4915.85)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#f89b1c' />
					<stop offset='0.98' stopColor='#d57527' />
				</radialGradient>
				<radialGradient
					id='radial-gradient-8'
					cx='-81.9'
					cy='488.15'
					r='0.55'
					gradientTransform='matrix(9.96, 0, 0, -9.96, 1122.49, 4915.85)'
					xlinkHref='#radial-gradient'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-25'
					x1='332.5'
					y1='148.74'
					x2='432.6'
					y2='148.74'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-26'
					x1='343.22'
					y1='131.15'
					x2='372.66'
					y2='182.14'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.08' stopColor='#8b519f' />
					<stop offset='0.57' stopColor='#823890' />
					<stop offset='0.97' stopColor='#7c2987' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-27'
					x1='496.09'
					y1='144.89'
					x2='553.61'
					y2='178.1'
					gradientTransform='translate(933.06 196.2) rotate(180)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#8b519f' />
					<stop offset='0.32' stopColor='#843d93' />
					<stop offset='0.68' stopColor='#7e2e8a' />
					<stop offset='0.97' stopColor='#7c2987' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-28'
					x1='298.36'
					y1='173.32'
					x2='469.14'
					y2='173.32'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.08' stopColor='#8b519f' />
					<stop offset='0.09' stopColor='#8a4e9d' />
					<stop offset='0.19' stopColor='#843d93' />
					<stop offset='0.31' stopColor='#7f328c' />
					<stop offset='0.49' stopColor='#7d2b88' />
					<stop offset='0.97' stopColor='#7c2987' />
				</linearGradient>
				<radialGradient
					id='radial-gradient-9'
					cx='-101.97'
					cy='512.46'
					r='9.79'
					gradientTransform='matrix(14.77, 0, 0, -14.77, 1884.89, 7546.32)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#fff' />
					<stop offset='0.12' stopColor='#e5cce4' />
					<stop offset='0.26' stopColor='#cb9aca' />
					<stop offset='0.39' stopColor='#b671b5' />
					<stop offset='0.52' stopColor='#a551a4' />
					<stop offset='0.65' stopColor='#9a3a98' />
					<stop offset='0.77' stopColor='#922c90' />
					<stop offset='0.87' stopColor='#90278e' />
				</radialGradient>
				<linearGradient
					id='linear-gradient-owl-owl-29'
					x1='301.89'
					y1='173.64'
					x2='380.18'
					y2='173.64'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.16)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0.33' stopColor='#7c2987' />
					<stop offset='0.93' stopColor='#8b519f' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-30'
					x1='401.65'
					y1='141.21'
					x2='429.59'
					y2='184.41'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.16)'
					gradientUnits='userSpaceOnUse'
				>
					<stop offset='0' stopColor='#8b519f' />
					<stop offset='0.33' stopColor='#833c93' />
					<stop offset='0.68' stopColor='#7e2e8a' />
					<stop offset='0.97' stopColor='#7c2987' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-owl-owl-31'
					x1='330.71'
					y1='148.38'
					x2='435.33'
					y2='148.38'
					gradientTransform='matrix(1, 0, 0, -1, 0, 196.2)'
					xlinkHref='#linear-gradient-owl-owl'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-32'
					x1='342.84'
					y1='130.34'
					x2='372.47'
					y2='181.65'
					xlinkHref='#linear-gradient-owl-owl-26'
				/>
				<linearGradient
					id='linear-gradient-owl-owl-33'
					x1='493.6'
					y1='144.53'
					x2='552.57'
					y2='178.58'
					xlinkHref='#linear-gradient-owl-owl-27'
				/>
			</defs>

			<g clipPath='url(#clipPath-owl)'>
				<g id='wing-left'>
					<polygon
						id='wing-left-tip'
						points='63.56 126.82 0.18 166.22 0 112.54 63.56 126.82'
						fill='url(#linear-gradient-owl-owl)'
					/>
					<polygon
						id='wing-left-top'
						points='267.44 53.38 0 112.54 309.41 123.96 267.44 53.38'
						fillRule='evenodd'
						fill='url(#linear-gradient-owl-owl-2)'
					/>
					<polygon
						id='wing-left-bottom'
						points='350.26 193.38 0 112.54 309.41 123.2 350.26 193.38'
						fillRule='evenodd'
						fill='url(#linear-gradient-owl-owl-3)'
					/>
				</g>
				<g id='wing-right'>
					<polygon
						id='wing-right-top'
						points='498.82 52.88 764.61 112.49 458.27 120.01 498.82 52.88'
						fillRule='evenodd'
						fill='url(#linear-gradient-owl-owl-4)'
					/>
					<polygon
						id='wing-right-bottom'
						points='414.01 193.64 764.61 112.49 457.98 119.7 414.01 193.64'
						fillRule='evenodd'
						fill='url(#linear-gradient-owl-owl-5)'
					/>
					<polygon
						id='wing-right-tip'
						points='701 127.21 764.18 166.22 764.61 112.49 701 127.21'
						fill='url(#linear-gradient-owl-owl-6)'
					/>
				</g>
				<g id='owl'>
					<g id='owl-body'>
						<path
							d='M458.89,119.28c-2.6,4.36-7.29,11.77-13.08,21.42-10.09,16.83-21.3,36.09-31.4,52.92-.18.3-.2,0-.4.27.07-.81.2-2.87.17-3.5a44.85,44.85,0,0,0-.12-6.35,82.64,82.64,0,0,1,.12-8.33c.57-2.82.18-5.66.24-8.49a81.46,81.46,0,0,1,.13-8.27,42.07,42.07,0,0,0,.13-9.25c.25-2.88-.1-5.78.25-8.67a40.29,40.29,0,0,0,.12-8.85c.25-3-.11-6,.24-9a33.21,33.21,0,0,0,.07-8.51c.4-3.82-.19-7.67.44-11.48l.67-1.07a1.81,1.81,0,0,1,1.9.1c4.26,2.13,8.86,3.45,13.22,5.35,5,2.16,9.86,4.48,15,6.29,4.26,1.67,6.89,2.86,10.34,4.27C458.86,119,458.17,118.75,458.89,119.28Z'
							transform='translate(0 -0.24)'
							fillRule='evenodd'
							fill='url(#linear-gradient-owl-owl-7)'
						/>
						<path
							d='M458.42,119.31c-1.6-1-4-1.58-5.86-2.39-6.54-2.86-13.46-4.92-19.94-8-4.29-2-8.83-3.52-13.26-5.25a13.07,13.07,0,0,0-3.32-1.26c.17-.82,1-1,1.56-1.36,25.93-15.31,51.55-30,77.5-45.3l2.39-1.38c-1,1.64-2.1,3.4-3,5C482.45,79.35,458.59,119.08,458.42,119.31Z'
							transform='translate(0 -0.24)'
							strokeMiterlimit='10'
							fillRule='evenodd'
							fill='url(#linear-gradient-owl-owl-8)'
							stroke='url(#linear-gradient-owl-owl-9)'
						/>
						<path
							d='M415.28,123.44v7.27a3.79,3.79,0,0,1-.34,1.85,49.91,49.91,0,0,1,0,8.73v6.92a3.81,3.81,0,0,1-.33,1.85,52.83,52.83,0,0,1,0,9.07v8.4c-.41,2.11-.08,4.24-.2,6.36a4.83,4.83,0,0,1-.15,2v8.41c-.41,2.43-.05,4.87-.2,7.3,0,.49.45,1.19-.46,1.44l-20.41-34c-1.66-2.77-3.29-5.56-5-8.32a1.44,1.44,0,0,1-.07-1.74q13.68-22.72,27.32-45.49a46.5,46.5,0,0,1-.07,11.55A45.33,45.33,0,0,1,415.28,123.44Z'
							transform='translate(0 -0.24)'
							fillRule='evenodd'
							fill='url(#linear-gradient-owl-owl-10)'
						/>
						<path
							d='M414,193.89l-6.75-11.18c-5.52-8.65-10.21-15.46-14.89-23.27l-1.94-3.25-3-5.07a2.15,2.15,0,0,1-.08-2.5q11.45-19,22.84-38l5.76-9.61.28,2.44a81.08,81.08,0,0,1,.06,10.8c-.06,3.6.12,7.2,0,10.79,0,1.64-.13,3.28-.13,4.92,0,6.31-.43,12.61-.5,18.92-.07,6.55-.21,13.1-.28,19.65,0,1.9-.06,3.81-.12,5.71-.12,3.42-.36,5.68-.55,10.07-.17,3.12-.18,2.89-.5,7.7C414.12,192.53,414,193.89,414,193.89Zm.75-87.77-3.14,5.23q-11.42,19-22.85,38c-.21.35-.19.52.08,1,1,1.69,2,3.38,3,5.09l1.94,3.24,14.05,23.42,5.28,8.81v-2.15a25.76,25.76,0,0,1,.18-4.48v-8.52l.08-.15a2,2,0,0,0,.09-1v-.72c.05-.78,0-1.55,0-2.36a19.1,19.1,0,0,1,.18-4v-8.39a51.45,51.45,0,0,0,0-8.95V150l.1-.19a3.25,3.25,0,0,0,.25-1.51v-7a49.29,49.29,0,0,0,0-8.59v-.21l.1-.19a3,3,0,0,0,.25-1.51v-7.3a43.73,43.73,0,0,0,0-8.25V115a44,44,0,0,0,.42-8.88Z'
							transform='translate(0 -0.24)'
							fill='url(#linear-gradient-owl-owl-11)'
						/>
						<path
							d='M309.64,124.66c-3.78-6.22-7.58-12.42-11.32-18.66Q282.9,80.34,267.53,54.65c-.12-.2-.22-.4-.34-.62.62-.39.93.13,1.28.34C293.53,69.08,317.91,82.3,343,97c1.34.79,4.12,1.89,5.07,3.15a6.18,6.18,0,0,1-1.43,1.89c-11.69,7.16-23.1,14.72-34.84,21.81C311.12,124.27,310.52,124.87,309.64,124.66Z'
							transform='translate(0 -0.24)'
							fillRule='evenodd'
							fill='url(#linear-gradient-owl-owl-12)'
						/>
						<path
							d='M310.16,124.43c6.84-4.22,13.71-8.42,20.52-12.68,4.77-3,9.46-6.06,14.19-9.08.95-.61,1.94-1.13,2.92-1.69a.66.66,0,0,1,0,.28,11.35,11.35,0,0,1,.91,4.6c0,3.32-.11,6.64.13,10a18.79,18.79,0,0,0,.05,6.17c.24,2.17.13,4.37.33,6.54a13.69,13.69,0,0,0,.06,5.52c.19,4.51.67,9,.5,13.53,0,1.56.06,3.13.14,4.69a18,18,0,0,0,.08,6.3c.21,2.37.45,4.77.44,7.13-.05,6.58.62,13.15.43,19.73-.07,2.2.63,4.39.11,6.6-.72,1.45-.89.1-1.11-.25l-6-10.12-33.29-56.29A8.44,8.44,0,0,1,310.16,124.43Z'
							transform='translate(0 -0.24)'
							fillRule='evenodd'
							fill='url(#linear-gradient-owl-owl-13)'
						/>
						<path
							d='M350.69,193.1c-.14.32-.09.16-.46.92-.08.16-.08.09-.38-.4-1-1.58-1.62-2.73-2.42-4.09-.47-.79-.7-1.22-1.15-2-.25-.44-.5-.88-.76-1.31s-.64-1.11-1-1.66c-.21-.35-.42-.7-.62-1.06-.11-.17-.21-.35-.31-.52s-.31-.38-.31-.51l-.15-.26c-11.1-18.76-21.51-37.21-32.6-56-.15-.25-1.15-2-1.15-2l.3-.27,6.17-3.8c4.72-2.89,9.58-5.89,14.35-8.87,2.95-1.84,5.92-3.76,8.79-5.62l5.39-3.46c.67-.42,1.36-.81,2-1.19l.91-.52.73-.13.44.45a1.15,1.15,0,0,1,.13.48,11.72,11.72,0,0,1,.91,4.75v2.31c0,2.5,0,5.08.14,7.6v.14a18.5,18.5,0,0,0,0,5.94v.07c.13,1.18.16,2.37.18,3.52s.06,2,.15,3v.2a12.93,12.93,0,0,0,0,5.22v.13c.06,1.37.14,2.77.23,4.12.19,3.09.39,6.3.28,9.47,0,1.33,0,2.7.1,4v.74a17.78,17.78,0,0,0,.07,6v.1l.06.67c.19,2.14.39,4.36.38,6.54,0,3,.11,6,.23,8.86.15,3.56.31,7.24.2,10.88a19.85,19.85,0,0,0,.15,2.44c.06.62.19,1.23.21,1.86a1.85,1.85,0,0,1-.4,1.15,2.09,2.09,0,0,0-.59,1A2.37,2.37,0,0,0,350.69,193.1Zm-39.52-68.21.13.27,33.3,56.28.15.26c1.83,3.09,3.72,6.29,5.62,9.42a14.9,14.9,0,0,0-.13-2.91,21.26,21.26,0,0,1-.16-2.64c.11-3.58,0-7.24-.19-10.77-.12-2.92-.25-6-.23-8.93,0-2.11-.56-14-.56-14-.07-1.36-.18-10.43-.37-13.49-.09-1.33-.17-2.71-.23-4.07a14.33,14.33,0,0,1-.07-5.65c-.09-1-.11-2-.14-3s-.06-2.26-.18-3.37a20.24,20.24,0,0,1-.06-6.31c-.17-2.56-.15-5.14-.13-7.64V106a11.09,11.09,0,0,0-.61-3.7h0c-.65.36-1.32.74-1.95,1.14L340,106.93c-2.88,1.86-5.85,3.78-8.81,5.63-4.77,3-9.65,6-14.36,8.88Z'
							transform='translate(0 -0.24)'
							fill='url(#linear-gradient-owl-owl-14)'
						/>
						<path
							d='M348.49,129.2c-.15-2.43-.05-4.39-.13-6.57a22.37,22.37,0,0,1,0-5.78c-.65-2.75-.31-5.55-.28-8.32v-6.38c2.33,2.77,3.49,6.24,5.38,9.26,7.53,12,15,24.15,22.5,36.21a2.33,2.33,0,0,1,0,2.89c-11.15,17.9-20,34.13-25.67,43.21-.31-2.6,0-6.37-.08-8.2-.23-6.85-.17-13.72-.47-20.56-.11-2.26-.24-4.52-.32-6.78-.1-2.51-.4-7.54-.35-8.46,0-5.45-.38-10.31-.38-15.47C348.65,132.35,348.58,131,348.49,129.2Z'
							transform='translate(0 -0.24)'
							fillRule='evenodd'
							fill='url(#linear-gradient-owl-owl-15)'
						/>
						<path
							d='M348.5,129.37V129c-.19-4.7-.37-9.4-.43-14.11V105c0-.59.31-2.39,0-2.88,1.53,2.36,2.68,5.1,5.42,9.46,7.59,12.06,15.1,24.16,22.69,36.21a2.34,2.34,0,0,1,0,2.9c-11.24,17.89-20.32,33.82-26.07,43.36-.09-2.72.17-6.53.11-8.36-.28-8.28-.29-16.56-.69-24.83-.1-2-.26-4-.3-6-.12-6.39-.07-12.64-.42-18.89C348.73,133.78,348.6,131.57,348.5,129.37Z'
							transform='translate(0 -0.24)'
							fillRule='evenodd'
							fill='url(#linear-gradient-owl-owl-16)'
						/>
					</g>
					<g id='owl-face'>
						<g id='nose'>
							<path
								d='M381.77,117.37v29.1c-1.1-1.82-2.09-3.75-3.08-5.59-2.73-5-5.42-10.12-8.13-15.18-.18-.34-.46-.62.08-1C374.34,122.32,378.09,119.8,381.77,117.37Z'
								transform='translate(0 -0.24)'
								fillRule='evenodd'
								fill='url(#linear-gradient-owl-owl-17)'
							/>
							<path
								d='M381.78,117.37v29.1c1.1-1.82,2.09-3.75,3.08-5.59,2.73-5,5.42-10.12,8.13-15.18.18-.34.46-.62-.08-1C389.21,122.32,385.46,119.8,381.78,117.37Z'
								transform='translate(0 -0.24)'
								fillRule='evenodd'
								fill='url(#linear-gradient-owl-owl-18)'
							/>
						</g>
						<path
							d='M431.83,97.22c0-14.88-11-26.95-24.63-27s-24.65,12-24.66,26.93,11,27,24.63,27S431.81,112.11,431.83,97.22Z'
							transform='translate(0 -0.24)'
							fill='url(#linear-gradient-owl-owl-19)'
						/>
						<path
							id='brow-right'
							d='M384.05,86c1.2-8.24,8.89-15.35,16.82-16.94a3.6,3.6,0,0,0-2.11,2.22c5.11-1.71,10.85-5.1,16.47-2.6A14.19,14.19,0,0,0,412.48,70c4,.15,7.29,1.93,11.22,2.32s6.81-1.62,10.09-3.5c-.16,8.75-13.32,9.7-19,5.69a11.61,11.61,0,0,1,4,2.2c-2.93.21-6.23.31-8.8-1.24a12.35,12.35,0,0,1,2.36,1.24c-3.55.24-6.63-.4-10-.91,1,.52,1.18,1,1.8,1.86-8.06.24-14.35,2.32-20,8.31'
							transform='translate(0 -0.24)'
							fill='url(#linear-gradient-owl-owl-20)'
						/>
						<path
							d='M387.33,99.36a16.08,16.08,0,1,0,16.09-16.07,16.08,16.08,0,0,0-16.09,16.07Z'
							transform='translate(0 -0.24)'
							fill='url(#radial-gradient)'
						/>
						<path
							d='M403.39,116.39a17,17,0,1,1,17-17h0A17,17,0,0,1,403.39,116.39Zm0-32.16a15.14,15.14,0,1,0,15.13,15.15A15.14,15.14,0,0,0,403.39,84.23Z'
							transform='translate(0 -0.24)'
							fill='#a67e53'
						/>
						<path
							d='M395.06,99.67a8.39,8.39,0,1,0,8.4-8.38A8.39,8.39,0,0,0,395.06,99.67Z'
							transform='translate(0 -0.24)'
							fill='url(#radial-gradient-2)'
						/>
						<path
							d='M405.17,94.42a2.45,2.45,0,1,1-2.74-2.12l.32,0A2.29,2.29,0,0,1,405.17,94.42Z'
							transform='translate(0 -0.24)'
							fill='#efeceb'
						/>
						<path
							d='M330.51,97.22c0-14.88,11-26.95,24.62-27s24.66,12,24.67,26.93-11,27-24.63,27S330.52,112.11,330.51,97.22Z'
							transform='translate(0 -0.24)'
							fill='url(#linear-gradient-owl-owl-21)'
						/>
						<path
							id='brow-left'
							d='M378.28,86c-1.2-8.24-8.88-15.35-16.81-16.94a3.63,3.63,0,0,1,2.1,2.22c-5.1-1.71-10.85-5.1-16.47-2.6A13.91,13.91,0,0,1,349.85,70c-4,.15-7.29,1.93-11.22,2.32s-6.81-1.62-10.09-3.5c.16,8.75,13.32,9.7,19,5.69a11.7,11.7,0,0,0-4,2.2c2.93.21,6.23.31,8.8-1.24A12,12,0,0,0,350,76.66c3.54.24,6.62-.4,10-.91-1.05.52-1.18,1-1.8,1.86,8.07.24,14.35,2.32,20,8.31'
							transform='translate(0 -0.24)'
							fill='url(#linear-gradient-owl-owl-22)'
						/>
						<path
							d='M375,99.36a16.08,16.08,0,1,1-16.09-16.07A16.08,16.08,0,0,1,375,99.36Z'
							transform='translate(0 -0.24)'
							fill='url(#radial-gradient-3)'
						/>
						<path
							d='M358.94,116.39a17,17,0,1,1,17-17h0A17,17,0,0,1,358.94,116.39Zm0-32.16a15.14,15.14,0,1,0,15.14,15.14h0A15.15,15.15,0,0,0,358.94,84.23Z'
							transform='translate(0 -0.24)'
							fill='#a67e53'
						/>
						<path
							d='M367.27,99.67a8.39,8.39,0,1,1-8.4-8.38A8.39,8.39,0,0,1,367.27,99.67Z'
							transform='translate(0 -0.24)'
							fill='url(#radial-gradient-4)'
						/>
						<path
							d='M361.36,94.42a2.45,2.45,0,1,1-2.74-2.12l.31,0A2.29,2.29,0,0,1,361.36,94.42Z'
							transform='translate(0 -0.24)'
							fill='#efeceb'
						/>
						<g id='toca'>
							<g id='owl-toca-string'>
								<path
									d='M305.81,57.55a1.86,1.86,0,0,1-1.87-1.85h0V27c0-3.41,2.37-4.55,3.64-4.67L316.11,21c1.05-.17,2.13,1.53,2.13,1.53L308.06,26c-.35.09-.4.64-.4,1V55.69a1.85,1.85,0,0,1-1.84,1.86Z'
									transform='translate(0 -0.24)'
									fill='url(#linear-gradient-owl-owl-23)'
								/>
								<path
									d='M305.81,56.31a.61.61,0,0,1-.62-.6V27c0-3.15,2.41-3.42,2.51-3.43l6.84-1.12-6.88,2.4c-.29,0-1.24.46-1.24,2.15V55.69a.61.61,0,0,1-.6.62Z'
									transform='translate(0 -0.24)'
									fill='url(#radial-gradient-5)'
								/>
								<path
									d='M311.58,95.53c0,1.83-11.56,1.74-11.56,0,0-13.43,2.58-39.84,5.79-39.84S311.58,82.1,311.58,95.53Z'
									transform='translate(0 -0.24)'
									fill='url(#linear-gradient-owl-owl-24)'
								/>
								<path
									d='M305.91,95.62A12.59,12.59,0,0,1,301,95c.22-14.73,2.93-36.41,5.07-38.13,1.51,1.72,4.23,23.41,4.23,38.69,0-.1-.14-.13-.37-.13-.61,0-2,.22-4.06.22Z'
									transform='translate(0 -0.24)'
									fill='url(#radial-gradient-6)'
								/>
								<path
									d='M310.69,56.22a4.89,4.89,0,1,1-5.11-4.65h.23a4.77,4.77,0,0,1,4.88,4.66Z'
									transform='translate(0 -0.24)'
									fill='url(#radial-gradient-7)'
								/>
								<path
									d='M305.81,59.64a3.42,3.42,0,1,1,3.63-3.42A3.55,3.55,0,0,1,305.81,59.64Z'
									transform='translate(0 -0.24)'
									fill='url(#radial-gradient-8)'
								/>
							</g>
							<path
								d='M432.6,59.13l-6.44-23.84c-9.92-7.32-25.63-12.08-43.4-12.15h-.38C364.61,23.21,348.89,28,339,35.29L332.5,59.13c33.43,0,38.05,12.65,49.86,12.65.06,0,.13-.07.18-.07s.15.07.2.07C395.61,71.78,399.18,59.13,432.6,59.13Z'
								transform='translate(0 -0.24)'
								fill='url(#linear-gradient-owl-owl-25)'
							/>
							<path
								d='M388.75,23.35c-2-.12-4-.2-6-.21h-.38c-17.77.07-33.49,4.83-43.43,12.15l-6.36,23.62Z'
								transform='translate(0 -0.24)'
								fill='url(#linear-gradient-owl-owl-26)'
							/>
							<path
								d='M376.42,23.35c2-.12,4-.2,6-.21h.38c17.77.07,33.48,4.83,43.42,12.15l6.36,23.62Z'
								transform='translate(0 -0.24)'
								fill='url(#linear-gradient-owl-owl-27)'
							/>
							<path
								d='M464,31.45c-2.32-1.15-77.57-30.2-84.41-31.2a1.22,1.22,0,0,0-.27,0,1.31,1.31,0,0,0-.28,0c-6.85,1-73.23,26.57-75.55,27.71s-5.13,2.58-5.13,4.75,3.34,4.54,5.33,5.33,31.08,7.48,31.08,7.48h89.09s38-3.22,39.94-4,5.34-3.16,5.34-5.33S466.37,32.65,464,31.45Z'
								transform='translate(0 -0.24)'
								fill='url(#linear-gradient-owl-owl-28)'
							/>
							<path
								d='M300.19,32.71c0-1,2.44-2.25,4.06-3.07C307.41,28.09,373.07,3,379.3,2.06h0c6.07.9,79.83,29.07,83.85,31,1.57.79,4.12,2.07,4.12,3.09C467.29,37,300.19,33.55,300.19,32.71Z'
								transform='translate(0 -0.24)'
								fill='url(#radial-gradient-9)'
							/>
							<path
								d='M333.38,41.13c-8.16-2-28.82-6.76-30.57-7.36a8.26,8.26,0,0,1-1.48-.83c.62-.34,1.28-.66,1.67-.86,3.6-1.74,71.29-27,77.07-27.88h.13c6.11,1.29,81.41,29.66,85.3,31.52.33.16,1,.51,1.69.88a10.39,10.39,0,0,1-1.58.84c-1.65.35-22,2.25-39.9,3.73Z'
								transform='translate(0 -0.24)'
								fill='none'
							/>
							<polygon
								points='380.18 41.06 380.18 3.98 301.89 32.62 334 40.89 380.18 41.06'
								fill='url(#linear-gradient-owl-owl-29)'
							/>
							<polygon
								points='380.18 3.98 380.18 41.06 426.28 41.14 467.97 36.57 380.18 3.98'
								fillRule='evenodd'
								fill='url(#linear-gradient-owl-owl-30)'
							/>
							<path
								d='M435.33,59.11,428.6,36c-10.37-7.11-26.8-11.73-45.38-11.8h-.39c-18.58.07-35,4.69-45.4,11.8l-6.72,23.15c35,0,39.79,12.29,52.14,12.29,0,0,.13-.07.18-.07s.16.07.21.07C396.66,71.4,400.39,59.11,435.33,59.11Z'
								transform='translate(0 -0.24)'
								fill='url(#linear-gradient-owl-owl-31)'
							/>
							<path
								d='M389.48,24.37c-2.06-.13-4.14-.2-6.26-.21h-.39c-18.58.07-35,4.69-45.4,11.8l-6.66,22.93Z'
								transform='translate(0 -0.24)'
								fill='url(#linear-gradient-owl-owl-32)'
							/>
							<path
								d='M376.59,24.37c2.06-.13,4.14-.2,6.26-.21h.39c18.58.07,35,4.69,45.4,11.8l6.65,22.93Z'
								transform='translate(0 -0.24)'
								fill='url(#linear-gradient-owl-owl-33)'
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

Owl.propTypes = {
	spread: PropTypes.bool,
	className: PropTypes.string,
};

Owl.defaultProps = {
	spread: false,
	className: '',
};

export default Owl;
