import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import generateRating from '../../controllers/generateRating';
import Star from '../Assets/Star';

const RatingIndicator = ({ rating, size, showRating }) => {
	const [ratingToShow, setRatingToShow] = useState([]);

	useEffect(() => {
		setRatingToShow(generateRating(rating));
		// eslint-disable-next-line
	}, []);

	return (
		<div className={`rating-indicator ${size}`}>
			{ratingToShow.map((rate, index) =>
				rate === 1 || rate >= 5 ? (
					<Star key={index} />
				) : rate < 5 && rate !== 0 ? (
					<Star key={index} half />
				) : (
					<Star key={index} empty />
				)
			)}
			{showRating && <p>{rating}</p>}
		</div>
	);
};

RatingIndicator.propTypes = {
	rating: PropTypes.number.isRequired,
	size: PropTypes.string,
	showRating: PropTypes.bool,
};

RatingIndicator.defaultProps = {
	size: 'm',
	showRating: false,
};

export default RatingIndicator;
