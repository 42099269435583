import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Generic/Box';
import Text from '../Generic/Text';
import firstUp from '../../controllers/firstUp';
import TYPES from '../../modules/Filter/filterTypes';
import FilterCheckbox from './FilterCheckbox';
import FilterRadio from './FilterRadio';
import FilterInterval from './FilterInterval';

const FilterItem = ({ content, onClick }) => {
  const { type } = content;

  // TITLE
  if (type === TYPES.TITLE || type === TYPES.TITLE_CLEAR) {
    const { icon, header } = content;

    return (
      <Box
        className={`fs-xs filter-item filter-item-title ${
          type === TYPES.TITLE_CLEAR && 'clr-red'
        }`}
        onClick={onClick}>
        <div className='filter-title'>
          <Text icon={icon}>{firstUp(header)}</Text>
        </div>
      </Box>
    );
  }

  // CHECKBOX
  if (type === TYPES.CHECKBOX) {
    const { header, list, id } = content;

    return <FilterCheckbox header={header} list={list} id={id} />;
  }

  // RADIO
  if (type === TYPES.RADIO) {
    const { header, list, id } = content;

    return <FilterRadio header={header} list={list} id={id} />;
  }

  // INTERVAL
  if (type === TYPES.INTERVAL) {
    const { header, id } = content;

    return <FilterInterval header={header} id={id} />;
  }

  return (
    <Box className={`fs-xs filter-item`}>
      <Text>No type passed!</Text>
    </Box>
  );
};

FilterItem.propTypes = {
  content: PropTypes.object.isRequired,
  state: PropTypes.shape({
    radio: PropTypes.shape({
      checkedRadio: PropTypes.object.isRequired,
      setCheckedRadio: PropTypes.func.isRequired,
    }).isRequired,
    checkbox: PropTypes.shape({
      checkedCheckbox: PropTypes.object.isRequired,
      setCheckedCheckbox: PropTypes.func.isRequired,
    }).isRequired,
  }),
  onClick: PropTypes.func,
};

FilterItem.defaultProps = {
  onClick: () => {},
};

export default FilterItem;
