import React, { useContext } from 'react';
import Filter from '../../modules/Filter/Filter';
import { AssetContext } from '../../contexts/asset/AssetContext';
import CardsContainer from '../../modules/CardsContainer/CardsContainer';
import PortraitCard from '../../components/Generic/PortraitCard';
import LandscapeCard from '../../components/Generic/LandscapeCard';
import UtilityBar from '../../modules/UtilityBar/UtilityBar';
import { FilterContext } from '../../contexts/filter/FilterContext';

const Explore = () => {
  const { userFemale } = useContext(AssetContext);

  const { orientation } = useContext(FilterContext);

  const user = {
    photo: userFemale,
    rating: 2.2,
    fullName: 'John Doe',
    creSession: 10,
    discipline: 'Arabic',
    level: 5,
    rank: 'gold',
    disciples: 12,
    role: 'mentor',
    experience: 5,
    certified: true,
    avFor: 1,
    teachingLang: ['english', 'arabic'],
    hours: 72,
    sessionH: 32,
    description:
      'This is some dummy text to fill out the field. This is some dummy text to fill out the field.',
    video: 'sadklihfsdnaiofsd',
    mentor: [{ identifier: 'TX00005', uid: '1234' }],
    born: 'Oct 25, 1994',
    age: '25',
    gender: 'Male',
    from: 'United States of America',
    residence: 'Romania, Cluj-Napoca',
    timezone: 'UTC +3',
    createdAt: '15 June 2021',
    // disciple: [{ identifier: 'TX00005', uid: '1234' }],
  };

  return (
    <main className='explore-page'>
      <div className='explore-page-content container'>
        <Filter />
        <div className='explore-page-entries'>
          <UtilityBar />
          <CardsContainer orientation={orientation}>
            {orientation === 'portrait' ? (
              <>
                <PortraitCard user={user} />
                <PortraitCard user={user} />
                <PortraitCard user={user} />
                <PortraitCard user={user} />
              </>
            ) : (
              <>
                <LandscapeCard user={user} />
                <LandscapeCard user={user} />
                <LandscapeCard user={user} />
                <LandscapeCard user={user} />
              </>
            )}
          </CardsContainer>
        </div>
      </div>
    </main>
  );
};

export default Explore;
