import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import firstUp from '../../controllers/firstUp';
import { Link } from 'react-router-dom';
import Text from '../Generic/Text';
import { LangContext } from '../../contexts/language/LangContext';
import Logo from '../Assets/Logo';

const UserProfileNav = ({ id, list }) => {
  const {
    lang,
    lang: { TX00800: generalLang },
  } = useContext(LangContext);

  return (
    <header className='user-profile-navbar'>
      <Logo stroke />
      <nav>
        <ul>
          <Text li className='selected'>
            <Link to={`/user_profile/${id}`}>
              {firstUp(generalLang.content)}
            </Link>
          </Text>

          {list &&
            list.map((entry, index) => (
              <Text li key={'keyUserProfileNav_' + index}>
                <Link to={`/user_profile/${id}/${entry.uid}`}>
                  {firstUp(lang[entry.identifier].content)}
                </Link>
              </Text>
            ))}
        </ul>
      </nav>
    </header>
  );
};

UserProfileNav.propTypes = {
  id: PropTypes.string.isRequired,
  list: PropTypes.array,
};

export default UserProfileNav;
