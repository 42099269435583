import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import { LangContext } from '../../contexts/language/LangContext';
import firstUp from '../../controllers/firstUp';
import Form from '../Form/Form';
import Button from '../Generic/Button';

const AddCreditcard = ({ setShowModal }) => {
	const [add, setAdd] = useState({
		cardNo: '',
		expDate: '',
		cardHolder: '',
		cardTitle: '',
	});

	const { cardNo, cardHolder, expDate, cardTitle } = add;

	const {
		lang: {
			TX00252: { content: saveLang },
			TX00298: { content: cardNumberLang },
			TX00299: { content: expireDateLang },
			TX00301: { content: cardholderLang },
			TX00302: { content: cardTitleLang },
		},
	} = useContext(LangContext);

	const addFields = [
		{
			label: firstUp(cardNumberLang),
			name: 'cardNo',

			text: cardNo,
			type: 'text',
		},
		{
			label: firstUp(expireDateLang),
			name: 'expDate',

			text: expDate,
			type: 'text',
		},
		{
			label: firstUp(cardholderLang),
			name: 'cardHolder',

			text: cardHolder,
			type: 'text',
		},
		{
			label: firstUp(cardTitleLang),
			name: 'cardTitle',

			text: cardTitle,
			type: 'text',
		},
	];

	const handleChange = (e) =>
		setAdd({ ...add, [e.target.name]: e.target.value });

	const handleClose = () => setShowModal(false);

	const handleSubmit = () => {
		// save creditcard
		setShowModal(false);
	};

	return (
		<Modal className='add-creditcard' onClose={handleClose}>
			<Form
				fields={addFields}
				edit
				handleChange={handleChange}
				handleSubmit={handleSubmit}
			>
				<Button submit primary full text={saveLang} onClick={handleSubmit} />
			</Form>
		</Modal>
	);
};

AddCreditcard.propTypes = {
	setShowModal: PropTypes.func.isRequired,
};

export default AddCreditcard;
