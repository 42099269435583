import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';
import Text from '../Generic/Text';
import Button from '../Generic/Button';
import { LocContext } from '../../contexts/location/LocContext';
import firstUp from '../../controllers/firstUp';

const DigitalSignature = ({ className }) => {
	const {
		lang: {
			TX00282: { content: addLang },
			TX00292: { content: digitalSignatureLang },
			TX00293: { content: thisWillHelpLang },
			// TX00295: { content: uploadLang },
			// TX00296: { content: drawLang },
		},
	} = useContext(LangContext);

	const { path } = useContext(LocContext);

	return (
		<div className={`digital-signature ${className}`}>
			<div className='digital-signature-content'>
				<h5>{firstUp(digitalSignatureLang)}</h5>
				<Text>{firstUp(thisWillHelpLang)}</Text>
				<Button primary to={`${path}`} text={`${addLang}`} />
			</div>
		</div>
	);
};

DigitalSignature.propTypes = {
	className: PropTypes.string,
};

DigitalSignature.defaultProps = {
	className: '',
};

export default DigitalSignature;
