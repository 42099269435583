import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';
import ValuesSection from './ValuesSection';

const Values = ({ className, mentor, disciple }) => {
	const {
		lang: {
			TX00195: title_1,
			TX00200: title_2,
			TX00205: title_3,

			// Education page
			TX00215: discipleValuesText_1,
			TX00216: discipleValuesText_2,
			TX00217: discipleValuesText_3,
			TX00218: discipleValuesText_4,

			TX00219: discipleValuesText_5,
			TX00220: discipleValuesText_6,
			TX00221: discipleValuesText_7,
			TX00222: discipleValuesText_8,

			TX00223: discipleValuesText_9,
			TX00224: discipleValuesText_10,
			TX00225: discipleValuesText_11,

			// Mentoring page
			TX00196: mentorValuesText_1,
			TX00197: mentorValuesText_2,
			TX00198: mentorValuesText_3,
			TX00199: mentorValuesText_4,

			TX00201: mentorValuesText_5,
			TX00202: mentorValuesText_6,
			TX00203: mentorValuesText_7,
			TX00204: mentorValuesText_8,

			TX00206: mentorValuesText_9,
			TX00207: mentorValuesText_10,
			TX00208: mentorValuesText_11,
		},
	} = useContext(LangContext);

	const {
		milestonesDisciple: discipleValuesImg_1,
		topDisciples: discipleValuesImg_2,
		discipleRank_6: discipleValuesImg_3,

		milestonesMentor: mentorValuesImg_1,
		topMentors: mentorValuesImg_2,
		mentorRank_6: mentorValuesImg_3,
	} = useContext(AssetContext);

	const valuesSetMentor_1 = {
		list: [
			mentorValuesText_1.content,
			mentorValuesText_2.content,
			mentorValuesText_3.content,
			mentorValuesText_4.content,
		],
		img: mentorValuesImg_1,
		title: title_1.content,
	};
	const valuesSetMentor_2 = {
		list: [
			mentorValuesText_5.content,
			mentorValuesText_6.content,
			mentorValuesText_7.content,
			mentorValuesText_8.content,
		],
		img: mentorValuesImg_2,
		title: title_2.content,
	};
	const valuesSetMentor_3 = {
		list: [
			mentorValuesText_9.content,
			mentorValuesText_10.content,
			mentorValuesText_11.content,
		],
		img: mentorValuesImg_3,
		title: title_3.content,
	};

	const valuesSetDisciple_1 = {
		list: [
			discipleValuesText_1.content,
			discipleValuesText_2.content,
			discipleValuesText_3.content,
			discipleValuesText_4.content,
		],
		img: discipleValuesImg_1,
		title: title_1.content,
	};
	const valuesSetDisciple_2 = {
		list: [
			discipleValuesText_5.content,
			discipleValuesText_6.content,
			discipleValuesText_7.content,
			discipleValuesText_8.content,
		],
		img: discipleValuesImg_2,
		title: title_2.content,
	};
	const valuesSetDisciple_3 = {
		list: [
			discipleValuesText_9.content,
			discipleValuesText_10.content,
			discipleValuesText_11.content,
		],
		img: discipleValuesImg_3,
		title: title_3.content,
	};

	const valuesMentor = [
		valuesSetMentor_1,
		valuesSetMentor_2,
		valuesSetMentor_3,
	];

	const valuesDisciple = [
		valuesSetDisciple_1,
		valuesSetDisciple_2,
		valuesSetDisciple_3,
	];

	return (
		<section className={`values container ${className}`}>
			{mentor &&
				valuesMentor.map((entry, index) => (
					<ValuesSection
						key={'valueMentor_' + index + '_key'}
						mentor
						right={index % 2 === 1 && true}
						content={entry}
					/>
				))}
			{disciple &&
				valuesDisciple.map((entry, index) => (
					<ValuesSection
						key={'valueDisciple_' + index + '_key'}
						disciple
						right={index % 2 === 1 && true}
						content={entry}
					/>
				))}
		</section>
	);
};

Values.propTypes = {
	className: PropTypes.string,
	mentor: PropTypes.bool,
	disciple: PropTypes.bool,
};

Values.defaultProps = {
	className: '',
	mentor: false,
	disciple: false,
};

export default Values;
