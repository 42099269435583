import { useContext } from 'react';
import { LOCATIONS } from '../contexts/location/types';
import { AssetContext } from '../contexts/asset/AssetContext';
import { LangContext } from '../contexts/language/LangContext';

const getSidebarContent = (location) => {
	const {
		accountIcon,
		agendaIcon_org,
		mentorIcon,
		discipleIcon,
		medalIcon_org,
		messagesIcon_org,
		eventsIcon_org,
		activityIcon,
		walletIcon_org,
		shareIcon,
		supportIcon,
		infoIcon_org,
	} = useContext(AssetContext);

	const {
		lang: {
			TX00055: { content: creLang },
			TX00258: { content: cardsLang },
			TX00259: { content: historyLang },
			TX00234: { content: supportLang },
			TX00236: { content: infoLang },
			TX00237: { content: walletLang },
			TX00238: { content: shareLang },
			TX00051: { content: mentoringLang },
			TX00050: { content: educationLang },
			TX00354: { content: rankLang },
		},
	} = useContext(LangContext);

	const account = [
		{
			icon: infoIcon_org,
			text: infoLang,
		},
		{
			icon: walletIcon_org,
			text: walletLang,
			submenu: [creLang, cardsLang, historyLang],
		},
		{
			icon: shareIcon,
			text: shareLang,
		},
		{
			icon: supportIcon,
			text: supportLang,
		},
	];

	const profile = [
		{
			icon: accountIcon,
			text: 'Overview',
		},
		{
			icon: agendaIcon_org,
			text: 'Agenda',
		},
		{
			icon: mentorIcon,
			text: mentoringLang,
		},
		{
			icon: discipleIcon,
			text: educationLang,
		},
		{
			icon: medalIcon_org,
			text: rankLang,
		},
		{
			icon: messagesIcon_org,
			text: 'Messages',
		},
		{
			icon: eventsIcon_org,
			text: 'Events',
		},
		{
			icon: activityIcon,
			text: 'Activity',
		},
	];

	switch (location) {
		case LOCATIONS.ACCOUNT:
			return account;
		case LOCATIONS.PROFILE:
			return profile;
		default:
			break;
	}
};

export default getSidebarContent;
