// eslint-disable-next-line
module.exports = {
  SET_SELECTED: 'SET_SELECTED',
  REMOVE_SELECTED: 'REMOVE_SELECTED',
  SET_CHECKBOX: 'SET_CHECKBOX',
  SET_RADIO: 'SET_RADIO',
  SET_INTERVAL: 'SET_INTERVAL',
  CLEAR_FILTER: 'CLEAR_FILTER',
  SET_ORIENTATION: 'SET_ORIENTATION',
  SET_PREFERENCES: 'SET_PREFERENCES',
};
