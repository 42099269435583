import PropTypes from 'prop-types';
import firstUp from './firstUp';

const getFilterDefs = (defs) => {
  let filters = {};

  defs.map((def) =>
    def.id !== 'category' && def.id !== 'avFor'
      ? def.list.map(
          (entry) =>
            (filters = {
              ...filters,
              [def.id]: {
                ...filters[`${def.id}`],
                [`${def.id}${firstUp(entry)}`]: '',
              },
            })
        )
      : (filters = { ...filters, [def.id]: '' })
  );

  return filters;
};

getFilterDefs.propTypes = {
  defs: PropTypes.arrayOf([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      list: PropTypes.arrayOf([PropTypes.string]).isRequired,
    }),
  ]).isRequired,
};

export default getFilterDefs;
