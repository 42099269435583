import React from 'react';
import PropTypes from 'prop-types';
import FormFields from './FormFields';
import Detail from '../Generic/Detail';

const Form = ({
	children,
	fields,
	edit,
	handleSubmit,
	handleChange,
	withSubmit,
}) => {
	return (
		<form onSubmit={(e) => (e.preventDefault(), handleSubmit(e))}>
			{!edit ? (
				fields.map((field, index) => (
					<Detail key={'accountInfo_' + index} label={field.label} column>
						{field.text}
					</Detail>
				))
			) : (
				<FormFields fields={fields} handleChange={handleChange} />
			)}
			{children}
			{withSubmit && <input type='submit' />}
		</form>
	);
};

Form.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	fields: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			list: PropTypes.arrayOf(PropTypes.string),
		})
	).isRequired,
	edit: PropTypes.bool,
	withSubmit: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
};

Form.defaultProps = {
	withSubmit: false,
};

export default Form;
