import { EDIT_USER, LOGIN_SUCCES, LOGOUT } from './types';

const authReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCES:
      return {
        ...state,
        user: action.payload,
        isLogged: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isLogged: false,
      };
    case EDIT_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    default:
      return state;
  }
};

export default authReducer;
