import React, { createContext, useReducer, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import reducer from './filterReducer';
import getFilterDefs from '../../controllers/getFilterDefs';
import {
  CLEAR_FILTER,
  SET_CHECKBOX,
  SET_INTERVAL,
  SET_RADIO,
  SET_SELECTED,
  SET_PREFERENCES,
  REMOVE_SELECTED,
  SET_ORIENTATION,
} from './types';
import { LangContext } from '../language/LangContext';
import TYPES from '../../modules/Filter/filterTypes';

export const FilterContext = createContext();

const FilterState = ({ children }) => {
  const {
    lang: {
      TX00332: allLang,

      TX00391: categoryLang,
      TX00022: languagesLang,
      TX00023: sciencesLang,
      TX00024: talentsLang,

      TX00361: disciplineLang,

      TX00686: experienceLang,
      TX00755: experienceIntervalLang_1,
      TX00756: experienceIntervalLang_2,
      TX00757: experienceIntervalLang_3,
      TX00758: experienceIntervalLang_4,

      TX00055: creLang,
      TX00005: sessionLang,

      TX00687: timezoneLang,

      TX00417: levelLang,

      TX00383: availableForLang,

      TX00424: teachingLanguageLang,

      TX00394: disciplesLang,
      TX00695: activeLang,

      TX00354: rankLang,
      TX00642: initiatorLang,
      TX00643: explorerLang,
      TX00644: coachLang,
      TX00645: innovatorLang,
      TX00646: masterLang,
      TX00647: guruLang,

      TX00689: extraLang,
      TX00690: videoLang,
      TX00691: certifiedLang,
      TX00692: testActivatedLang,
      TX00693: libraryMaterialsLang,
      TX00694: certificationsActivatedLang,
    },
  } = useContext(LangContext);

  const categories = [
    languagesLang.content,
    sciencesLang.content,
    talentsLang.content,
  ];
  const disciplines = [
    'arabic',
    'mandarin',
    'german',
    'romanian',
    'english',
    'french',
    'latin',
    'italian',
  ];
  const experienceIntervals = [
    experienceIntervalLang_1.content,
    experienceIntervalLang_2.content,
    experienceIntervalLang_3.content,
    experienceIntervalLang_4.content,
  ];
  const timezones = [
    'UTC +0',
    'UTC +1',
    'UTC +2',
    'UTC +3',
    'UTC +4',
    'UTC +5',
    'UTC +6',
    'UTC +7',
    'UTC +8',
    'UTC +9',
    'UTC +10',
    'UTC +11',
    'UTC +12',
    'UTC +13',
    'UTC +14',
    'UTC +15',
    'UTC +16',
    'UTC +17',
    'UTC +18',
    'UTC +19',
  ];
  const levels = [
    'level 1',
    'level 2',
    'level 3',
    'level 4',
    'level 5',
    'level 6',
    'level 7',
    'level 8',
    'level 9',
    'level 10',
  ];
  const languages = [
    'arabic',
    'mandarin',
    'german',
    'romanian',
    'english',
    'french',
    'latin',
    'italian',
  ];
  const ranks = [
    allLang.content,
    initiatorLang.content,
    explorerLang.content,
    coachLang.content,
    innovatorLang.content,
    masterLang.content,
    guruLang.content,
  ];
  const extras = [
    videoLang.content,
    certifiedLang.content,
    testActivatedLang.content,
    libraryMaterialsLang.content,
    certificationsActivatedLang.content,
  ];

  const categoryFilter = {
    type: TYPES.RADIO,
    header: categoryLang.content,
    list: categories,
    id: 'category',
  };
  const disciplineFilter = {
    type: TYPES.CHECKBOX,
    header: disciplineLang.content,
    list: disciplines,
    id: 'discipline',
  };
  const experienceFilter = {
    type: TYPES.CHECKBOX,
    header: experienceLang.content,
    list: experienceIntervals,
    id: 'experience',
  };
  const creFilter = {
    type: TYPES.INTERVAL,
    header: `${creLang.content} (${sessionLang.content})`,
    id: 'cre',
  };
  const timezoneFilter = {
    type: TYPES.CHECKBOX,
    header: timezoneLang.content,
    list: timezones,
    id: 'timezone',
  };
  const levelFilter = {
    type: TYPES.CHECKBOX,
    header: levelLang.content,
    list: levels,
    id: 'level',
  };
  const availableForFilter = {
    type: TYPES.RADIO,
    header: availableForLang.content,
    list: levels,
    id: 'avFor',
  };
  const teachingLanguageFilter = {
    type: TYPES.CHECKBOX,
    header: teachingLanguageLang.content,
    list: languages,
    id: 'teachingLang',
  };
  const discipleFilter = {
    type: TYPES.INTERVAL,
    header: `${disciplesLang.content} (${activeLang.content})`,
    id: 'disciples',
  };
  const rankFilter = {
    type: TYPES.CHECKBOX,
    header: rankLang.content,
    list: ranks,
    id: 'rank',
  };
  const extraFilter = {
    type: TYPES.CHECKBOX,
    header: extraLang.content,
    list: extras,
    id: 'extra',
  };
  const exploreFilters = [
    categoryFilter,
    disciplineFilter,
    experienceFilter,
    creFilter,
    timezoneFilter,
    levelFilter,
    availableForFilter,
    teachingLanguageFilter,
    discipleFilter,
    rankFilter,
    extraFilter,
  ];

  const radioFilterDefs = getFilterDefs([categoryFilter, availableForFilter]);
  const checkboxFilterDefs = getFilterDefs([
    disciplineFilter,
    experienceFilter,
    timezoneFilter,
    levelFilter,
    teachingLanguageFilter,
    rankFilter,
    extraFilter,
  ]);

  const { current: initialState } = useRef({
    checkedRadio: radioFilterDefs,
    checkedCheckbox: checkboxFilterDefs,
    checkedInterval: {
      min: 0,
      max: 0,
      selectedMin: 0,
      selectedMax: 0,
    },

    selected: [],
    orientation: localStorage.card_viewMode ?? 'portrait',
    preferences: {
      sortBy: 0,
      perPage: 30,
      rating: 0,
    },

    filterDefaults: {
      exploreFilters,
    },
    trackedFilters: ['category', 'discipline'],
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const { checkedCheckbox, checkedRadio, checkedInterval, trackedFilters } =
    state;

  const isFilterEmpty = () => {
    const obj1_keys = Object.keys(checkedCheckbox);

    const obj1 = obj1_keys
      .map((key) => Object.values(checkedCheckbox[`${key}`]))
      .reduce((acc, curr) => (acc = [...acc, ...curr]));
    const obj2 = Object.values(checkedRadio);

    const checkboxNotEmpty = obj1.some((item) => item !== '');
    const radioNotEmpty = obj2.some((item) => item !== '');

    return radioNotEmpty || checkboxNotEmpty ? false : true;
  };

  const isFilterTracked = (filter) =>
    trackedFilters.some((item) => item === filter);

  const setCheckedRadio = (payload) => dispatch({ type: SET_RADIO, payload });

  const setCheckedCheckbox = (payload) =>
    dispatch({ type: SET_CHECKBOX, payload });

  const setCheckedInterval = (payload) =>
    dispatch({ type: SET_INTERVAL, payload });

  const setSelected = (payload) => dispatch({ type: SET_SELECTED, payload });

  const setOrientation = (payload) =>
    dispatch({ type: SET_ORIENTATION, payload });

  const setPreferences = (payload) =>
    dispatch({ type: SET_PREFERENCES, payload });

  const removeSelected = (payload) =>
    dispatch({ type: REMOVE_SELECTED, payload });

  const clearFilter = () =>
    dispatch({ type: CLEAR_FILTER, payload: initialState });

  return (
    <FilterContext.Provider
      value={{
        ...state,
        dispatch,
        isFilterEmpty,
        isFilterTracked,

        radio: {
          checkedRadio,
          setCheckedRadio,
        },
        checkbox: {
          checkedCheckbox,
          setCheckedCheckbox,
        },
        interval: {
          checkedInterval,
          setCheckedInterval,
        },

        setCheckedCheckbox,
        setCheckedRadio,
        setSelected,
        setPreferences,
        setOrientation,

        clearFilter,
        removeSelected,
      }}>
      {children}
    </FilterContext.Provider>
  );
};

FilterState.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FilterState;
