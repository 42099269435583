import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { LangContext } from '../../contexts/language/LangContext';
import EditToggler from '../Generic/EditToggler';
import firstUp from '../../controllers/firstUp';
import Form from '../Form/Form';

const BankAccountInfo = ({ className, creditcard }) => {
	const [edit, setEdit] = useState(false);

	const {
		lang: {
			TX00287: { content: bankAccountLang },
			TX00288: { content: fullNameLang },
			TX00289: { content: countryLang },
			TX00290: { content: addressLang },
			TX00291: { content: accountNumberLang },
		},
	} = useContext(LangContext);

	const { fullName, country, address, accountNo } = creditcard;

	const formFields = [
		{
			label: firstUp(fullNameLang),
			text: fullName,
			type: 'text',
			name: 'fullName',
		},
		{
			label: firstUp(countryLang),
			text: country,
			type: 'text',
			name: 'country',
		},
		{
			label: firstUp(addressLang),
			text: address,
			type: 'text',
			name: 'address',
		},
		{
			label: firstUp(accountNumberLang),
			text: accountNo,
			type: 'text',
			name: 'accountNo',
		},
	];

	const handleSubmit = () => setEdit(false);

	return (
		<div className={`bank-account ${className}`}>
			<header className='flex'>
				<h5>{firstUp(bankAccountLang)}</h5>
				<EditToggler
					onSubmit={handleSubmit}
					onCancel={() => {}}
					state={{ edit, setEdit }}
				/>
			</header>
			<Form
				withSubmit
				fields={formFields}
				edit={edit}
				handleChange={() => {}}
				handleSubmit={handleSubmit}
			/>
		</div>
	);
};

BankAccountInfo.propTypes = {
	className: PropTypes.string,
	creditcard: PropTypes.shape({
		fullName: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		address: PropTypes.string.isRequired,
		accountNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
			.isRequired,
	}).isRequired,
};

BankAccountInfo.defaultProps = {
	className: '',
};

export default BankAccountInfo;
