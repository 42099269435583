import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../Navigation/Sidebar';
// import { LocContext } from '../../contexts/location/LocContext';

const Dashboard = ({ className, children }) => {
  // const {location} = useContext(LocContext);

  return (
    <main className={`dashboard ${className}`}>
      <Sidebar />
      <div className={`dashboard-content ${className}-content`}>{children}</div>
    </main>
  );
};

Dashboard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.element,
  ]).isRequired,
};

Dashboard.defaultProps = {
  className: '',
};

export default Dashboard;
