import React, { useContext } from 'react';
import { AssetContext } from '../../contexts/asset/AssetContext';
import { LangContext } from '../../contexts/language/LangContext';
import FeatureCard from '../../components/Generic/FeatureCard';

const Features = () => {
  const { penTip } = useContext(AssetContext);

  const {
    lang: {
      TX00028: library,
      TX00031: libraryText,
      TX00029: cert,
      TX00032: certText,
      TX00030: academy,
      TX00033: academyText,
    },
  } = useContext(LangContext);

  const content = [
    { title: library.content, text: libraryText.content, icon: undefined },
    { title: cert.content, text: certText.content, icon: undefined },
    { title: academy.content, text: academyText.content, icon: undefined },
  ];

  return (
    <section className='features container'>
      <img src={penTip} alt='webinclass-pen-tip' />
      {content.map((entry, index) => (
        <FeatureCard key={entry.title} content={entry} id={index} />
      ))}
    </section>
  );
};

export default Features;
