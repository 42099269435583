const generateRating = (rating) => {
  const result = [];
  const ratingSplit = rating.toString().split('.');

  for (let i = 0; i < parseInt(ratingSplit[0]); i++) {
    result.push(1);
  }

  if (ratingSplit.length === 2) {
    result.push(parseInt(ratingSplit[1]) === 1 ? 2 : parseInt(ratingSplit[1]));
  }

  while (result.length < 5) {
    result.push(0);
  }

  return result;
};

export default generateRating;
