import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { LangContext } from './contexts/language/LangContext';
import { LocContext } from './contexts/location/LocContext';

import './css/main.css';

// PAGES
import Landing from './pages/Landing/Landing';

// COMPONENTS
import Header from './components/Header/Header';
import Navbar from './modules/Navbar/Navbar';

// ROUTES
import {
	ExploreRoutes,
	RoleRoutes,
	ActivityRoutes,
	CategoryRoutes,
	AccountRoutes,
	UserProfileRoutes,
	JoinRoutes,
} from './routes/routes';

const App = () => {
	const {
		lang: { TX00046: exploreLang },
		loadLang,
		getAvLangs,
	} = useContext(LangContext);

	const { updateLocation } = useContext(LocContext);

	useEffect(() => {
		updateLocation();
		getAvLangs();
		loadLang();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Header>
				<Navbar />
			</Header>
			<Switch>
				<Route path={`/${exploreLang.content}`} component={ExploreRoutes} />
				<Route path='/role' component={RoleRoutes} />
				<Route path='/activity' component={ActivityRoutes} />
				<Route path='/category' component={CategoryRoutes} />
				<Route path='/join' component={JoinRoutes} />
				<Route path='/user_profile' component={UserProfileRoutes} />
				<Route path='/account' component={AccountRoutes} />
				<Route path='/' component={Landing} />
			</Switch>
		</>
	);
};

export default App;
