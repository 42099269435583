import React from 'react';
import PropTypes from 'prop-types';
import firstUp from '../../controllers/firstUp';
import Text from '../Generic/Text';
import Logo from '../Assets/Logo';
import MentorIcon from '../Assets/MentorIcon';
import DiscipleIcon from '../Assets/DiscipleIcon';

const RoleStep = ({ content, mentor, disciple, right }) => {
  const { icon, title, text1, text2 } = content;

  return (
    <div
      className={`role-step ${right ? 'role-step-right' : 'role-step-left'} ${
        right && mentor
          ? 'role-step-mentor'
          : right && disciple
          ? 'role-step-disciple'
          : ''
      }`}>
      {icon === 'logo' ? (
        <Logo noType className='role-step-icon' />
      ) : icon === 'mentor' ? (
        <MentorIcon className='role-step-icon' />
      ) : icon === 'disciple' ? (
        <DiscipleIcon className='role-step-icon' />
      ) : (
        <img src={icon} alt='step' />
      )}
      <div className='role-step-body'>
        <Text text={firstUp(title)} className='fw-b' />
        {text1 && <Text text={firstUp(text1)} className='fs-xs' />}
        {typeof text2 === 'string' ? (
          <Text text={firstUp(text2)} className='fs-xs' />
        ) : (
          <ul>
            {text2.map((entry, index) => (
              <Text
                key={'roleStep_' + index}
                li
                text={firstUp(entry)}
                className='fs-xs'
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

RoleStep.propTypes = {
  content: PropTypes.object.isRequired,
  mentor: PropTypes.bool,
  disciple: PropTypes.bool,
  right: PropTypes.bool,
};

RoleStep.defaultProps = {
  mentor: false,
  disciple: false,
  right: false,
};

export default RoleStep;
