import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from './Box';
import ShowcaseTexts from './ShowcaseTexts';

const ActivityCard = ({ className, content }) => {
  const refCard = useRef(null);

  return (
    <Box refProp={refCard} className={`activity-card ${className}`}>
      <ShowcaseTexts content={content} />
    </Box>
  );
};

ActivityCard.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
};

ActivityCard.defaultProps = {
  className: '',
};

export default ActivityCard;
