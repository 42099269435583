import React, { useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import UserProfileNav from '../../components/UserProfile/UserProfileNav';

import UserProfileGeneral from './UserProfileGeneral';
import UserProfileActivity from './UserProfileActivity';
import Modal from '../../components/Modal/Modal';
import { LOCATIONS } from '../../contexts/location/types';

const UserProfile = () => {
  const location = useLocation();
  const { current: userStateRef } = useRef(location.state?.user);

  const { id, activity } = useParams();

  return (
    <Modal location={LOCATIONS.EXPLORE} className='user-profile'>
      <UserProfileNav
        id={id}
        list={userStateRef.mentor ? userStateRef.mentor : userStateRef.disciple}
      />
      {!activity ? (
        <UserProfileGeneral user={userStateRef} />
      ) : (
        <UserProfileActivity user={userStateRef} />
      )}
    </Modal>
  );
};

export default UserProfile;
