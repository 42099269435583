import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LangContext } from '../../contexts/language/LangContext';
import RoleBackdrop from '../../components/Role/RoleBackdrop';
import RoleSteps from '../../modules/Role/RoleSteps';
import RoleFinalMessage from '../../modules/Role/RoleFinalMessage';
import WICShowcaseText from '../../components/Generic/WICShowcaseText';
import Separator from '../../components/Generic/Separator';
import CategorySlides from '../../modules/Category/CategorySlides';
import Activities from '../../modules/Activity/Activities';
import Features from '../../modules/Features/Features';
import Values from '../../modules/Values/Values';

const Role = () => {
  const [roleSelected, setRoleSelected] = useState('');
  const {
    lang: {
      TX00050: EducationLang,
      TX00164: HowItWorksLang,
      TX00192: AvailableCategoriesLang,
      TX00193: ActivitiesLang,
      TX00194: OurValuesLang,
      TX00209: BecomeAMentor,
      TX00210: BecomeADisciple,
    },
  } = useContext(LangContext);

  const { role } = useParams();

  useEffect(() => {
    window.scroll({ top: 0 });

    role === EducationLang.content
      ? setRoleSelected('education')
      : setRoleSelected('mentoring');

    // eslint-disable-next-line
  }, []);

  return (
    <main className={`role-page ${roleSelected}-page`}>
      <RoleBackdrop role={roleSelected} />
      <WICShowcaseText />
      <Separator text={HowItWorksLang.content} />
      <RoleSteps role={roleSelected} />
      <Separator text={AvailableCategoriesLang.content} />
      <CategorySlides />
      <Separator text={ActivitiesLang.content} />
      <Activities />
      <Features />
      <Separator text={OurValuesLang.content} />
      <Values
        mentor={roleSelected === 'mentoring' && true}
        disciple={roleSelected === 'education' && true}
      />
      <Separator
        text={
          roleSelected === 'mentoring'
            ? BecomeAMentor.content
            : BecomeADisciple.content
        }
      />
      <RoleFinalMessage role={roleSelected} />
    </main>
  );
};

export default Role;
