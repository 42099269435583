import React, { useContext } from 'react';

import NavigationBarMain from '../../components/Navigation/NavigationBarMain';
import NavigationBarSecondary from '../../components/Navigation/NavigationBarSecondary';
import { AuthContext } from '../../contexts/auth/AuthContext';
import { LocContext } from '../../contexts/location/LocContext';

const Navbar = () => {
  const { isLogged } = useContext(AuthContext);
  const { isMainBlock } = useContext(LocContext);

  let k = 0;

  return (
    <>
      {!isLogged && isMainBlock() ? (
        <NavigationBarMain key={k++} white />
      ) : !isLogged && !isMainBlock() ? (
        <NavigationBarMain />
      ) : isLogged && isMainBlock() ? (
        <NavigationBarSecondary key={k++} white />
      ) : isLogged && !isMainBlock() ? (
        <NavigationBarSecondary />
      ) : (
        <div>error</div>
      )}
    </>
  );
};

export default Navbar;
