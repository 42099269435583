import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '../../components/Generic/Box';
import Text from '../../components/Generic/Text';
import Pill from '../../components/Generic/Pill';
import { AssetContext } from '../../contexts/asset/AssetContext';
import firstUp from '../../controllers/firstUp';
import MentorIcon from '../../components/Assets/MentorIcon';
import Input from '../../components/Generic/Input';
import SelectorListGrid from '../../components/Generic/SelectorListGrid';
import { FilterContext } from '../../contexts/filter/FilterContext';
import { LocContext } from '../../contexts/location/LocContext';

const UtilityBar = ({ className }) => {
  const { closeRed } = useContext(AssetContext);

  const {
    selected,
    removeSelected,
    setPreferences,

    preferences: { sortBy, rating, perPage },
  } = useContext(FilterContext);

  const { location } = useContext(LocContext);

  const handleChange = (e) =>
    setPreferences({ [e.target.name]: e.target.value });

  return (
    <section className={`utility-bar ${className}`}>
      <Box>
        <div className='top'>
          <div className='utility-bar-location'>
            <MentorIcon />
            {location && <Text>{firstUp(location)}</Text>}
          </div>
          <div className='utility-bar-pills'>
            {selected &&
              selected.map((text, index) => (
                <Pill
                  key={'key_' + text + index}
                  small
                  content={{
                    text,
                    icon: closeRed,
                  }}
                  onClick={removeSelected}
                />
              ))}
          </div>
        </div>
        <form className='bottom'>
          <Input
            id='sortBy'
            type='select'
            name='sortBy'
            value={sortBy}
            onChange={handleChange}
            content={{ label: 'sort by', list: ['languages', 'categories'] }}
          />
          <Input
            id='perPage'
            type='select'
            name='perPage'
            value={perPage}
            onChange={handleChange}
            content={{ label: 'Per page', list: [30, 50, 100] }}
          />
          <Input
            id='rating'
            type='select'
            name='rating'
            value={rating}
            onChange={handleChange}
            content={{ label: 'Rating', list: [1, 2, 3, 4, 5] }}
          />
          <SelectorListGrid />
        </form>
      </Box>
    </section>
  );
};

UtilityBar.propTypes = {
  className: PropTypes.string,
};

UtilityBar.defaultProps = {
  className: '',
};

export default UtilityBar;
